/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Utils, _, Http, API, Constant, Images, Trans, NavLink } from 'Helpers';
import { Img } from 'Components';
import Select from 'react-select';
import { PlayerCard } from 'Modals';
import { ReduxHOC } from 'HOC';
import InfiniteScroll from 'react-infinite-scroll-component';
const {
  REACT_APP_SALARY_SUBFFIX
} = process.env

class AllStatistics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_league: Utils.getMultiLeg(),
      // AllPosition: {},
      // ALLTeam: {},
      SortBy: [],
      selected_position: '',
      selected_sortby: '',
      selected_team: '',
      RosterMasterData: [],
      RosterPosting: false,
      RosterArr: [],
      // SelectedPlayer: {},
      isPlayerCardShow: false,
      is_single_player: Utils.isSinglePlayer(),

      hasMore: false,
      Offset: Constant.CURRENT_OFFSET,
      RosterStore: [],
      RosterItems: Array.from({ length: Constant.ITEMS_PERPAGE }),
      scroll_btn: false,
      remData: false,
      AppMasterData: props.root.AppMasterData
    }
  }

  PlayerCardInit = (SelectedPlayer) => {
    this.setState({
      SelectedPlayer
    }, () => {
      this.PlayerCardToggle()
    })
  }
  PlayerCardToggle = () => {
    this.setState({
      isPlayerCardShow: !this.state.isPlayerCardShow
    })
  }

  LeagueToggle = (item) => {
    this.setState({
      selected_league: item,
    }, this.RosterStatsMasterData)
  }

  GetRosterStats = () => {
    const { selected_league } = this.state
    this.setState({ RosterPosting: true })
    let param = {
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
      "is_score_stats_needed": "1"
    }
    Http.post(API.GET_ALL_ROSTER, param).then(response => {
      const { data } = response;
      let TempView = [{
        value: '',
        position: '',
        position_display_name: "All",
        max_player_per_position: null,
      }]
      _.map(data.result, (it) => {
        TempView.push({ value: it.position, position_display_name: it.position_name, position: it.position, max_player_per_position: null })

      })
      const ids = TempView.map(o => o.position)
      const filtered = TempView.filter(({ value }, index) => !ids.includes(value, index + 1))

      // let TempViews = [{
      //   team_id: '',
      //   team_name: 'All',
      //   team_abbr: "All",
      //   // max_player_per_position: null,
      // }]
      // _.map(data.result, (it) => {
      //   TempViews.push({ team_id: it.team_id, team_name: it.team_name, team_abbr: it.team_abbr })

      // })
      // const idx = TempViews.map(o => o.team_id)
      // const filters = TempViews.filter(({ value }, index) => !idx.includes(value, index + 1))

      this.setState({
        AllPosition: filtered,
        // ALLTeam: filters,
        RosterArr: data.result,
        RosterStore: _.cloneDeep(data.result),
        RosterPosting: false,
      }, () => {
        this.setState({
          hasMore: true,
          RosterArrEmpty: _.isEmpty(data.result) && _.isEmpty(this.state.RosterArr)
        })
      })
    }).catch(error => {
      this.setState({ RosterPosting: false })
    });
  }
  getAllTeam = (lg_id, sp_id) => {
    // const { t } = this.props;
    let s3_param = [
      API.S3_GET_ALL_TEAM,
      lg_id
    ]
    let param = { league_id: lg_id, sports_id: sp_id }
    Http.s3(Utils.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((response) => {
      // Http.post(API.GET_ALL_TEAM, param).then(response => {
      const { data } = response;
      // console.log('object')
      let TempViews = [{
        team_id: '',
        team_name: 'All',
        team_abbr: "All",
      }]
      _.map(data, (it) => {
        // console.log(it,'team_idteam_id')
        TempViews.push({ team_id: it.team_id, team_name: it.team_name, team_abbr: it.team_abbr })
        // console.log(TempViews)
      })
      const idx = TempViews.map(o => o.team_id)
      const filters = TempViews.filter(({ team_id }, index) => !idx.includes(team_id, index + 1))
      // console.log(filters,'sadd')
      this.setState({
        // updatePlayerList: new Date().valueOf(),
        // team_update: filters,
        ALLTeam: filters
      }, () => {
        // console.log(this.state.ALLTeam, 'adoijo')
      })

    }).catch(error => {
      console.error(error)
    });
  }
  componentDidMount() {
    this.getAllTeam(Utils.getMultiLeg().league_id, Utils.getMultiLeg().sports_id)
    //  this.RosterStatsMasterData()
    this.getSortlist()
    this.GetRosterStats()
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        this.setState({ scroll_btn: true })
      } else {
        this.setState({ scroll_btn: false })
      }
    });
  }
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  getSortlist = () => {
    const { selected_league } = this.state
    let param = {
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
    }
    Http.post(API.GET_SORT_BY_LIST, param).then(response => {
      const { data } = response;
      this.setState({
        SortBy: [{
          value: 'All',
          label: <Trans>All</Trans>,
        }, ...data],
      })
    }).catch(error => {
      this.setState({ RosterPosting: false })
    });
  }

  _filterHandler = () => {
    // console.log(selected_team,'selected_team')
    const {
      RosterStore,
      selected_position,
      selected_sortby,
      selected_team
    } = this.state;
    // return false
    const _fltrData = Utils.RosterFilterInit({
      data: RosterStore,
      selected_position: selected_position,
      selected_sorting: selected_sortby,
      selected_team: selected_team
    })
    // console.log(_fltrData,'asdsad')

    // console.log(selected_sortby.value,'RosterFilterInit')
    const _condition = _fltrData.length >= Constant.ITEMS_PERPAGE
    this.setState({
      RosterItems: Array.from({ length: _condition ? Constant.ITEMS_PERPAGE : _fltrData.length }),
      RosterArr: _fltrData
    }, () => {
      const { RosterItems, RosterArr } = this.state;
      const __condition = (RosterArr.length - RosterItems.length) >= Constant.ITEMS_PERPAGE;
      this.setState({
        hasMore: __condition
      })
    });
  }



  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    // console.log(e, 'team_name')
    this.setState({
      [name]: value,
      RosterArr: []
    }, () => {
      // console.log(this.state.selected_team, 'selected_teamselected_team')
      this._filterHandler()
    });
  }

  handleChanges = (e, type) => {
    // console.log(e.target.value, 'sadhasoil')
    const name = !type ? e.team_name : type;
    let value = !type ? e.value : e;
    // console.log(e, 'team_name')
    this.setState({
      [name]: value,
      RosterArr: []
    }, () => {
      this._filterHandler()
    });
  }



  fetchMoreData = () => {
    const { RosterItems, RosterArr } = this.state;
    const _condition = (RosterArr.length - RosterItems.length) >= Constant.ITEMS_PERPAGE;
    this.setState({
      RosterItems: RosterItems.concat(Array.from({ length: _condition ? Constant.ITEMS_PERPAGE : RosterArr.length - RosterItems.length })),
      hasMore: _condition
    });
  }

  showImage(item){
    if(item.player_image){
      return item.player_image
    } else if(item.jersey){
      return item.jersey 
    } else {
      return Images.DEFAULT_JERSEY
    }
  }





  render() {
    const {
      selected_league,
      AllPosition,
      selected_position,
      SortBy,
      selected_sortby,
      selected_team,

      // RosterPosting,
      RosterArr,


      //  RosterMasterData,
      isPlayerCardShow,
      SelectedPlayer,
      is_single_player,
      hasMore,
      RosterItems,
      scroll_btn,
      ALLTeam,
      AppMasterData
      // remData
    } = this.state;

    const PlayerCardProps = {
      isOpen: isPlayerCardShow,
      toggle: this.PlayerCardToggle,
      selected_league: selected_league,
      Player: SelectedPlayer,
      RosterMasterData: [],
    }

    

    // const upInteractive = false;

    // function autoToggle() {
    //   $('.stt-btn').toggleClass('auto');
    // }

    // $('.stt-btn').hover(function () {
    //   upInteractive = true;
    //   $('.stt-btn').removeClass('auto');
    // });

    // setInterval(function () {

    //   console.log(upInteractive);

    //   if (upInteractive === false) {
    //     autoToggle();
    //   }

    // }, 2000);

    return (
      <section className='pt-cls'>
        <Container className='pfix-container'>
          <div className="top-1">

            <div className="mtb-all-stats">
              <NavLink className='red-circle' to='/statistics'>
                <i className='icon-previous' />
              </NavLink>
              <div className="heading-main pl-fix">Player Stats</div>
              {/* <p className='subtitle'><Trans>Play Fantasy with your friends</Trans></p> */}
            </div>

          </div>
        </Container>
        <Container className='white-stats'>
          <div className="small-container pt-fix m-fix-update mb-fix">
            <div className="top-2">
              <Row className='gutters-5px-sm m-top-l gutter-fix'>
                <Col className='pos-rel'>
                  {/* {selected_team == '' && <div className='label-for-dd'>{_.isEmpty(selected_team) && !remData ? 'Filter By Club' : ''}</div>} */}
                  <Select
                    className='react-select mini border-update'
                    classNamePrefix="react-select"
                    placeholder='Filter by Club'
                    options={ALLTeam}
                    // isSearchable={false}
                    value={selected_team}
                    // defaultL={'Filter by Club'}
                    onChange={e => this.handleChanges(e, 'selected_team')}
                    getOptionLabel={({ team_name }) => team_name}
                    getOptionValue={({ team_id }) => team_id}
                  // menuIsOpen={true}
                  />
                </Col>
                <Col className='pos-rel'>
                  {/* <div className="filter-label"><Trans>View</Trans></div> */}
                  {/* <div className='label-for-dd l-fix'>{_.isEmpty(selected_position) ? 'Filter By Position' : ''}</div> */}
                  <Select
                    className='react-select mini border-update'
                    classNamePrefix="react-select"
                    placeholder='Filter By Position'
                    options={AllPosition}
                    value={selected_position}
                    isSearchable={false}
                    onChange={e => this.handleChange(e, 'selected_position')}
                    getOptionLabel={({ position_display_name }) => position_display_name}
                    getOptionValue={({ value }) => value}
                  />
                </Col>
                <Col md={4} sm={6} id='mt-ids' className='pos-rel'>
                  {/* <div className='label-for-dd l-fix'>{_.isEmpty(selected_sortby) ? 'Sort By' : ''}</div> */}
                  <Select
                    className='react-select mini border-update'
                    classNamePrefix="react-select"
                    placeholder='Sort By'
                    options={SortBy}
                    value={selected_sortby}
                    isSearchable={false}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    onChange={e => this.handleChange(e, 'selected_sortby')}
                  />
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <InfiniteScroll
                  dataLength={RosterItems.length}
                  next={this.fetchMoreData}
                  hasMore={hasMore}>
                  <table className='statplayer-tbl'>
                    <thead>
                      <tr>
                        <td className='player-info'><Trans>Player</Trans></td>
                        <td><Trans>Form</Trans></td>
                        {
                          !is_single_player &&
                          <td><Trans>Salary</Trans></td>
                        }
                        {/* <td className='last-child'><Trans>Selected by</Trans></td> */}
                        {
                         // (selected_sortby && selected_sortby.value != '' && selected_sortby.value != 'avg_form') &&
                         selected_sortby.label !== 'Salary' && selected_sortby.label !== "Form" && 
                          <td className={`${_.includes(['shots_on_goal', 'penalty_saves', 'yellow_cards', 'goals_conceded', 'transfer_out_count'], selected_sortby.value) ? 'w80' : ''} ${selected_sortby.value == 'penalty_misses' ? 'w110' : ''}`}>
                            <div className="dyn-label">{selected_sortby == ''   ? ' All ' : selected_sortby.label}</div>
                            {/* || selected_sortby.label == 'Salary' */}
                          </td>
                        }
                      </tr>
                    </thead>
                    <div id='seperator' />
                    {
                      !_.isEmpty(RosterArr) &&
                      <tbody>

                        {
                          _.map(RosterItems, (i, idx) => {
                            return (
                              <tr key={idx} onClick={() => this.PlayerCardInit(RosterArr[idx])}>
                                <td className='player-info'>
                                  <figure className="figure">
                                    <Img src={this.showImage(RosterArr[idx])} fallbacksrc={Images.DEFAULT_JERSEY} alt="" className='img' />
                                    {/* is_single_player ? RosterArr[idx].player_image : RosterArr[idx].jersey */}
                                    
                                  </figure>
                                  <div className="inline">
                                    {
                                      is_single_player ?
                                        <>
                                          <div className='player-name'>{RosterArr[idx].full_name}</div>
                                          <div className="team-name">{RosterArr[idx].country || '--'} {is_single_player ? `| Rank ${RosterArr[idx].rank}` : ''}</div>
                                        </>
                                        :
                                        <>
                                          <div className='player-name'>{RosterArr[idx].nick_name || RosterArr[idx].full_name}</div>
                                          <div className="team-name">
                                          {
                                            <Img src={RosterArr[idx].flag} fallbacksrc={Images.DEFAULT_FLAG} alt="" className='flag flag-custom-view' />
                                          }
                                            {RosterArr[idx].team_abbr_lang}
                                          </div>
                                        </>
                                    }
                                  </div>
                                </td>
                                <td>{RosterArr[idx].avg_form}</td>
                                {
                                  !is_single_player &&
                                  <td>{AppMasterData.CURRENCY_CODE + ' ' + RosterArr[idx].salary} {REACT_APP_SALARY_SUBFFIX}</td>
                                }
                                {/* <td>{RosterArr[idx].pick_percentage + '%'}</td> */}
                                {
                                  ( selected_sortby.label !== 'Salary' && selected_sortby.label !== "Form") &&
                                  <td className='last-child'>{
                                    selected_sortby == '' || selected_sortby.value == 'All'  ? RosterArr[idx].pick_percentage + '%' : 
                                    RosterArr[idx][selected_sortby.value] || '--'}</td>
                                }
                              </tr>
                            )
                          })
                        }

                      </tbody>
                    }
                  </table>
                </InfiniteScroll>
              </Col>
            </Row>
          </div>
          {
            isPlayerCardShow &&
            <PlayerCard {...PlayerCardProps} />
          }
        </Container>
        {scroll_btn &&
          <div className='stt-btn' onClick={() => this.goToTop()}>
            <i className='icon-up' />
          </div>
        }
      </section>
    )
  }
}

AllStatistics.propTypes = {}

export default ReduxHOC(AllStatistics)
