/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
// import AddToHomescreen from 'react-add-to-homescreen';
import { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Notification, { notify } from 'react-notify-toast';
import { CacheBuster, ScrollToTop } from 'Components';
import './App.scss';
import { Http, API, Utils, Auth, NavLink, Constant, _ } from "Helpers";
import { Layout } from "Pages";
import { ReduxHOC } from "HOC";
import { createBrowserHistory } from 'history';
import { getCookieConsentValue } from "react-cookie-consent";
import Cookies from 'js-cookie'

const history = createBrowserHistory();
const location = history.location;
const queryString = require('query-string');
const parsed = queryString.parse(location.search);
let momentLib;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      AppInit: true,
      Cookies: undefined,
    }
  }
  componentDidMount() {


    if (process.env.NODE_ENV !== "development") {
      // console.error = () => {};
      // console.log = () => {};
      // console.info = () => {};
    }
    if (Auth.getAuth() && Utils.getLangPosting()) {
      Http.post(API.UPDATE_LANG).then(response => {
        localStorage.removeItem('lang_posting');
      })
    }
    // if (Utils.getLang() == 'ar') momentLib = require('moment/locale/ar');

    Utils.setCpSession()

    if (!_.isNull(Utils.getMasterData()) && Utils.getMasterData().allow_sso == 1) {
      this.getSessionKey()
    }
  }

  getSessionKey = () => {

    // if (!_.isUndefined(parsed) && !_.isEmpty(parsed)) {
    //   if(!_.isNull(Utils.getMasterData()) && Utils.getMasterData().allow_sso_type == 'GODG'){
    //     this.authenticate(parsed.MSISDN.replace("\"", ""))
    //   } else {
    //     if(!Auth.getAuth()){
    //       this.authenticate(parsed.accessToken.replace("\"", ""))
    //     }

    //   }

    // } else {
    const _URC = Utils.getCookie('_URC') 
    const _URCLS = localStorage.getItem('_URC')
    // const _USC = getCookieConsentValue() ? Utils.getCookie('_USC') : localStorage.getItem('_USC');
    // console.log(_URC,"_URC_URC");
    // console.log(_USC,"_USC_USC");
    if ( _URC && _URC !== "" && _URC !== "null" &&  JSON.parse(_URC) !== null  && !Auth.getAuth()) {
      this.authenticate(JSON.parse(_URC).user_guid)
      //if (!_URC && !_USC && !Auth.getAuth())
      //console.log('radhe radhe');
    } else {

      if (_URC && _URC !== "" && _URC != 'null'&&  JSON.parse(_URC) !== null ) {
        //console.log(JSON.parse(_URCLS).user_guid,"JSON.parse(_URCLS).user_guid");
        //console.log(JSON.parse(_URC).user_guid,"JSON.parse(_URC).user_guid");

        if (JSON.parse(_URC).user_guid !== JSON.parse(_URCLS).user_guid) {

          localStorage.removeItem('auth');
          localStorage.removeItem('profile');
          localStorage.removeItem(`current_lineup`);
          localStorage.removeItem(`lineup`);
          localStorage.removeItem(`auth`);
          this.authenticate(JSON.parse(_URC).user_guid)
        }
      }
      //this.authenticate(JSON.parse(_URC).user_guid,_USC)
    }
    // }
  }

  authenticate = (token) => {

    this.setState({ posting: true });

    const { history, actions } = this.props;

    let param = {
      "token": token,
      //  "usctoken":"MUd2dVRRS1pyU2txWUxjQ0FRL2I1UUpyOEJlNkRuaGtLWXFNTTcxbFBpU2o5cng0SG0vQzhINHBRZEpUY3A2SVVaYlhtUWJrSFk4amxiTHZPZGlZdFNoK3FhZXl2SW1hczB5VjNPeEUyMmdDcEd5SnJKeU9vTWhLaW9XeVkzbFZYVkVKV2ZjVWtHMm1tTit5d3ZNQXBBTEwyZFhkYSsxZzZVcGJVMzVuVEVKUFFTRVJvNEFpMkJJMko2WTFNQ3pTK25ucW84V0lkVnQrYTQ5YnF5QlVYeHJEWEJiWTlRU1lBYkZZV000c2luRWZLUXJmanBERzlYeW5wZkJQMkhqblJPUmtSbkhOWkN0L0ovcEJLbG9YaHhON1EyUnlEMDJaditaM29QdWhUQU1HWlVVMVhJWGpwcEI3eFZJRklYNFBCNkVVRDM4aHJsNmdOVng2cHU3Vm4wVURLMG9qUW1wSWxNUlNGSHVEcm4vclROc2d5Q1cyUzRBdlY0bGx3OVNEbjVyTWVLQ0M1am9ZbnZJUHNwbjM1b0lxdFV0bWNHMkxXb1RxR0ZiK2ROWUV5U2JnRUo1NGJ5NDlUVDhycjV1Kw=="
    }
    // console.log(param,"RRRRRRRRRRR");
    // return ;
    Http.post(API.LOGIN, param).then(response => {
      // alert(response.response_code)
      if (window.ReactNativeWebView) {
        let data = {
          action: "login",
          type: 'login',
          targetFunc: "loginFunc",
          userData: response.data
        };
        this.sendMessageToApp(data);
      }
      localStorage.setItem('_URC', Utils.getCookie('_URC'))
      Auth.setAuth(response.data.session_key);
      Utils.setProfile(response.data.user_profile);
      Utils.setExistingUser(response.data.user_profile.existing_user)


      actions.gtmEventFire('login', {
        'user_id': response.data.user_profile.user_unique_id,
        'user_name': response.data.user_profile.user_name,
        'login_method': 'email',
      }, true)

      Utils.setLineupMasterId().then(res => {
        actions.setAppMaster(Utils.getMasterData() || {})
        actions.setCurrentLineup(Utils.getCurrentLineup())
        actions.setProfileData(response.data.user_profile)
      })
      history.push(Constant.DASHBOARD_PATH);


    }).catch(error => {
      console.log(error)
      if (error.data) {
        window.open(error.data.redirection, "_self")
      }
      this.setState({ posting: false });
      // if(!isGuestUser) {
      // }

    });
  }

  handleAddToHomescreen = () => {
    // Utils.setCookie('is_cancel_prompt', true, 15)
    // alert('1. Open Share menu\n2. Tap on "Add to Home Screen" button');
  };
 
  render() {

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            this.state.AppInit ?
              <Fragment>
                <Notification options={{ zIndex: 99999 }} />
                <BrowserRouter>
                  <ScrollToTop>
                    <Switch>
                      <Route exact component={Layout} />
                      <Redirect from="*" to={Constant.DEFAULT_ROOT} />
                    </Switch>
                  </ScrollToTop>
                </BrowserRouter>
              </Fragment> : ''
          );
        }}
      </CacheBuster>
    );
  }
}

export default ReduxHOC(App);