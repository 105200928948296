// Config
import { Config } from 'Helpers';
// import Utils from 'Helpers/Utils';
const SUCCESS_CODE = 200;
const { URL } = Config.apiGateway;

// const is_single_player = Utils.isSinglePlayer()
// const isApiUpdate = Utils.getSinglePlayerFlag()

const _API = true ? {
    // ONBOARDING
    SIGNUP: `${URL}/user/auth/signup`,
    LOGIN: `${URL}/user/auth/login`,
    LOGOUT: `${URL}/user/auth/logout`,
    FORGOT_PASSWORD: `${URL}/user/auth/forgot_password`,
    ACTIVATE_ACCOUNT: `${URL}/user/auth/activate_account`,
    RESET_PASSWORD: `${URL}/user/auth/reset_password`,
    RESEND_EMAIL_VERIFICATION_LINK: `${URL}/user/auth/resend_email_verification_link`,
    S3_GET_COUNTRY_LIST: `country_list`,

    // ADVERTISEMENTS
    GET_BANNER: `${URL}/fantasy/advertisements/advertisement_list`,
    NEW_BANNER: `${URL}/user/common/get_banners`, //tashaf
    S3_GET_BANNER: `advertisement_list`,
    S3_NEW_BANNER: `get_banners`,

    // Home
    GET_HOME_DATA: `${URL}/fantasy/sl_lobby/get_home_data`,
    TOP_SCORING_PLAYERS: `${URL}/fantasy/sl_lobby/top_scoring_players`,
    GET_LIVE_FIXTURE: `${URL}/fantasy/sl_contest/get_live_fixture_list`,
    S3_TOP_SCORING_PLAYERS: `top_scoring_players`,

    // LEAGUES
    GET_SPORT_LEAGUES: `${URL}/fantasy/sl_lobby/get_sport_leagues`,
    SPORTS_LEAG_LIST: `${URL}/fantasy/sl_lobby/get_sport_leagues`,

    GET_APP_MASTER_DATA: `${URL}/fantasy/sl_lobby/get_app_master_data`,

    // Lineup
    MY_CURRENT_LINEUP: `${URL}/fantasy/sl_lineup/my_current_lineup`,
    GET_AUTOFILL_LINEUP_DATA: `${URL}/fantasy/sl_lineup/get_autofill_lineup_data`,
    GET_ALL_TEAM: `${URL}/fantasy/sl_lineup/get_all_team`,
    S3_GET_ALL_TEAM: `team_list`,

    GET_LINEUP_MASTER_DATA: `${URL}/fantasy/sl_lineup/get_lineup_master_data`,
    GET_MANAGE_TEAM_MASTER_DATA: `${URL}/fantasy/sl_contest/get_manage_team_master_data`,
    GET_USER_LINEUP_DETAIL: `${URL}/fantasy/sl_lineup/get_user_lineup_detail`,
    GET_USER_TEAM_INFO: `${URL}/fantasy/sl_lineup/get_user_team_info`,
    LINEUP_PROCCESS: `${URL}/fantasy/sl_lineup/lineup_proccess`,
    PROCESS_PLAYER_TRANSFER: `${URL}/fantasy/sl_lineup/process_player_transfer`,
    JOIN_GAME: `${URL}/fantasy/sl_contest/join_game`,
    UPDATE_CAPTAIN_VICECAPTAIN: `${URL}/fantasy/sl_lineup/update_captain_vicecaptain`,

    GET_ALL_ROSTER: `${URL}/fantasy/sl_roster/get_all_roster`,
    GET_ROSTER_FOR_TRANSFER: `${URL}/fantasy/sl_roster/get_roster_for_transfer`,

    GET_PLAYERCARD_SL: `${URL}/fantasy/sl_roster/get_playercard_sl`,
    GET_PLAYERCARD_SL_MASTER_DATA: `${URL}/fantasy/sl_roster/get_playercard_sl_master_data`,
    APPLY_BOOSTER: `${URL}/fantasy/sl_lineup/apply_booster`,
    REMOVE_BOOSTER: `${URL}/fantasy/sl_lineup/remove_booster`,
    APPLY_WILDCARD: `${URL}/fantasy/sl_lineup/apply_wildcard`,

    // Fixture
    GET_ALL_WEEK: `${URL}/fantasy/sl_contest/get_all_week`,
    S3_GET_ALL_WEEK: `week_list`, // S3
    GET_FIXTURE_LIST: `${URL}/fantasy/sl_contest/get_fixture_list`,
    S3_GET_FIXTURE_LIST: `fixture_list`, // S3
    GET_LIVE_FIXTURE_LIST: `${URL}/fantasy/sl_contest/get_live_fixture_list`,
    GET_MATCH_SUMMARY: `${URL}/fantasy/sl_contest/get_match_summary`,

    // Stastics
    GET_PLAYERS_STASTICS: `${URL}/fantasy/sl_lineup/get_players_stastics`,
    GET_ALL_ROSTER_STATS: `${URL}/fantasy/sl_lineup/get_all_roster_stats`,
    GET_TOP_SCORING_PLAYER: `${URL}/fantasy/sl_lobby/top_scoring_players`,
    MOST_PICKED_CAPTAINS: `${URL}/fantasy/sl_lineup/most_picked_captains`,

    // Leagues
    GET_USER_LEADERBOARD_LIST_MINILEAGUE: `${URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_list_minileague`,
    GET_USER_LEADERBOARD_LIST_GLOBAL: `${URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_list_global`,
    GET_USER_LEADERBOARD_LIST_PRIVATE: `${URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_list_private`,

    // Mini League
    GET_MINI_LEAGUE_DETAIL: `${URL}/fantasy/sl_minileague/get_mini_league_detail`,
    GET_UNJOINED_MINI_LEAGUES: `${URL}/fantasy/sl_minileague/get_unjoined_mini_leagues`,

    CONTEST_LEADERBOARD: `${URL}/fantasy/sl_new_leaderboard/contest_leaderboard`,
    CONTEST_LEADERBOARD_MASTER_DATA: `${URL}/fantasy/sl_new_leaderboard/contest_leaderboard_master_data`,
    GET_INVITE_CODE: `${URL}/fantasy/sl_contest/save_invite_for_game`,
    CHECK_ELIGIBILITY: `${URL}/fantasy/sl_contest/check_eligibility_for_contest`,
    DELETE_LEAGUE: `${URL}/fantasy/sl_contest/delete_contest`,

    REMOVE_LEAGUE_USER: `${URL}/fantasy/sl_lineup/remove_league_user`,
    GET_USER_LEADERBOARD_LIST: `${URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_list`,
    GET_USER_LEADERBOARD_DETAILS: `${URL}/fantasy/sl_new_leaderboard/get_user_leaderboard_details`,

    CREATE_LEAGUE_MASTER_DATA: `${URL}/fantasy/sl_contest/create_league_master_data`,

    CREATE_PRIVATE_LEAGUE: `${URL}/fantasy/sl_contest/create_private_league`,

    LEAVE_LEAGUE: `${URL}/fantasy/sl_lineup/leave_league`,

    // Notification
    GET_NOTIFICATION: `${URL}/user/notification/get_notification`,
    UPDATE_READ_ALL: `${URL}/user/notification/update_read_all`,
    GET_NOTIFICATION_SETTINGS: `${URL}/user/notification/get_notification_settings`,
    UPDATE_NOTIFICATION_SETTING: `${URL}/user/notification/update_notification_setting`,

    // CMS Pages
    GET_PAGE_DETAIL: `${URL}/user/common/get_page_detail`,

    // My Profile 
    GET_COUNTRY_LIST: `${URL}/user/auth/get_country_list`,
    GET_STATE_LIST: `${URL}/user/my_profile/get_state_list`,
    GET_MY_PROFILE: `${URL}/user/my_profile/get_my_profile`,
    DO_UPLOAD: `${URL}/user/my_profile/do_upload`,
    UPDATE_PROFILE: `${URL}/user/my_profile/update_profile`,
    SEND_EDIT_EMAIL_VERIFICATION_LINK: `${URL}/user/my_profile/send_edit_email_verification_link`,
    CHANGE_PASSWORD: `${URL}/user/my_profile/change_password`,
    UPDATE_LANG: `${URL}/user/my_profile/update_lang`,
    GET_SORT_BY_LIST: `${URL}/fantasy/sl_lineup/get_sort_by_list`,
    GET_PRIZES: `${URL}/fantasy/sl_contest/get_prizes`,
    NOTIFICATION_COUNTS: `${URL}/user/notification/get_notification_unread_cnt`,
    NOTIFICATIONS_DATA: `${URL}/user/notification/get_notification`,
    GET_WINNER_DATA:`${URL}/fantasy/common/get_winner_data`,
    //MY TEAM SHARE
    UPLOAD_SHARE_IMAGE: `${URL}/user/my_profile/upload_share_image`,
    GET_PLAYER_UPCOMMING_FIXTURE: `${URL}/fantasy/sl_roster/get_player_upcomming_fixture`,
} : {
    SPORTS_LEAG_LIST: `${URL}/fantasy/sl_lobby/get_sport_leagues`,
    GET_APP_MASTER_DATA: `${URL}/fantasy/sl_lobby/get_app_master_data`,
    GET_BANNER: `${URL}/fantasy/advertisements/advertisement_list`,
    S3_GET_BANNER: `advertisement_list`,
    
    
}
const API = {
    SUCCESS_CODE,
    ..._API
};
export default API
