/* eslint-disable */
import { Component, Fragment } from "react";
import { Modal, Row, Col, Alert,  TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Utils, Http, API, _, Images, Constant, Trans } from "Helpers";
import Slider from "react-slick";
import { notify } from "react-notify-toast";
import { ReduxHOC } from "HOC";
import { Img } from "Components";
import classnames from 'classnames';

const { REACT_APP_SALARY_SUBFFIX } = process.env;

class PlayerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: true,
      statsposting: true,
      isPlayerRoleView: true,
      MasterData: [],
      PlayerCardData: [],
      PlayerParam: {},
      SelectedWeek: null,
      is_single_player: Utils.isSinglePlayer(),
      CurrentLineup: props.root.CurrentLineup,
      constant_pos: Utils.getFormationInitial(),
      SelectedLeague: Utils.getMultiLeg(),
      AppMasterData: props.root.AppMasterData,
      activeTab:'1',
      FixturesList:[],
      CurrentFixtuerSelected:'',
      openingItem: '',
      opening: false,
    };
    this.showNotify = notify.createShowQueue();
    this.slider = null;
  }

  PlayerViewToggle = (bool) => {
    this.setState(
      {
        isPlayerRoleView: bool,
      },
      () => {
        const { SelectedWeek } = this.state;
        if (!_.isNull(this.slider)) {
          this.slider.slickGoTo(Number(SelectedWeek) - 1);
        }
      }
    );
  };

  GetMasterData = () => {
    const { PlayerParam } = this.state;
    Http.post(API.GET_PLAYERCARD_SL_MASTER_DATA, PlayerParam)
      .then((response) => {
        this.setState(
          {
            MasterData: response.data,
            SelectedWeek: response.data.active_week,
          },
          () => {
            const { PlayerParam, SelectedWeek } = this.state;
            this.GetPlayercard({ ...PlayerParam, week: SelectedWeek });
          }
        );
      })
      .catch((error) => {
        this.setState({ posting: false });
        console.error(error);
      });
  };

  GetPlayercard = (param) => {
    this.setState({
      statsposting: true,
      // PlayerCardData: []
    });
    Http.post(API.GET_PLAYERCARD_SL, param)
      .then((response) => {
        this.setState(
          {
            posting: false,
            statsposting: false,
            PlayerCardData: _.isUndefined(response.data.player_card)
              ? response.data
              : response.data.player_card,
          },
          () => {
            const { SelectedWeek } = this.state;
            if (!_.isNull(this.slider)) {
              this.slider.slickGoTo(Number(SelectedWeek) - 1);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          posting: false,
          statsposting: false,
        });
        console.error(error);
      });
  };

  showImage(item) {
    if (item.player_image) {
      return item.player_image
    } else if (item.jersey) {
      return item.jersey
    } else {
      return Images.DEFAULT_JERSEY
    }
  }


  componentDidMount() {
    document.body.style.overflow = "hidden";
    const { selected_league, Player } = this.props;
    const { is_single_player } = this.state;
    const { league_id, sports_id } = selected_league;
    let PlayerParam = {
      league_id: league_id,
      sports_id: sports_id,
      player_team_id: Player.player_team_id,
      player_uid: Player.player_uid,
      ...(is_single_player ? { is_pick_percentage_req: 0 } : {}),
    };

  
    this.setState(
      {
        PlayerParam,
      },
      () => this.GetMasterData()
    );
  }
  componentWillUnmount() {
    document.body.style.overflow = "unset";
  }

  AddPlayer = (player) => {
    const {
      LineupList,
      RosterMasterData,
      t,
      ALL_POSITION,
      lineup_roster_limit,
      toggle,
      next_week,
      IS_TRASFER,
      SALARY_CAP,
    } = this.props;
    let param = {
      next_week,
      lineup_roster_limit,
      player,
      lineup: LineupList,
      salary: Utils.AvailableSalary(SALARY_CAP, LineupList),
      max_player_per_team: RosterMasterData.max_player_per_team,
      all_position: ALL_POSITION,
    };
    Utils.AddPlayerToLineup(param)
      .then((response) => {
        if (IS_TRASFER) {
          Utils.setTransferData(player, "1");
        }
        this.props.updateLineupList(response);
        toggle();
      })
      .catch((err) => {
        this.showNotify(t(err), "error", 5000);
        toggle();
      });
  };

  RemovePlayer = (player) => {
    const { LineupList, toggle, IS_TRASFER, next_week, joined_week } =
      this.props;
    let param = {
      player: player,
      lineup: LineupList,
      next_week,
      joined_week,
    };
    Utils.RemovePlayerFromLineup(param).then((response) => {
      if (IS_TRASFER) {
        Utils.setTransferData(player, "0");
      }
      this.props.updateLineupList(response);
      toggle();
    });
  };

  SelectWeekHandle = (item) => {
    this.setState(
      {
        SelectedWeek: item.week,
      },
      () => {
        const { PlayerParam, SelectedWeek } = this.state;
        this.GetPlayercard({ ...PlayerParam, week: SelectedWeek });
      }
    );
  };

  isGKPlayerRole = () => {
    const { Player, LineupList } = this.props;
    let _flt = _.filter(LineupList, (item) => {
      return (
        item.player_uid != Player.player_uid && item.position == Player.position
      );
    });
    return !_.isEmpty(_flt) ? _flt[0].player_role : null;
  };

   toggleTabs = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab },()=>{
        if(tab == '2' && _.isEmpty(this.state.FixturesList)){
          this.getPlayerFixture()
        }
      });
    }
  };

  getPlayerFixture = () => {
    const { selected_league, Player } = this.props;
    const { league_id } = selected_league;
    this.setState({posting:true})
    let param ={
      league_id: league_id,
      player_team_id: Player.player_team_id,
      player_id: Player.player_id,
    }
    Http.post(API.GET_PLAYER_UPCOMMING_FIXTURE, param).then(response => {
      const { data } = response.data;
      
      
      let match_list_clone = _.cloneDeep(response.data)
      let match_list = {}
      _.forEach(match_list_clone, (item, key) => {
        if (_.isEmpty(match_list)) {
          match_list = item
        } else {
          match_list = _.concat(match_list, item)
        }
      })

      this.setState({
        FixturesList: response.data
      }, () => {
        
        
        this.setState({
          posting: false,
         // FixturesList: match_list
        })
      })
    }).catch(error => {
      console.error(error)
    });

  }

  toggleFixtureDetail =()=>{
      
  }

  render() {
    const {
      isOpen,
      toggle,
      Player,
      isLineupPlayer,
      LineupList,
      PlayerRoleInit,
      i18n,
      selected_league,
      enablePlayerRoleInit,
      next_week,
      joined_week,
      IS_TRASFER,
      SALARY_CAP,
    } = this.props;

    const {
      isPlayerRoleView,
      posting,
      statsposting,
      MasterData,
      SelectedWeek,
      PlayerCardData,
      is_single_player,
      CurrentLineup,
      constant_pos,
      SelectedLeague,
      AppMasterData,
      FixturesList,
      CurrentFixtuerSelected,
      openingItem,
      opening
    } = this.state;

    const {
      // position = '',
      team_abbreviation = '',
      // away = '--',
      // home = '--',
    } = MasterData;

    const {
      selected_by = null,
      avg_form = null,
      total_points = null,
      pick_percentage = null,
      master_data = [],
      roster_detail = {},
      salary = null,
    } = MasterData;

    var SliderSettings = {
      infinite: true,
      // speed: 500,
      arrows: true,
      slidesToShow: i18n.language === "ar" ? 3 : 3,
      slidesToScroll: i18n.language === "ar" ? 1 : 1,
      responsive: [
        {
          breakpoint: 767,

          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          }
        },
      ]
    };

    const PlayerRoleCaptain = ({ Player }) => {
      if (selected_league.sports_config.is_captain_available == 0) return null;
      return (
        <a
          className={`player-role-btn row${Player.player_role == 1 ? " active" : ""
            }`}
          onClick={true ? () => PlayerRoleInit(Player, "1") : null}
        >
          <Col>
            <span className="check" />
          </Col>
          <Col className="title">
            <Trans>Make Captain</Trans>
          </Col>
          <Col>
            {Player.player_role == 1 ? <img src={Images.CAPTAIN} className="role-img" alt="" /> :
              <img src={Images.CAPTAIN_BLUE} className="role-img" alt="" />}
          </Col>
        </a>
      );
    };
    const PlayerRoleViceCaptain = ({ Player }) => {
      if (selected_league.sports_config.is_vicecaptain_available == 0)
        return null;
      return (
        <a
          className={`player-role-btn row${Player.player_role == 2 ? " active" : ""
            }`}
          onClick={true ? () => PlayerRoleInit(Player, "2") : null}
        >
          <Col>
            <span className="check" />
          </Col>
          <Col className="title">
            <Trans>Make Vice-Captain</Trans>
          </Col>
          <Col>
            {Player.player_role == 2 ?
              <img src={Images.VICE_CAPTAIN_W} className="role-img" alt="" />
              : <img src={Images.VICE_CAPTAIN} className="role-img" alt="" />}
          </Col>
        </a>
      );
    };

    let current_date = '';

    const FixtureDetails = () => {
        const { MatchSummary } = this.state;
        return (
            _.map(MatchSummary.stats, (item, idx) => {
                return (
                    (!_.isEmpty(item.home) || !_.isEmpty(item.away)) &&
                    <div className="fixture-details" key={idx}>
                        <div className="title dl-clrs">{MatchSummary.stats_lang[idx]}</div>
                        <div className="content">
                            <div className="cell">
                                {
                                    is_single_player ?
                                        <div>
                                            {item.home}
                                        </div>
                                        :
                                        <>
                                            {
                                                item.home ?
                                                    _.map(item.home, (obj, index) => {
                                                        return (
                                                            <div title={obj.full_name} key={index}>
                                                                <span className="text-ellipsis">{obj.full_name}</span>{" "}
                                                                {
                                                                    Number(obj.value) > 0 &&
                                                                    `(${obj.value})`
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    "--"
                                            }
                                        </>
                                }

                            </div>
                            <div className="cell">
                                {
                                    is_single_player ?
                                        <div>
                                            {item.away}
                                        </div>
                                        :
                                        <>
                                            {
                                                item.away ?
                                                    _.map(item.away, (obj, index) => {
                                                        return (
                                                            <div title={obj.full_name} key={index}>
                                                                <span className="text-ellipsis">{obj.full_name}</span>{" "}
                                                                {
                                                                    Number(obj.value) > 0 &&
                                                                    `(${obj.value})`
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    "--"
                                            }

                                        </>
                                }
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }


    
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="custom-modal plc-modal"
        size={"sm"}
      >
        <i className="close-btn left icon-cancel" onClick={toggle} />
        <div className="player-card-top">
          {isLineupPlayer &&
            (CurrentLineup.user_join_count == 0 ||
              next_week == joined_week ||
              IS_TRASFER) && (
              <Fragment>
                {
                  Utils.IsPlayerExist(Player, LineupList) ? (
                    <a
                      className="player-action-toggle remove"
                      onClick={() => this.RemovePlayer(Player)}
                    >
                      <Trans>
                        <span className="player-remove">-</span>
                      </Trans>
                    </a>
                  ) : (
                    // <a className="player-action-toggle remove icon-check" onClick={() => this.RemovePlayer(Player)} />
                    <a
                      className="player-action-toggle"
                      onClick={() => this.AddPlayer(Player)}
                    >
                      <Trans>
                        <span className="player-add">+</span>
                      </Trans>
                    </a>
                  )
                  // <a className="player-action-toggle icon-plus" onClick={() => this.AddPlayer(Player)} />
                }
              </Fragment>
            )}

          <Row className="gutters-7px">
            <Col className="thumb-col">
              <figure className="thumb">
                {/* <Img src={is_single_player ? Player.player_image : Player.jersey} fallbacksrc={Images.DEFAULT_JERSEY} alt="" className='jersey' /> */}
                {/* <img src={Player.flag || Images.DEFAULT_FLAG} alt="" className='flag' /> */}

                <Img src={this.showImage(Player)} fallbacksrc={Images.DEFAULT_JERSEY} alt="" className='jersey' />
              </figure>
            </Col>
            <Col>
              <h5 className="player-name text-bold">{Player.full_name}</h5>

              {is_single_player ? (
                <p className="text-muted">
                  {Player.country} | <Trans>Rank</Trans> {Player.rank}
                </p>
              ) : (
                <>
                  {MasterData.position ? (
                    <p className="text-muted">{`${MasterData.position} | ${MasterData.team_abbr_lang}`}</p>
                  ) : (
                    <p className="text-muted">--</p>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row className="player-stats-tbl gutters-7px row-bg-pcr">
            <Col>
              <div className="text-bold">
                {avg_form && avg_form != "nan" ? avg_form : "--"}
              </div>
              <div className="text-muted">
                <Trans>Form</Trans>
              </div>
            </Col>
            {SALARY_CAP != 0 && (
              <Col>
                <div className="text-bold">
                  {AppMasterData.CURRENCY_CODE}{Player.salary || salary} {" "}
                  {REACT_APP_SALARY_SUBFFIX}{" "}
                </div>
                <div className="text-muted">
                  <Trans>Salary</Trans>
                </div>
              </Col>
            )}
            <Col>
              <div className="text-bold">
                {pick_percentage ? pick_percentage + "%" : "--"}
              </div>
              <div className="text-muted">
                <Trans>Selected by</Trans>
              </div>
            </Col>
            <Col>
              <div className="text-bold">
                {!_.isEmpty(MasterData) ? total_points : "--"}
              </div>
              <div className="text-muted">
                <Trans>Total Points</Trans>
              </div>
            </Col>
          </Row>
          <Img
            src={is_single_player ? Player.player_image : Player.jersey}
            fallbacksrc={Images.PLAYER_CRD_IMG}
            alt=""
            className="right-big-jersy"
          />
        </div>
        <Fragment>
          {enablePlayerRoleInit &&
            isPlayerRoleView &&
            Utils.IsPlayerField(Player, LineupList) &&
            isLineupPlayer &&
            (CurrentLineup.user_join_count == 0 ||
              next_week == joined_week ||
              IS_TRASFER) ? (
            <div className="player-card-body">
              {constant_pos &&
                Player.position == constant_pos.P0 &&
                this.isGKPlayerRole() != null ? (
                <Fragment>
                  {Player.player_role == 0 && this.isGKPlayerRole() == 0 && (
                    <Fragment>
                      <PlayerRoleCaptain Player={Player} />
                      <PlayerRoleViceCaptain Player={Player} />
                    </Fragment>
                  )}

                  {((Player.player_role == 1 && this.isGKPlayerRole() == 0) ||
                    (Player.player_role == 0 &&
                      this.isGKPlayerRole() == 1)) && (
                      <Fragment>
                        <PlayerRoleViceCaptain Player={Player} />
                      </Fragment>
                    )}
                  {((Player.player_role == 2 && this.isGKPlayerRole() == 0) ||
                    (Player.player_role == 0 &&
                      this.isGKPlayerRole() == 2)) && (
                      <Fragment>
                        <PlayerRoleCaptain Player={Player} />
                      </Fragment>
                    )}
                </Fragment>
              ) : (
                <Fragment>
                  <PlayerRoleCaptain Player={Player} />
                  <PlayerRoleViceCaptain Player={Player} />
                </Fragment>
              )}
            </div>
          ) : (
            <div className="player-card-body">
              {posting ? (
                <Utils.Spinner />
              ) : (
                <Fragment>
                  {/* {(!Utils.isCapAvailable(LineupList) ||
                    !Utils.isVCAvailable(LineupList)) && IS_TRASFER && (
                      <div>
                        {constant_pos &&
                        Player.position == constant_pos.P0 &&
                        this.isGKPlayerRole() != null ? (
                          <Fragment>
                            {Player.player_role == 0 &&
                              this.isGKPlayerRole() == 0 && (
                                <Fragment>
                                  <PlayerRoleCaptain Player={Player} />
                                  <PlayerRoleViceCaptain Player={Player} />
                                </Fragment>
                              )}

                            {((Player.player_role == 1 &&
                              this.isGKPlayerRole() == 0) ||
                              (Player.player_role == 0 &&
                                this.isGKPlayerRole() == 1)) && (
                              <Fragment>
                                <PlayerRoleViceCaptain Player={Player} />
                              </Fragment>
                            )}
                            {((Player.player_role == 2 &&
                              this.isGKPlayerRole() == 0) ||
                              (Player.player_role == 0 &&
                                this.isGKPlayerRole() == 2)) && (
                              <Fragment>
                                <PlayerRoleCaptain Player={Player} />
                              </Fragment>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <PlayerRoleCaptain Player={Player} />
                            <PlayerRoleViceCaptain Player={Player} />
                          </Fragment>
                        )}
                      </div>
                    )} */}
                  {
                    // Utils.isCapAvailable(LineupList) &&
                    //   Utils.isVCAvailable(LineupList) && !IS_TRASFER &&
                    <div>
                       <div className="card-fixture-wrap">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '1' })}
                              onClick={() => this.toggleTabs('1')}
                            >
                              Points
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '2' })}
                              onClick={() => this.toggleTabs('2')}
                            >
                              Upcoming Fixtures
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <div className="week-list show-arrows-slider">
                                        <Slider
                                          {...SliderSettings}
                                          ref={(slider) => (this.slider = slider)}
                                        >
                                          {_.map(master_data, (item, idx) => {
                                            return (
                                              <Fragment key={idx}>
                                                <div
                                                  className={`week-item${item.week == SelectedWeek ? " active" : ""
                                                    }`}
                                                  onClick={() => this.SelectWeekHandle(item)}
                                                >
                                                  <div className="label">
                                                  {Utils.getMultiLeg().week_label[item.week]}{" "}
                                                    {/* {item.week} */}
                                                  </div>
                                                  <div className="value">
                                                    {item.score} <Trans>Points</Trans>
                                                  </div>
                                                </div>
                                              </Fragment>
                                            );
                                          })}
                                        </Slider>
                                      </div>
                            {_.map(PlayerCardData.stats_match_list, (match, index) => {
                              return (
                                <Fragment key={index}>
                                  <div className="stats-match-header">
                                    <p className="team-date-detail">
                                      {/* {
                                            !is_single_player &&
                                            <>
                                              <span className="text-muted">
                                                {
                                                  team_abbreviation == home ? t('vs') : '@'
                                                }
                                              </span>
                                              {" "}
                                              {
                                                team_abbreviation == away
                                                  ?
                                                  (home || '--')
                                                  :
                                                  (away || '--')
                                              }
                                            </>
                                          } */}

                                      {/* {!_.isEmpty(PlayerCardData) ? ( */}
                                      <Fragment>
                                        {/* {!is_single_player && <></>} */}
                                        {_.isUndefined(match.scheduled_date)
                                          ? "--"
                                          : Utils.DateFormat(
                                            Utils.getUtcToLocal(
                                              match.scheduled_date
                                            ),
                                            "DD MMM"
                                          )}
                                        <>
                                          {' | '}
                                          {
                                            //team_abbreviation == match.home ? 'vs' : '@'
                                            'vs'
                                          }
                                          {' '}
                                          {
                                            team_abbreviation == match.away
                                              ?
                                              (match.home || '--')
                                              :
                                              (match.away || '--')
                                          }
                                        </>
                                      </Fragment>
                                      {/* ) : (
                                          ""
                                        )} */}
                                    </p>
                                  </div>

                                  <div className="player-stats-tbl-wrap">
                                    {statsposting ? (
                                      <Utils.Spinner />
                                    ) : (
                                      <Fragment>
                                        {_.isEmpty(match.display_card) ? (
                                          <>
                                            <Alert color="light" className="text-center">
                                              {/* <div className="text-center"> */}
                                                <Trans>Stats Not Available</Trans>
                                              {/* </div> */}
                                            </Alert>
                                          </>
                                        ) : (
                                          <table className="player-stats-tbl">
                                            <thead>
                                              <tr>
                                                <td className="tbl-label">
                                                  <Trans>Statistics</Trans>
                                                </td>
                                                <td className="tbl-label">
                                                  <Trans>Value</Trans>
                                                </td>
                                                <td className="tbl-label">
                                                  <Trans>Points</Trans>
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {_.map(match.display_card, (item, idx) => {
                                                return (
                                                  <tr key={idx}>
                                                    <td className="table-txt">
                                                      {item.Label}
                                                    </td>
                                                    <td className="table-txt">
                                                      {item.Value == 0 ? " " : item.Value}
                                                    </td>
                                                    <td className="table-txt fw-bold">
                                                      {item.Point}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                            <tfoot>
                                              <tr>
                                                <td className="table-txt">
                                                  <Trans>Total Points</Trans>
                                                </td>
                                                <td> </td>
                                                <td className="total-val">
                                                  {_.sumBy(
                                                    match.display_card,
                                                    function (o) {
                                                      return Number(o.Point);
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                </Fragment>
                              );
                            })}
                          </TabPane>
                          <TabPane tabId="2">
                          <Row className='pfix-row'>
                    {
                        !posting ?
                            <Col className='fixtures-list-wrap'>
                              {/* <div className="weeek-block">{Utils.getMultiLeg().week_label[SelectedWeek]}</div> */}
                                <div className="fixtures-list content-list" >
                                    {
                                        _.map(FixturesList, (item, index) => {
                                            
                                            
                                          
                                            let firstChar = Utils.getUtcToLocal(item.season_scheduled_date, "dddd, MMM D");
                                            let ShowHeadShow = true;
                                            if (firstChar != current_date) {
                                                current_date = firstChar
                                                ShowHeadShow = true;
                                            }
                                            return (
                                                <Fragment key={index}>
                                                    {
                                                        <>
                                                        <div className="weeek-block cdl-clrs cbt-match">
                                                            <span>
                                                                {Utils.getMultiLeg().week_label[item.week]}
                                                            </span>
                                                        </div>
                                                        <div className="cweeek-block dl-clrs bt-match">
                                                          <span>
                                                          {firstChar}
                                                          </span>
                                                        </div>
                                                    </>
                                                    }

                                                    {
                                                        is_single_player ?
                                                            <>
                                                                <div className={`is_single_player fixture-item fixture-mt ${item.season_game_uid == CurrentFixtuerSelected && !this.isMatchSummaryEmpty() ? ' active' : ''}${item.season_game_uid == openingItem ? ' opening' : ''}`} onClick={(!opening && Utils.isDateTimePast(item.season_scheduled_date, item)) ? () => this.toggleFixtureDetail(item) : null}>

                                                                    <div className="cell">
                                                                        <div {...{ className: `fx-name ${Number(item.home_score) > Number(item.away_score) ? 'winner' : ''}` }} title={item.home_full_name}>{item.home_full_name}</div>
                                                                        <figure className='fx-flag'>
                                                                            <Img src={item.home_player_image} fallbacksrc={Images.COUNTRY_FLAG} alt="" />
                                                                        </figure>
                                                                    </div>

                                                                    <div className="cell mid">
                                                                        <div className="score-capsule">

                                                                            <img src={Images.CLOCK_FMT} alt='' />
                                                                            {

                                                                                Utils.DateFormat(Utils.getUtcToLocal(item.season_scheduled_date), "HH:mm")
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="cell last">
                                                                        <figure className='fx-flag'>
                                                                            <Img src={item.away_player_image} fallbacksrc={Images.COUNTRY_FLAG} alt="" />
                                                                        </figure>
                                                                        <div {...{ className: `fx-name ${Number(item.home_score) < Number(item.away_score) ? 'winner' : ''}` }} title={item.away_full_name}>{item.away_full_name}</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className={`radha fixture-item fixture-mt ${item.season_game_uid == CurrentFixtuerSelected && !this.isMatchSummaryEmpty() ? ' active' : ''}${item.season_game_uid == openingItem ? ' opening' : ''}`} onClick={(!opening && Utils.isDateTimePast(item.season_scheduled_date, item)) ? () => this.toggleFixtureDetail(item) : null}>
                                                                    <div className="cell">
                                                                        <div {...{ className: `fx-name ${(Number(item.home_team_goal) > Number(item.away_team_goal) || Number(item.home_team_score) > Number(item.away_team_score)) ? 'winner' : ''}` }} title={item.home_team_name}>
                                                                            <span className='mob-l-name'>{item.home}</span>
                                                                            {
                                                                                // (!item.home_team_goal && Utils.isDateTimePast(item.season_scheduled_date, item)) &&
                                                                                // <div className="fx-score-wrap">
                                                                                //     <b>{item.home_team_score}/{item.home_wickets}</b> <small>({item.home_overs} <Trans>OVR</Trans>)</small>
                                                                                // </div>
                                                                            }
                                                                        </div>
                                                                        <figure className='fx-flag'>
                                                                            <Img src={item.flag_home} fallbacksrc={Images.DEFAULT_FLAG} alt="" />
                                                                        </figure>
                                                                    </div>


                                                                    <div className="cell mid">
                                                                        <div className="score-capsule schedule-update">
                                                                            <img src={Images.CLOCK_FMT} alt='' />
                                                                            {

                                                                                Utils.DateFormat(Utils.getUtcToLocal(item.season_scheduled_date), "HH:mm")
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="cell last">
                                                                        <figure className='fx-flag'>
                                                                            <Img src={item.flag_away} fallbacksrc={Images.DEFAULT_FLAG} alt="" />
                                                                        </figure>
                                                                        <div {...{ className: `fx-name ${(Number(item.home_team_goal) < Number(item.away_team_goal) || Number(item.home_team_score) < Number(item.away_team_score)) ? 'winner' : ''}` }} title={item.away_team_name}>
                                                                            
                                                                            <span className='mob-l-name'>{item.away}</span>
                                                                            {
                                                                                // (!item.away_team_goal && Utils.isDateTimePast(item.season_scheduled_date, item)) &&
                                                                                // <div className="fx-score-wrap">
                                                                                //     <b>{item.away_team_score}/{item.away_wickets}</b> <small>({item.away_overs} <Trans>OVR</Trans>)</small>
                                                                                // </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </>
                                                    }
                                                    {
                                                        item.season_game_uid == CurrentFixtuerSelected &&
                                                        ''
                                                        // <FixtureDetails />
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                            :
                            <Utils.Spinner />
                    }

                </Row>
                          </TabPane>
                        </TabContent>
                      </div>


                      
                    </div>}
                </Fragment>
              )}
            </div>
          )}

          {/* && (Player.is_bench_player == 0 || _.isUndefined(Player.is_bench_player))  */}
          {enablePlayerRoleInit &&
            Utils.IsPlayerField(Player, LineupList) &&
            isLineupPlayer &&
            (CurrentLineup.user_join_count == 0 ||
              next_week == joined_week ||
              IS_TRASFER) && (
              <div className="player-card-footer">
                {isPlayerRoleView ? (
                  <a
                    className="profile-view-btn"
                    onClick={() => this.PlayerViewToggle(false)}
                  >
                    <Trans>View Full Profile</Trans>
                  </a>
                ) : (
                  <a
                    className="profile-view-btn"
                    onClick={() => this.PlayerViewToggle(true)}
                  >
                    <Trans>Back</Trans>
                  </a>
                )}
              </div>
            )}
        </Fragment>
      </Modal>
    );
  }
}
PlayerCard.defaultProps = {
  Player: {},
  isLineupPlayer: false,
  PlayerRoleInit: () => { },
  updateLineupList: () => { },
  RosterMasterData: [],
  LineupList: [],
  joined_week: null,
};
export default ReduxHOC(PlayerCard);
