/* eslint-disable */
import { Component, Fragment } from 'react';
import { API, Auth, Constant, Http, Images, Utils, _, withRouter, Trans } from 'Helpers';
import { Col, Container, Row, Modal, ModalBody } from 'reactstrap';
import { ReduxHOC } from 'HOC';

class SelectLeague extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: true,
      LeagueList: [],
      SportList: [],
      ShowEntireCom: true
    }
  }
  componentDidMount() {

    document.body.style.overflow = 'hidden';
    Http.post(API.SPORTS_LEAG_LIST).then(res => {
      const { data } = res;
      this.setState({
        SportList: data.sports,
        LeagueList: data.leagues,
      }, () => {
        // let selected_sports_id = Utils.getMultiLeg().sports_id;
        // if (_.size(data.leagues[selected_sports_id]) == 1 || this.props.is_root) {
        if (this.props.is_root) {
          this.setDefaultLeague(data)
        } else {
          this.setState({
            ShowEntireCom: false
          })
        }
        this.setState({
          posting: false
        })
      })
    }).catch(err => {
      console.log(err);
      this.setState({
        posting: false
      })
    })
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  setDefaultLeague = (data) => {
    const { sports, leagues } = data
    let _league = leagues[sports[0].sports_id][0]
    // let SelectedLeague = _.filter( leagues , (o) => o.status == 1 && Utils.isDateTimePast(o.league_end_date) )
    // console.log(JSON.stringify(SelectedLeague, null, '\t'))
    this.props.callback(true)
    this.getLeagueDetail({ ..._league, ...sports[0] }, false)
  }

  reload = () => {
    const { history } = this.props
    history.push(Constant.DASHBOARD_PATH);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  getLeagueDetail = (item, is_reload = false) => {
    const _this = this
    if (item.status == 0) {
      return;
    }
    Utils.setSinglePlayerFlag(item.sports_config.is_single_player);
    Utils.setMultiLeg(item);

    const excFn = function () {
      Utils.removeLineup()
      if (Auth.getAuth()) {
        Utils.removeLineup()
        Utils.setLineupMasterId(item).then(res => {
          if (is_reload) {
            _this.reload()
          } else {
            _this.setState({
              ShowEntireCom: false
            });
            if (_this.props.isOpen) {
              _this.props.dropdownToggle('SelectLeagueOpen')
            } else {
              if (!_.includes(_this.props.location.pathname, 'activation')) {
                _this.props.history.push(Constant.DASHBOARD_PATH)
              }
            }
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        if (is_reload) {
          setTimeout(() => {
            _this.reload()
          }, 100)
        } else {
          _this.setState({
            ShowEntireCom: false
          });
          if (_this.props.isOpen) {
            _this.props.dropdownToggle('SelectLeagueOpen')
          } else {
            if (!_.includes(_this.props.location.pathname, 'activation')) {
              _this.props.history.push(Constant.DASHBOARD_PATH)
            }
          }
        }
      }
    }
    this.GetAdvBanner(item).then(res => {
      excFn()
    }).catch(err => {
      excFn()
    })
  }


  GetAdvBanner = async ({ league_id, sports_id }) => {
    let s3_param = [
      API.S3_GET_BANNER,
      league_id
    ]
    let param = {
      league_id: league_id,
      sports_id: sports_id
    }
    let status = null
    // Http.post(API.GET_BANNER, param).then(res => {
    await Http.s3(Utils.s3UrlConvert(s3_param), API.GET_BANNER, param).then(res => {
      let AdData = []
      for (let ad of res.data.advertisements) {
        for (let pos of res.data.positions) {
          if (ad.ad_position_id == pos.ad_position_id) {
            AdData.push({ ...pos, ...ad })
          }
        }
      }
      Utils.SetSessionStorage('banner', AdData);
      // Redux Data Update
      const { actions } = this.props;
      actions.setAdData(AdData || {})
      status = AdData
    }).catch(err => {
      status = err
    })
    return status;
  }


  render() {
    const { isOpen, dropdownToggle } = this.props
    const { SportList, LeagueList, posting, ShowEntireCom } = this.state;
    if (ShowEntireCom) return <Utils.Spinner primary />
    return (
      <Modal isOpen={isOpen} toggle={Utils.getMultiLeg() ? () => dropdownToggle('SelectLeagueOpen') : () => { }} size={'md'} className='custom-modal select-league-wrap' backdrop='static'>
        {
          Utils.getMultiLeg() &&
          <i className="close-btn icon-cancel" onClick={() => dropdownToggle('SelectLeagueOpen')} />
        }
        <ModalBody>
          <Row >
            <Col>
              <img src={Images.LOGO_EN} alt="" className='select-league-logo' />
              <h5 className='league-header'><Trans>SELECT A</Trans> <span className="secondary-color"><Trans>LEAGUE</Trans></span></h5>
            </Col>
          </Row>
          <>
            {
              posting ?
                // <div className="spinner-static">
                // </div>
                <Utils.Spinner primary />
                :
                <>
                  {
                    _.map(SportList, (obj, i) => {
                      return (
                        <Fragment key={i}>
                          <Row key={i}>
                            <Col>
                              <div className="lg-sport-name">{!_.isUndefined(LeagueList[obj.sports_id]) && obj.sports_name}</div>
                            </Col>
                          </Row>
                          {
                            _.map(LeagueList[obj.sports_id], (item, idx) => {
                              return (
                                <Row key={idx}>
                                  <Col>
                                    <div className="league-card" onClick={() => this.getLeagueDetail({ ...item, ...obj }, true)}>
                                      <img src={item.image} className='icon-sports' alt="" />
                                      <div className='leg-title'>
                                        {item.league_name}
                                      </div>

                                      <div className="leg-last">
                                        <div {...{
                                          className: `playbtn ${item.status == 1 ? (Utils.isDateTimePast(item.league_end_date) ? 'disabled' : '') : 'disabled'}`
                                        }}>
                                          <span className='lets-play-text'>{item.status == 1 ? <Trans>let's play</Trans> : <Trans>coming soon</Trans>}</span>
                                        </div>
                                      </div>

                                      <div {...{ className: `status-indicator ${Utils.dateIsBetween(item.league_schedule_date, item.league_end_date) ? 'live' : (Utils.isDateTimePast(item.league_end_date) ? <Trans>completed</Trans> : <Trans>upcoming</Trans>)}` }}>
                                        <div className="livenow">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                        <span>
                                          {
                                            Utils.dateIsBetween(item.league_schedule_date, item.league_end_date) ? <Trans>live</Trans> : (Utils.isDateTimePast(item.league_end_date) ? <Trans>completed</Trans> : <Trans>upcoming</Trans>)
                                          }
                                        </span>
                                      </div>

                                    </div>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </Fragment>
                      )
                    })
                  }
                </>
            }
          </>
        </ModalBody>
      </Modal>
    );
  }
}
SelectLeague.defaultProps = {
  isOpen: false,
  dropdownToggle: () => { },
  callback: () => { },
  is_root: false
}
const SelectLeagueWrap = withRouter(SelectLeague, { withRef: true })
export default ReduxHOC(SelectLeagueWrap);