/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, Fragment } from 'react';
import { Images, NavLink, Trans } from 'Helpers';

class MessageBox extends Component {
  render() {
    const { Icon, Title, Subtitle, ButtonLabel, ButtonPath, LinkLabel, LinkPath, className, onClick } = this.props;
    return (
      <div className={`message-box ${className ? className : ''}`}>
        <div className="inner">
          {Icon ? <img src={Icon} className='message-box-img' alt="" /> : <img src={Images.LOGO_EN} className='message-box-img logo' alt="" />}
          {Title && <h6 className='message-box-title'><Trans>{Title}</Trans></h6>}
          {Subtitle && <div className="color-primary"><Trans>{Subtitle}</Trans></div>}
          {
            onClick ?
            <Fragment>
              {ButtonLabel && <a onClick={() => onClick()} className="btn btn-primary normal btn-sm"><Trans>{ButtonLabel}</Trans></a>}
              {LinkLabel && <a onClick={() => onClick()}><Trans>{LinkLabel}</Trans></a>}
            </Fragment>
            :
            <Fragment>
              {ButtonLabel && ButtonPath && <NavLink exact to={ButtonPath} className="btn btn-primary normal btn-sm"><Trans>{ButtonLabel}</Trans></NavLink>}
              {LinkLabel && LinkPath && <NavLink exact to={LinkPath}><Trans>{LinkLabel}</Trans></NavLink>}
            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default MessageBox
