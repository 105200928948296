/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col, FormGroup, Input, Button, Form, Label } from 'reactstrap'
import { SocialForm } from 'Components'
import {
  Utils, Http, API,
  // Auth, 
  Constant, withRouter, Trans
} from 'Helpers'
import Select from 'react-select';
import ReduxHOC from 'HOC/ReduxHOC/ReduxHOC';
import DatePicker from 'react-date-picker';

const md5 = require('md5');
class SignupFormSM extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      posting: false,
      formValid: false,
      isEye: false,
      country_list: [],
      first_name: "",
      last_name: "",
      user_name: "",
      phone_no: "",
      email: "",
      password: "",
      country: null,
      phonecode: '',
      opt_marketing_email: false,
      _gender: '',
      dob: '',
      zip_code: '',

    }
  }

  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (name === 'password') {
      value = value.trim()
    }
    if (type == 'country') {
      this.setState({
        phonecode: '+' + value.phonecode
      })
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  handleCheckboxChange = (value) => {
    this.setState({ opt_marketing_email: value });
  };

  onCalendarOpen = () => {
    if (this.state.dob == '' || this.state.dob == null) {
      this.setState({ dob: Utils.getDateYearsOld() }, this.validateForm)
    }
  }
  onDateChange = (dob) => {
    this.setState({ dob }, this.validateForm)
  }

  validateForm() {
    const {
      email,
      password,
      first_name,
      user_name,
      country,
      _gender,
      dob
    } = this.state;
    this.setState({
      formValid:
        password != '' &&
        email != '' &&
        first_name != '' &&
        user_name != '' &&
        _gender != '' &&
        dob != '' &&
        //!_.isEmpty(_gender) &&
        //!_.isEmpty(dob) &&
        //!_.isEmpty(country) &&
        Utils.isFormValid([
          'password',
          'email',
          'first_name',
          'last_name',
          'phone_no',
          'user_name',
        ], this.state)
    });
  }
  sendMessageToApp(action) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    } catch (error) { }
  }

  getCountryData = () => {
    let s3_param = [
      API.S3_GET_COUNTRY_LIST,
    ]
    // Http.s3(Utils.s3UrlCtConvert(s3_param)).then(response => {
    Http.s3(Utils.s3UrlCtConvert(s3_param), API.GET_COUNTRY_LIST).then(response => {
      this.setState({
        country_list: response.data.country_list,
        posting: false
      }, () => {
        try {
          const mdt = Utils.getMasterData()
          const _country = _.filter(response.data.country_list, o => o.master_country_id == '101')
          console.log(_country, 'gutter-fix')
          this.setState({
            country: _country[0]
          }, () => {
            const { country } = this.state
            this.setState({
              phonecode: '+' + country.phonecode
            });
          })
        } catch (error) {

        }

      });
    }).catch(error => {
      this.setState({ posting: false });
    });
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const {
      email,
      password,
      first_name,
      last_name,
      user_name,
      phone_no,
      country,
      opt_marketing_email,
      zip_code,
      dob,
      _gender
    } = this.state;
    const { isGuestUser, history, closeModal, onTeamSubmit, actions } = this.props;

    let param = {
      "first_name": first_name,
      "last_name": last_name,
      "user_name": user_name,
      "phone_no": phone_no,
      "master_country_id": country ? country.master_country_id : '',
      "email": email,
      "password": md5(password),
      "device_type": Constant.DEVICE_TYPE,
      "device_id": Constant.DEVICE_ID,
      "terms": true,
      "opt_marketing_email": opt_marketing_email ? 1 : 0,
      "zip_code": zip_code,
      "dob": dob == null ? dob : Utils.DobFormat(dob),
      "gender": !_.isEmpty(_gender) ? _gender.id : ''
    };

    Http.post(API.SIGNUP, param).then(response => {
      Utils.notify(response.message, 'success', 5000);
      this.props.history.push(Constant.DEFAULT_ROOT)
      actions.gtmEventFire('sign_up', {
        'user_id': response.data.user_profile.user_unique_id,
        'user_name': response.data.user_profile.user_name,
        'signup_method': 'email',
      }, true)
      closeModal()
      // if (window.ReactNativeWebView) {
      //   let data = {
      //     action: "login",
      //     type: 'login',
      //     targetFunc: "loginFunc",
      //     userData: response.data
      //   };
      //   this.sendMessageToApp(data);
      // }
      // Auth.setAuth(response.data.session_key);
      // Utils.setProfile(response.data.user_profile);
      // Utils.setExistingUser(response.data.user_profile.existing_user)
      // Utils.setLineupMasterId().then(res => {
      //   window.location.reload();
      // })

      // OLD Comment
      // Utils.GetSportLeagues().then(res => {
      //   // DefaultHeader.ProfileFetch()
      //   // MobileHeader.ProfileFetch()
      //   if(isGuestUser) {
      //     onTeamSubmit()
      //   } else {
      //     history.push(Constant.DASHBOARD_PATH);
      //     toggle()
      //   }
      // })
      this.setState({ posting: false });
    }).catch(error => {
      this.setState({ posting: false });
      // if(!isGuestUser) {
      //   toggle()
      // }
    });
  }

  componentDidMount() {
    this.getCountryData()
    // const { actions } = this.props
    // actions.gtmEventFire('signup_open')

  }

  render() {
    const { toggleForm, t, i18n } = this.props;
    const {
      isEye,
      email,
      password,
      first_name,
      last_name,
      user_name,
      phone_no,
      formValid,
      posting,
      country_list,
      country,
      phonecode,
      opt_marketing_email,
      _gender,
      dob,
      zip_code,

    } = this.state;
    const SocialFormProps = {
      ...this.props
    }
    const SelectProps = {
      // isSearchable: false,
      className: 'react-select plain',
      classNamePrefix: 'react-select',
    }
    const gender_list = [
      { id: 'male', name: <Trans>Male</Trans> },
      { id: 'female', name: <Trans>Female</Trans> },
      { id: 'other', name: <Trans>Other</Trans> },
      { id: 'doesnt_specify', name: <Trans>Doesn't wish to specify</Trans> },
    ]

    return (
      <div>
        <div className="landing-form popup">
          <Row className='onboarding-gutter-fix'>
            <Col md={5}>
              <h4 className='landing-form-title tal-su'><Trans>Sign Up</Trans></h4>
            </Col>
            <Col>
              <div className="form-switch-wrap new-fix-pws"><Trans>Already Registered?</Trans> <a className='text-medium color-blk' onClick={() => toggleForm(true)}><Trans>Login</Trans></a></div>
            </Col>
          </Row>
          <Row className='onboarding-gutter-fix'>
            <Col md={12}>
              {/* autocomplete="username" */}
              <Form onSubmit={this.onSubmit} autoComplete={'off'} id='SignUpForm'>
                <Row className='gutters-5px gutter-fix'>
                  <Col>
                    <FormGroup>
                      <Input type="text"
                        id='first_name'
                        name='first_name'
                        placeholder={t('First Name')}
                        required
                        value={first_name}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("first_name", first_name)}
                        valid={Utils.isValid("first_name", first_name)} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input type="text"
                        id='last_name'
                        name='last_name'
                        placeholder={t('Last Name')}
                        required
                        value={last_name}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("last_name", last_name)}
                        valid={Utils.isValid("last_name", last_name)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Select
                        placeholder={t("Gender")}
                        className='react-select plain color-black'
                        classNamePrefix="react-select"
                        value={_gender}
                        onChange={e => this.handleChange(e, '_gender')}
                        options={gender_list}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Col>
                      <FormGroup>
                        {/* <Input type="text" placeholder="Date of birth" /> */}

                        <DatePicker
                          dayPlaceholder={i18n.language == "en" || i18n.language == "en-US" ? t('Date') : t('Date of Birth')}
                          monthPlaceholder={i18n.language == "en" || i18n.language == "en-US" ? t('of') : ''}
                          yearPlaceholder={i18n.language == "en" || i18n.language == "en-US" ? t('Birth') : ''}
                          format={dob ? "dd-MM-yyyy" : "ddMMyyyy"}
                          locale={i18n.language}
                          onChange={this.onDateChange}
                          onCalendarOpen={this.onCalendarOpen}
                          value={dob}
                          calendarIcon={<i className='icon-calender' />}
                          className='form-control'
                          clearIcon={null}
                          maxDate={Utils.getDateYearsOld()}
                          showDoubleView={false}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input type="email"
                        id='email'
                        name='email'
                        placeholder={t('Email')}
                        required
                        value={email}
                        autoComplete="username"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("email", email)}
                        valid={Utils.isValid("email", email)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className='pwd-form-group'>
                      <i className={`pwd-eye ${isEye ? 'icon-eye' : 'icon-eye-hide'}`} onClick={() => this.setState({ isEye: !isEye })}></i>
                      <Input
                        id='password'
                        name='password'
                        placeholder={t('Password')}
                        required
                        type={isEye ? 'text' : 'password'}
                        maxLength={50}
                        value={password}
                        autoComplete="password"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", password)}
                        valid={Utils.isValid("password", password)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input type="text"
                        id='user_name'
                        name='user_name'
                        required
                        placeholder={t('Username')}
                        value={user_name}
                        autoComplete='off'
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("user_name", user_name)}
                        valid={Utils.isValid("user_name", user_name)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Select
                        {...SelectProps}
                        placeholder={t("Select Country")}
                        value={country}
                        options={country_list}
                        onChange={e => this.handleChange(e, 'country')}
                        getOptionLabel={Utils.getLang() === "mal" ?
                          ({ mal_country_name }) => (`${mal_country_name}`) :
                          Utils.getLang() === "ar" ? ({ ar_country_name }) => (`${ar_country_name}`) :
                            Utils.getLang() === "ben" ? ({ ben_country_name }) => (`${ben_country_name}`) :
                              ({ en_country_name }) => (`${en_country_name}`)
                        }
                        getOptionValue={(master_country_id) => master_country_id}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='gutters-5px gutter-fix' >
                  <Col xs={4}>
                    <FormGroup>
                      <Input type="text"
                        placeholder={t('Phone Code')}
                        value={phonecode}
                        autoComplete="off"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input type="text"
                        id='phone_no'
                        name='phone_no'
                        placeholder={t('Mobile')}
                        value={phone_no}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("phone_no", phone_no)}
                        valid={Utils.isValid("phone_no", phone_no)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder={t("Postal Code")}
                        name='zip_code'
                        value={zip_code}
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("zip_code", zip_code)}
                        valid={Utils.isValid("zip_code", zip_code)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>

                    <FormGroup check className='form-check-signup'>
                      <Input type="checkbox"
                        id='opt_marketing_email'
                        name='opt_marketing_email'
                        checked={opt_marketing_email}
                        onChange={() => {
                          opt_marketing_email == true ? this.handleCheckboxChange(false) : this.handleCheckboxChange(true)
                        }}
                      />
                      <Label check for='opt_marketing_email'>
                        <Trans>I am happy to receive news, offers and ticketing updates from the  {`${process.env.REACT_APP_DOC_TITLE}`}. You can unsubscribe at any time.</Trans>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className='m-t-20 sm'>
                  <Col>
                    <Button type='submit' size="lg" className='btn-block'
                      disabled={!formValid || posting}><Trans>Sign Up</Trans></Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-switch-wrap sm"><Trans>By signing up, you agree to the</Trans> <a className='text-medium' onClick={() => this.props.history.push("term-and-privacy-policy")}><Trans>Terms & Privacy Policy</Trans></a></div>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md={12}>
              <SocialForm className="popup" {...SocialFormProps} />
            </Col>
            {/* <Col md={12}>
              <div className="form-switch-wrap"><Trans>Already Registered?</Trans> <a className='text-medium color-blk' onClick={() => toggleForm(true)}><Trans>Login</Trans></a></div>
            </Col> */}
          </Row>

        </div>

      </div>
    )
  }
}
SignupFormSM.defaultPorps = {
  onTeamSubmit: () => { },
  closeModal: () => { },
  isGuestUser: false
}
const SignupFormSMWraped = ReduxHOC(SignupFormSM);
export default withRouter(SignupFormSMWraped)
