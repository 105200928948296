/* eslint-disable */
import { Component } from 'react';
import Select from 'react-select';
import { notify } from 'react-notify-toast';
import { FormGroup, Input } from 'reactstrap';
import { Range } from 'rc-slider';
import { API, Constant, Http, Images, Trans, Utils, _ } from 'Helpers';
import { PlayerCard } from 'Modals';
import { ReduxHOC } from 'HOC';
import InfiniteScroll from 'react-infinite-scroll-component';
const {
  REACT_APP_SALARY_SUBFFIX
} = process.env

const PlayerItem = ({
  AppMasterData,
  LineupList,
  sports_config,
  item,
  RemovePlayer,
  AddPlayer,
  PlayerCardInit,
  is_single_player, SALARY_CAP,
  selected_sorting,
  showImage
}) => {


  return (
    <>
      {!_.isUndefined(item) &&
        <div {...{
          className: `player-item`
        }}>
          {
            item.rank &&
            <div className="pl-rank">{item.rank}</div>
          }
          <div className="thumb-wrap" onClick={() => PlayerCardInit(item)}>
            {( item.player_other_status == "1" ||  item.player_other_status == "2" ||  item.player_other_status == "3") && 
              <i className='player-status-icon icon-alert_ic'></i>
            }
            {
              is_single_player ?
                <img src={item.player_image || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                :
                <>
                  <img src={showImage(item)} alt="" className='thumb' />
                  <img src={item.flag || Images.DEFAULT_FLAG} alt="" className='flag' />
                </>
            }

          </div>
          <div className="player-info">
            <div className="player-name " onClick={() => PlayerCardInit(item)}><span title={item.full_name}>{item.full_name}</span></div>
            {
              is_single_player ?
                <div className="player-team">{item.country || '--'}</div>
                :
                <div className="player-team">{item.team_abbr_lang}
                  {
                    item.player_other_status == "1" && 
                    <span className="player-status gray">
                      <Trans>Suspended</Trans>
                    </span>
                  }
                   {
                    item.player_other_status == "2" && 
                    <span className="player-status red">
                      <Trans>Eliminated</Trans>
                    </span>
                  }
                  {
                    item.player_other_status == "3" && 
                    <span className="player-status yellow">
                      <Trans>Injured</Trans>
                    </span>
                  }
                </div>
            }
          </div>
          {
            (SALARY_CAP != 0) &&
            <div className='cell salary' onClick={()=>[Utils.IsPlayerExist(item, LineupList) ? RemovePlayer(item) : AddPlayer(item) ]}>{AppMasterData.CURRENCY_CODE}{item.salary} {REACT_APP_SALARY_SUBFFIX}</div>
          }

          <div className='cell' onClick={()=>[Utils.IsPlayerExist(item, LineupList) ? RemovePlayer(item) : AddPlayer(item) ]}>
            {/* {item.fantasy_score || '--'} */}
            {selected_sorting.value == '' || selected_sorting.value == 'salary'  ? item.fantasy_score : item[selected_sorting.value]
            }
          </div>
          <div className='cell'>

            {
              Utils.IsPlayerExist(item, LineupList) ?
                <button {...{ className: `player-toggle selected` }} onClick={() => [RemovePlayer(item)]}>
                  <i {...{ className: `icon-check` }} />
                </button>
                :
                <button {...{ className: `player-toggle` }} onClick={() => [AddPlayer(item)]} >
                  <i {...{ className: `icon-plus` }} />
                </button>
            }

          </div>
        </div>}
    </>
  )
}

const PlayerPosGroup = (props) => {
  return (
    <>
      <div className="player-list-header">
        {
          props.is_single_player &&
          <div className="pl-rank"><Trans>Rank</Trans></div>
        }<div className="cell"></div>
        <div className="player-info"><Trans>Player</Trans> ({props.list[0].position_name})</div>
        {
          (props.SALARY_CAP != 0) &&
          <div className="cell"><Trans>Salary</Trans> ({props.AppMasterData.CURRENCY_CODE})</div>
        }
        <div className="cell"><Trans>TP</Trans></div>
        <div className="cell"></div>
      </div>
      <div className="player-list">
        {
          _.map(props.list, (item, idx) => {
            return (
              <PlayerItem item={item} key={idx} {...props} />
            )
          })
        }
      </div>
    </>
  )
}

class PlayerList extends Component {

  constructor(props) {
    const { SelectedLeague, root } = props
    super(props);
    this.state = {
      posting: false,
      IsAllPlayerRoster: false,
      isFilterOpen: false,
      selected_league: Utils.getMultiLeg(),
      selected_team: {},
      selected_position: {},
      selected_sorting: {},
      PriceRange: [0, 16],
      RosterStore: [],
      RosterArr: [],
      is_single_player: Utils.isSinglePlayer(),
      SelectedPlayer: {},
      isPlayerCardShow: false,
      AppMasterData: root.AppMasterData,
      hasMore: false,
      Offset: Constant.CURRENT_OFFSET,
      RosterArrEmpty: false,
      player_search_str: '',
      RosterItems: Array.from({ length: Constant.ITEMS_PERPAGE }),
      selected_value: ''
    }
    this.node = null;
    this.playerSearchHandle = _.debounce(this.playerSearchHandle.bind(this), 1000);
    this.showNotify = notify.createShowQueue();
    this.setInstance = this.setInstance.bind(this)
  }

  // Method(s)

  rosterFilterUpdate = (obj) => {
    if (!obj) return;
    const { ALL_POSITION } = this.props;
    let flt = _.filter(ALL_POSITION, (item) => {
      return item.position === obj.position_clone
    })
    this.handleChange(flt[0], 'selected_position')
  }

  filterToggle = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen
    })
  }

  _filterHandler = () => {
    const { IS_TRASFER, LineupList } = this.props;
    const {
      RosterStore,
      selected_position,
      selected_team,
      selected_sorting,
      player_search_str,
      PriceRange
    } = this.state;

    const _fltrData = Utils.RosterFilterInit({
      is_trasfer: IS_TRASFER,
      LineupList: LineupList,
      data: RosterStore,
      selected_position: selected_position,
      selected_sorting: selected_sorting,
      selected_team: selected_team,
      player_search_str: player_search_str,
      PriceRange: PriceRange
    })
    const _condition = _fltrData.length >= Constant.SUCCESS_CODE

    this.setState({
      RosterItems: Array.from({ length: _condition ? Constant.SUCCESS_CODE : _fltrData.length }),
      RosterArr: _fltrData
    }, () => {
      const { RosterItems, RosterArr } = this.state;
      const __condition = (RosterArr.length - RosterItems.length) >= Constant.SUCCESS_CODE;

      this.setState({
        hasMore: __condition
      })
    });

    if (selected_position.position == '' && selected_team.team_id == '' && selected_sorting.value == '' && player_search_str == '' && name != 'player_search_str') {
      // Default Mode
      console.log('Default Case!!');
      // this.setState({
      //   IsAllPlayerRoster: false,
      //   RosterArr: [],
      //   hasMore: false,
      //   RosterArrEmpty: false
      // }
      //   // , () => this.GetRosterByPosition()
      // );
    } else {
      console.log('Conditional Case!!');
    }
  }

  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    this.setState({
      [name]: value,
    }, () => {
      this._filterHandler()
    });
  };

  playerSearchHandle() {
    // this.setState({ CurrentPage: 1 }, () => {
    //   const { selected_position, selected_team, selected_sorting, player_search_str } = this.state;
    //   if (selected_position.position == '' && selected_team.team_id == '' && selected_sorting.value == '' && player_search_str == '') {
    //     this.setState({
    //       IsAllPlayerRoster: false,
    //       RosterArr: [],
    //       hasMore: false,
    //       RosterArrEmpty: false
    //     }, () => this.GetRosterByPosition());
    //   } else {
    //     this.getAllRoster()
    //   }
    // });
  }

  setInstance(node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        rosterFilterUpdate: this.rosterFilterUpdate
      })
    }
  }

  priceHandleChange = (PriceRange) => {
    this.setState({ PriceRange, selected_value: PriceRange[0] }, () => {
      this._filterHandler()
    })
    // console.log(PriceRange[0], 'roster-filter-wrap')
  }


  GetRosterByPosition = () => {
    this.setState({
      posting: true
    });

    const {
      selected_league,
      selected_sorting
    } = this.state;

    let param = {
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
      "sort_field": (_.isUndefined(selected_sorting) ? '' : selected_sorting.value) || '',
      "in_position_group": 1,
    };

    Http.post(API.GET_ALL_ROSTER, param).then(response => {
      const { data } = response;
      this.setState({
        RosterArr: data.result,
      }, () => {
        this.setState({
          posting: false
        })
      })
    }).catch(error => {
      this.setState({
        posting: false
      })
    });
  }
  // GET_ALL_ROSTER/GET_ROSTER_FOR_TRANSFER
  getAllRoster = () => {
    this.setState({
      isLoaderShow: true
    });
    const {
      selected_league,
    } = this.state;

    let param = {
      "is_score_stats_needed":"1",
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
    }

    Http.post(API.GET_ALL_ROSTER, param).then(response => {
      const { data } = response;
      this.setState({
        IsAllPlayerRoster: true,
        RosterArr: data.result,
        RosterStore: _.cloneDeep(data.result),
        isLoaderShow: false,
      }, () => {
        this.props.getMasterRosterList(this.state.RosterStore)
        this.setState({
          hasMore: true,
          RosterArrEmpty: _.isEmpty(data.result) && _.isEmpty(this.state.RosterArr)
        })
      })
    }).catch(error => {
      this.setState({
        isLoaderShow: false
      })
    });
  }

  RemovePlayer = (player) => {
    const { LineupList, IS_TRASFER, next_week, joined_week } = this.props;
    let param = {
      player: player,
      lineup: LineupList,
      next_week, joined_week
    }
    if (IS_TRASFER) {
      Utils.setTransferData(player, '0')
    }
    Utils.RemovePlayerFromLineup(param).then((response) => {
      this.props.updateLineupList(response);
      if (IS_TRASFER) {
        // player
      }
    })
    this.props.setActive(false)
  }

  AddPlayer = (player) => {
    const { LineupList, RosterMasterData, t, ALL_POSITION, lineup_roster_limit, next_week, IS_TRASFER, SALARY_CAP } = this.props;
    let param = {
      next_week,
      lineup_roster_limit,
      player,
      lineup: LineupList,
      salary: Utils.AvailableSalary(SALARY_CAP, LineupList),
      max_player_per_team: RosterMasterData.max_player_per_team,
      all_position: ALL_POSITION
    }
    Utils.AddPlayerToLineup(param).then((response) => {
      if (IS_TRASFER) {
        Utils.setTransferData(player, '1')
      }
      this.props.updateLineupList(response);
    }).catch((err) => {
      console.error(err);
      // this.showNotify(t(err), "error", 5000)
      Utils.notify(t(err), "error", 5000)
    })
    this.props.setActive(true)
  }


  PlayerCardInit = (SelectedPlayer) => {
    this.setState({
      SelectedPlayer
    }, () => {
      this.PlayerCardToggle()
    })
  }
  PlayerCardToggle = () => {
    this.setState({
      isPlayerCardShow: !this.state.isPlayerCardShow
    })
  }

  PlayerRoleInit = (player, role) => {
    const { LineupList } = this.props;
    let _LineupList = _.map(LineupList, (item) => {
      if (item.player_role == role) {
        item.player_role = 0
      }
      if (item.player_uid == player.player_uid) {
        item.player_role = role
      }
      return item
    })
    this.setState({
      isPlayerCardShow: false
    }, () => {
      this.props.updateLineupList(_LineupList)
    })

  }

  // Lifecycle(s)
  componentDidMount() {
    // const { is_single_player } = this.state
    // if (!is_single_player) {
    //   this.GetRosterByPosition()
    // } else {
    //   this.getAllRoster()
    // }
    this.getAllRoster()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if(this.props.updatePlayerList != nextProps.updatePlayerList) {
    // }
    if (!_.isEmpty(nextProps.ALL_POSITION) && _.isEmpty(this.state.selected_position)) {
      this.setState({
        selected_position: nextProps.ALL_POSITION[0]
      })
    }
    if (!_.isEmpty(nextProps.ALL_TEAM) && _.isEmpty(this.state.selected_team)) {
      this.setState({
        selected_team: nextProps.ALL_TEAM[0]
      })
    }
    if (!_.isEmpty(nextProps.SORT_BY_LIST) && _.isEmpty(this.state.selected_sorting)) {
      this.setState({
        selected_sorting: nextProps.SORT_BY_LIST[0]
      })
    }

    // if(this.props.updatePlayerList != nextProps.updatePlayerList) {
    //   this.setState({
    //     selected_position: nextProps.ALL_POSITION[0],
    //     selected_team: nextProps.ALL_TEAM[0],
    //     selected_sorting: ''
    //   })
    // }

  }

  fetchMoreData = () => {
    const { RosterItems, RosterArr } = this.state;
    const _condition = (RosterArr.length - RosterItems.length) >= Constant.ITEMS_PERPAGE
    this.setState({
      RosterItems: RosterItems.concat(Array.from({ length: _condition ? Constant.ITEMS_PERPAGE : RosterArr.length - RosterItems.length })),
      hasMore: _condition
    });
  }
  showImage(item){
    if(item.player_image){
      return item.player_image
    } else if(item.jersey){
      return item.jersey 
    } else {
      return Images.DEFAULT_JERSEY
    }
  }
   

  render() {
    const {
      i18n,
      ALL_TEAM,
      ALL_POSITION,
      SORT_BY_LIST,
      SALARY_CAP,
      sports_config,
      LineupList,
      RosterMasterData,
      updateLineupList,
      lineup_roster_limit,
      t,
      IS_TRASFER
    } = this.props;


    const {
      posting,
      selected_position,
      selected_team,
      selected_sorting,
      player_search_str,
      isFilterOpen,
      PriceRange,
      RosterArr,
      IsAllPlayerRoster,
      is_single_player,
      SelectedPlayer,
      isPlayerCardShow,
      selected_league,
      AppMasterData,
      isLoaderShow,
      hasMore,
      RosterItems,
      selected_value
    } = this.state;

    const min = 0;
    const max = 16;

    const RangeProps = true ? {
      reverse: i18n.language === 'ar',
      min: Number(min),
      max: Number(max),
      step: 0.5,
      defaultValue: PriceRange,
      marks: {
        [Number(min)]: `${AppMasterData.CURRENCY_CODE}${PriceRange[0]} ${REACT_APP_SALARY_SUBFFIX}`,
        [Number(max)]: `${AppMasterData.CURRENCY_CODE}${PriceRange[1]} ${REACT_APP_SALARY_SUBFFIX}`
      },
      onAfterChange: this.priceHandleChange,
      allowCross:false
    } : null

    const PlayerItemProps = {
      is_single_player,
      sports_config,
      AppMasterData,
      LineupList,
      PlayerCardInit: this.PlayerCardInit,
      RemovePlayer: this.RemovePlayer,
      AddPlayer: this.AddPlayer,
      selected_sorting:selected_sorting,
      showImage:this.showImage
    }

    const PlayerCardProps = {
      IS_TRASFER,
      lineup_roster_limit,
      ALL_POSITION,
      LineupList,
      selected_league,
      RosterMasterData,
      isOpen: isPlayerCardShow,
      Player: SelectedPlayer,
      isLineupPlayer: true,
      updateLineupList: updateLineupList,
      toggle: this.PlayerCardToggle,
      PlayerRoleInit: this.PlayerRoleInit,
    }


    return (
      <span ref={this.setInstance}>
        <div className="roster-filter-wrap" >
          {
            !SALARY_CAP ?
              <Utils.Spinner primary />
              :
              <>
                <div className="tb-row">
                  <div className="tb-cell" {...{ className: `tb-cell` }}>
                    <div className="filter-lbl"><Trans>Add Players</Trans></div>
                    <Select
                      isSearchable={false}
                      className='react-select outline'
                      classNamePrefix='react-select'
                      options={ALL_POSITION}
                      value={selected_position}
                      onChange={e => this.handleChange(e, 'selected_position')}
                      getOptionLabel={({ position_display_name }) => position_display_name}
                      getOptionValue={({ position }) => position}
                    />
                  </div>
                  {
                    SALARY_CAP == 0 ?
                      <div className="tb-cell" {...{ className: `tb-cell` }}>
                        <div className="filter-lbl"><Trans>Sort By</Trans></div>
                        <Select
                          isSearchable={false}
                          className='react-select outline'
                          classNamePrefix='react-select'
                          options={SORT_BY_LIST}
                          value={selected_sorting}
                          onChange={e => this.handleChange(e, 'selected_sorting')}
                        />
                      </div>
                      :
                      <div className="tb-cell" {...{ className: `tb-cell` }}>
                        <div className="filter-lbl"><Trans>By Team</Trans></div>
                        <Select
                          isSearchable={true}
                          className='react-select outline'
                          classNamePrefix='react-select'
                          options={ALL_TEAM}
                          value={selected_team}
                          onChange={e => this.handleChange(e, 'selected_team')}
                          getOptionLabel={({ team_name }) => team_name}
                          getOptionValue={({ team_league_id }) => team_league_id}
                        />
                      </div>
                  }

                  <button {...{ className: `filter-btn ${isFilterOpen ? 'open' : ''}`, onClick: () => this.filterToggle() }}>
                    <i className="icon-filter" />
                  </button>
                </div>
                {
                  isFilterOpen &&
                  <div className="filter-extend">
                    <div className="tb-row">
                      {
                        SALARY_CAP != 0 &&
                        <div className="tb-cell" {...{ className: `tb-cell` }}>
                          <div className="filter-lbl"><Trans>Sort By</Trans></div>
                          <Select
                            isSearchable={false}
                            className='react-select outline bg-clr-fx'
                            classNamePrefix='react-select'
                            options={SORT_BY_LIST}
                            value={selected_sorting}
                            onChange={e => this.handleChange(e, 'selected_sorting')}
                          />
                        </div>
                      }
                      <div className="tb-cell" {...{ className: `tb-cell ${SALARY_CAP != 0 ? '' : "full-width"}` }}>
                        <div className='filter-search'>
                          <FormGroup>
                            <Input
                              type="text"
                              name='player_search_str'
                              value={player_search_str}
                              placeholder={t('Search')}
                              className="filter-dropdown-toggle"
                              onChange={this.handleChange}
                              onKeyUp={this.playerSearchHandle} />
                            <i className="icon-search" />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="tb-row">
                      {
                        SALARY_CAP != 0 &&
                        <div className="tb-cell flex-for-price" {...{ className: `tb-cell full-width flex-for-price` }}>
                          <div className="filter-lbl"><Trans>Price </Trans>
                          {/* <span>{AppMasterData.CURRENCY_CODE} {selected_value == '' ? '0' : selected_value} {REACT_APP_SALARY_SUBFFIX}</span> */}
                          </div>
                          <div className="range-slider-wrap">
                            <Range {...RangeProps} />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
              </>
          }
        </div>

        {
          IsAllPlayerRoster ?
            <>
              <div className="player-list-header">
                {
                  is_single_player &&
                  <div className="pl-rank"><Trans>Rank</Trans></div>
                }
                {/* <div className="cell"></div> */}
                <div className="player-info"><Trans>Player</Trans></div>
                {
                  (SALARY_CAP != 0) &&
                  <div className="cell"><Trans>Salary</Trans> ({AppMasterData.CURRENCY_CODE})</div>
                }
                <div className="cell tp-atl">
                 {selected_sorting.value == '' || selected_sorting.value == 'salary' ?  <Trans>TP</Trans> : <Trans>{selected_sorting.label}</Trans>}
                </div>
                {/* <div className="cell"></div> */}
              </div>
              <div className={`player-list-wrap`}>
                {/* {
                    _.isEmpty(RosterArr) || posting ?
                    <Utils.Spinner primary />
                } */}
                <div className={IS_TRASFER ? 'player-list h-fix-pl' : "player-list"} id='playerList'>
                  <InfiniteScroll
                    dataLength={RosterItems.length}
                    next={this.fetchMoreData}
                    hasMore={hasMore}
                    scrollableTarget='playerList'
                  // loader={<h4>Loading...</h4>}
                  >
                    {
                      _.map(RosterItems, (item, idx) => {
                        return (
                          <PlayerItem item={RosterArr[idx]} key={idx} {...PlayerItemProps} />
                        )
                      })
                    }
                    <Utils.FlexboxLoader init={isLoaderShow} className='notification-loader' />
                  </InfiniteScroll>
                  {/* <InfiniteScroll
                    dataLength={RosterArr.length}
                    next={this.fetchMoreData}
                    hasMore={true}
                    scrollableTarget='playerList'
                    loader={<h4>Loading...</h4>}
                  >
                    {
                      _.map(RosterArr, (item, idx) => {
                        return (
                          <PlayerItem item={item} key={idx} {...PlayerItemProps} />
                        )
                      })
                    }
                    <Utils.FlexboxLoader init={isLoaderShow} className='notification-loader' />
                  </InfiniteScroll> */}
                </div>
              </div>
            </>
            :
            <div className={`player-list-wrap`}>
              {
                _.isEmpty(RosterArr) || posting ?
                  <Utils.Spinner primary />
                  :
                  <>
                    {
                      _.map(RosterArr, (item, idx) => {
                        return (
                          <PlayerPosGroup key={idx} list={item} {...PlayerItemProps} />
                        )
                      })
                    }
                  </>
              }

            </div>
        }

        {
          isPlayerCardShow &&
          <PlayerCard {...PlayerCardProps} />
        }
      </span>
    );
  }
}
PlayerList.defaultProps = {
  SALARY_CAP: null
}
export default ReduxHOC((PlayerList))