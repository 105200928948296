/* eslint-disable */
import { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col, Container, Row, DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, } from 'reactstrap';
import { Banner, Booster, GameweekNativeSlider, PitchView, ShareButton } from 'Components';
import { API, Constant, Http, Utils, _, NavLink, Trans, Images } from 'Helpers';
import { PlayerCard } from 'Modals';
import { ReduxHOC } from 'HOC';
import Drawer from 'react-motion-drawer';
import FixtureMT from 'Components/FixturesComp/FixtureMT';
import { Img } from 'Components';
import { Tabs, Tab } from 'react-tabs-scrollable'

class LeagueDetails extends Component {
  constructor(props) {
    super(props);
    this.nodes = {}
    const { t } = props
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      CurrentLineup: props.root.CurrentLineup,
      is_single_player: Utils.isSinglePlayer(),
      sports_config: {},
      RosterMasterData: {},


      is_owner: null,
      SelectedWeek: '',
      isLoaderShow: true,
      league_id: '',
      sports_id: '',
      contest_id: '',
      module_id: '',
      LeaderboardList: [],
      SelectedLeaderboard: {},
      LeagueType: [
        { value: 'all_league', label: t('All Leagues') },
        { value: 'global_league', label: t('Global Leagues') },
        { value: 'other_league', label: t('Private Leagues') },
        { value: 'mini_league', label: t('Mini Leagues') }
      ],

      SelectedLeaderboardItem: {},
      UserLeaderboardDetails: [],
      leaderboard: [],
      own_leaderboard: [],
      hasMore: false,
      Offset: Constant.CURRENT_OFFSET,

      team_name: '',
      LineupList: [],
      LineupLoader: true,

      team_info: {},
      next_week: '',
      joined_week: "",
      active_week: "",
      last_week: "",
      AllWeek: [],

      SelectedPlayer: {},
      isPlayerCardShow: false,
      ALL_POSITION_CLONE: [],
      isDrawerOpen: false,
      gameweeklastupdate: new Date().valueOf(),
      booster_list: [],
      bench_booster: {},
      captain_booster: {},
      free_hit_booster: {},
      wildcard: {},
      showSlider: false,
      selectedWk: Utils.getActive(),
      leaderboardG: [],
      leaderboardG_Paid: [],
      index_data: this.props.location.state ?  this.props.location.state.index : '',
      showSliderMonth:false,
      monthly_leagues:[],
      selectedMonth:'',
      max_winning_rank:'',
      updateBooster: new Date().valueOf(),
      lineup_master_id: null,
      activeTabMonth:null,
      isfromMonth:false,
      AllWeekWeekly:[]
    }
  }

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node
    }
  }
  /**
   * #1
   * @description method to get lineup master data
   */
  getLineupMasterData = () => {
    const { SelectedLeague } = this.state;
    const { league_id, sports_id } = SelectedLeague;

    Http.post(API.GET_LINEUP_MASTER_DATA, {
      league_id, sports_id
    }).then(response => {
      const { data } = response;
      this.setState({
        booster_list: data.booster_list,
        ALL_POSITION_CLONE: data.all_position,
        lineup_master_id: data.lineup_master_id,
      });
    }).catch(error => {
      this.props.history.push(Constant.DASHBOARD_PATH)
      console.error(error)
    });
  }

  init = async () => {
    const { SelectedLeague } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "user_id": this.props.user_id ? this.props.user_id : '' // For Point screen
    }
    let [PrivateLeaderboardList, MiniLeagueLeaderboardList] = await Promise.all([
      // Http.post(API.GET_USER_LEADERBOARD_LIST_GLOBAL, param),
      Http.post(API.GET_USER_LEADERBOARD_LIST_PRIVATE, param),
      Http.post(API.GET_USER_LEADERBOARD_LIST_MINILEAGUE, param),
    ]);
    return {
      // current_week: GlobalLeaderboardList.data.current_week,
      // ...GlobalLeaderboardList.data,
      ...PrivateLeaderboardList.data,
      ...MiniLeagueLeaderboardList.data,
    }
  }

  getCatName = (str) => {
    const { LeagueType } = this.state;
    let _arr = _.filter(LeagueType, o => o.value == str)
    return !_.isEmpty(_arr) ? _arr[0].label : ''
    //Leagues
  }

  getLeaderboardMasterData = () => {
    this.setState({ isLoaderShow: true });
    const { league_id, sports_id, contest_id } = this.state
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "contest_id": contest_id,
    }
    Http.post(API.CONTEST_LEADERBOARD_MASTER_DATA, param).then((res) => {
      this.setState({ isLoaderShow: false });
      const { data } = res;
      this.setState({
        //  is_owner: data.master_data.is_owner,
        //   contest_access_type: data.master_data.contest_access_type
        // SelectedScoringMonths: data.master_data.scoring_week[0]
      }, () => {
        this.getUserLeaderboardDetails()
      })
    }).catch(err => {
      console.error(err);
    })
  }

  getUserLeaderboardDetails = (week) => {
    this.setState({ isLoaderShow: true });
    const {
      league_id,
      sports_id,
      contest_id,
      module_id,
      SelectedLeaderboard,
      SelectedScoringMonths,
      leaderboard,
      Offset,
      isfromMonth
    } = this.state

    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "contest_id": contest_id,
      "module_id": module_id,

      // "month": 'all',
      // "month": SelectedScoringMonths.value,
      "module": SelectedLeaderboard.module,
      "limit": Constant.ITEMS_PERPAGE,
      "offset": Offset,
      "week": week ? week : ''

    }
    Http.post(API.GET_USER_LEADERBOARD_DETAILS, param).then((res) => {
      this.setState({ isLoaderShow: false });
      try {
        const { data } = res;
        this.setState({
          UserLeaderboardDetails: data,
          leaderboard: Offset == Constant.CURRENT_OFFSET ? data.leaderboard : [...leaderboard, ...data.leaderboard],
          own_leaderboard: data.own_leaderboard,
          SelectedLeaderboardItem: data.own_leaderboard || data.leaderboard[0],
          hasMore: data.leaderboard.length > 0 ? true : false,
          active_week: data.game_week,
          monthly_leagues:data.monthly_leagues,
          max_winning_rank:data.max_winning_rank,
          updateBooster: new Date().valueOf(),
          //activeTabMonth:data.monthly_leagues.length - 1

        }, () => {
          const {
            contest_id,
            CurrentLineup,
            showSliderMonth
          } = this.state
          if(showSliderMonth){
            if(!isfromMonth){
              this.setState({
                activeTabMonth:data.monthly_leagues.length - 1,
                selectedMonth:data.monthly_leagues[data.monthly_leagues.length - 1]
              })
            }
            
           // this.clickHandler('e',data.monthly_leagues.length - 1)
          }
          
          this.getUserTeamInfo({
            contest_id: contest_id,
            lineup_master_id: CurrentLineup.team_info.lineup_master_id,
            active_week: data.game_week,
          })
        })
      } catch (error) {
        this.props.history.push('/leagues')
      }
    }).catch(err => {
      console.error(err);
    })
  }

  getUserLeaderboardDetailsFetch = () => {
    this.setState({ isLoaderShow: true });
    const {
      league_id,
      sports_id,
      contest_id,
      module_id,
      SelectedLeaderboard,
      SelectedScoringMonths,
      leaderboard,
      Offset,
    } = this.state

    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "contest_id": contest_id,
      "module_id": module_id,

      // "month": 'all',
      // "month": SelectedScoringMonths.value,
      "module": SelectedLeaderboard.module,
      "limit": Constant.ITEMS_PERPAGE,
      "offset": Offset

    }
    Http.post(API.GET_USER_LEADERBOARD_DETAILS, param).then((res) => {
      this.setState({ isLoaderShow: false });
      try {
        const { data } = res;
        this.setState({
          UserLeaderboardDetails: data,
          leaderboard: Offset == Constant.CURRENT_OFFSET ? data.leaderboard : [...leaderboard, ...data.leaderboard],
          own_leaderboard: data.own_leaderboard,
          SelectedLeaderboardItem: data.own_leaderboard || data.leaderboard[0],
          hasMore: data.leaderboard.length > 0 ? true : false,
          active_week: data.game_week,
        })
      } catch (error) {
        this.props.history.push('/leagues')
      }
    }).catch(err => {
      console.error(err);
    })
  }

  fetchMoreData = () => {
    let offset = this.state.Offset + Constant.ITEMS_PERPAGE;
    this.setState({ Offset: offset }, () => {
      this.getUserLeaderboardDetailsFetch()
    });
  }

  getUserLineupDetail = (options) => {
    const {
      contest_id,
      active_week,
      lineup_master_id
    } = options;
    const {
      league_id,
      sports_id,
    } = this.state
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "lineup_master_id": lineup_master_id,
      "contest_id": contest_id,
      "week": active_week // Live week (Prev Scoring week)
    }
    Http.post(API.GET_USER_LINEUP_DETAIL, param).then((res) => {
      const { data } = res;
      this.setState({
        team_name: data.team_name
      }, () => {
        if (data.lineup.length > 0) {
          this.setState({
            LineupList: data.lineup,
            isLoaderShow: false,
            LineupLoader: false
          });
        } else {
          this.setState({
            LineupList: [],
            LineupLoader: false
          });
        }
      })
    })
  }

  getUserTeamInfo = (options) => {
    const { contest_id, active_week, lineup_master_id } = options;
    const {
      league_id,
      sports_id,
    } = this.state
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "lineup_master_id": lineup_master_id,
      "contest_id": contest_id,
      "week": active_week // Live week (Prev Scoring week)
    }

    Http.post(API.GET_USER_TEAM_INFO, param).then((res) => {
      const { team_info } = res.data
      this.setState({
        team_info: team_info,
        next_week: team_info.next_week,
        // next_week: team_info.scoring_weeks.length > 0 ? _.last(team_info.scoring_weeks).week : '',
        joined_week: team_info.joined_week,
        active_week: team_info.active_week,
        last_week: team_info.last_week,
        bench_booster: team_info.bench_booster_status,
        captain_booster: team_info.captain_booster_status,
        free_hit_booster: team_info.free_hit_booster_status,
        wildcard: team_info.wildcard_status,
        updateBooster: new Date().valueOf(),
      }, () => {
        //console.log(team_info.scoring_weeks,"team_info.scoring_weeksteam_info.scoring_weeks");
        if (team_info.scoring_weeks.length > 0) {
          var tmpAllWeek = [];
          var tmpAllWeekWeekly = [];
          var tmpSelectedWeek = ''


          //added new filter for details where we check the joined week for user and returns the list of weeks to be displayed
          const newData = _.filter(team_info.scoring_weeks, o => Number(o.week) >= Number(team_info.joined_week))
          const newDataWeekly = _.filter(team_info.scoring_weeks, o => Number(o.week) <= Number(this.state.current_week))

          _.map(newData, (week) => {
            tmpAllWeek.push({ ...week, id: week.week });
            // if (this.state.SelectedWeek == '' && team_info.next_week == week.week) {
            //   tmpSelectedWeek = week.week;
            // } else if (week.week == this.state.SelectedWeek) {
            //   tmpSelectedWeek = week.week;
            // }
          })
          _.map(newDataWeekly, (week) => {
            tmpAllWeekWeekly.push({ ...week, id: week.week });
            // if (this.state.SelectedWeek == '' && team_info.next_week == week.week) {
            //   tmpSelectedWeek = week.week;
            // } else if (week.week == this.state.SelectedWeek) {
            //   tmpSelectedWeek = week.week;
            // }
          })

         // console.log(tmpAllWeek,"tmpAllWeektmpAllWeektmpAllWeek");

          const isGameweekExist = _.filter(team_info.scoring_weeks, o => o.week == active_week).length > 0
          this.setState({
            AllWeek: tmpAllWeek,
            AllWeekWeekly:tmpAllWeekWeekly,
            SelectedWeek: isGameweekExist ? active_week : Number(active_week) + 1,
            gameweeklastupdate: new Date().valueOf()
          }, () => {
            this.getUserLineupDetail({
              contest_id: contest_id,
              lineup_master_id: lineup_master_id,
              active_week: this.state.SelectedWeek
            })
          })
        }
      })
    })
  }


  getSelectedLeaderboard = () => {
    const { LeaderboardList, module_id, leaderboardG_Paid, leaderboardG } = this.state
    const { dta, paid_league } = this.props.location.state
    // const SelLdb = dta ? leaderboardG : LeaderboardList
    let _selectedleaderboard = {}
    if (!dta) {
      if (_.isEmpty(LeaderboardList)) return ''
      _.map(LeaderboardList, leaderboard => {
        if (_.isEmpty(leaderboard)) return null
        _.map(leaderboard, (item, key) => {
          if (_.isEmpty(_selectedleaderboard)) {
            if (item.module_id == module_id) {
              _selectedleaderboard = {
                ...item,
                module: key
              }
              // console.log(key, 'wefoih')
              this.setState({
                SelectedLeaderboard: _selectedleaderboard,
                updateBooster: new Date().valueOf(),
              }, () => {
                this.getUserLeaderboardDetails()
              })
            }
          }
          return item;
        })
        return leaderboard
      })
    }
    else {
      if (paid_league) {
        if (_.isEmpty(leaderboardG_Paid)) return ''
        _.map(leaderboardG_Paid, (leaderboard, key) => {
          if (_.isEmpty(leaderboard)) return null
          if (_.isEmpty(_selectedleaderboard)) {
            if (leaderboard.module_id == module_id) {
              _selectedleaderboard = {
                ...leaderboard,
                module: key
              }
              this.setState({
                SelectedLeaderboard: _selectedleaderboard
              }, () => {
                this.getUserLeaderboardDetails()
              })
            }
          }
          // return item;
          return leaderboard
        })
      }
      else {
        const { index_data } = this.state
        if (_.isEmpty(leaderboardG)) return ''
        _.map(leaderboardG, (leaderboard, key) => {
           
          // return
          if (_.isEmpty(leaderboard)) return null
          if (_.isEmpty(_selectedleaderboard)) {
            if (index_data == key) {
              if (key === 'gameweek_standing') {
                setTimeout(() => {
                  this.setState({ showSlider: true, showSliderMonth:false },()=>{
                  })  
                  
                }, 1000);
                
              }
              if (key === 'monthly_standing') {
                this.setState({ showSliderMonth: true, showSlider:false })
              }
              _selectedleaderboard = {
                ...leaderboard,
                module: key
              }
              // console.log(_selectedleaderboard, '_selectedleaderboard_selectedleaderboard')
              this.setState({
                SelectedLeaderboard: _selectedleaderboard
              }, () => {
                this.getUserLeaderboardDetails()
              })
            }
          }
          return leaderboard
        })
      }
    }
  }



  componentDidMount() {
// alert(33)
    const { SelectedLeague } = this.state
    this.setState({
      updateBooster: new Date().valueOf(),
    },()=>{
      
    })
    if (this.props.location.state) {
      const { alldata, data, pr_leaguedata } = this.props.location.state
      this.setState({
        contest_access_type: data.contest_access_type,
        LeaderboardList: pr_leaguedata,
        current_week: alldata.current_week,
        leaderboardG: alldata['global_league'],
        leaderboardG_Paid: alldata['global_paid_league'],
        updateBooster: new Date().valueOf(),
      }, () => {
        //  console.log(alldata.current_week,"alldata.current_week");
        this.getSelectedLeaderboard()
        this.getLineupMasterData()

      })
    }
    const urlParams = new URLSearchParams(this.props.location.search)
    const type = urlParams.get('type')
    if (type) {
      this.setState({
        league_id: urlParams.get('league_id'),
        sports_id: urlParams.get('sports_id'),
        contest_id: urlParams.get('contest_id'),
        module_id: urlParams.get('module_id')
      }, () => {
        // this.init().then(res => {
        //   this.setState({
        //     LeaderboardList: res,
        //     current_week: res.current_week,
        //   }, () => {
        //     this.getSelectedLeaderboard()
        //     this.getLineupMasterData()
        //   })
        // })
      })
    } else {
      const { SelectedLeague } = this.state
      this.setState({
        league_id: SelectedLeague.league_id,
        sports_id: SelectedLeague.sports_id,
        contest_id: 1,
        module_id: 1
      }, () => {
        // this.init()
      })
    }

    // 
    const { sports_config } = SelectedLeague;
    this.setState({
      sports_config
    })


    //
    Utils.setLineupMasterId().then(res => {
      this.props.actions.setCurrentLineup(Utils.getCurrentLineup())
      this.setState({
        CurrentLineup: Utils.getCurrentLineup()
      })
    })
  }


  SelectWeekHandle = (item) => {
    if (this.state.current_week >= this.state.selectedWk) {
      this.setState({
        SelectedWeek: item.week,
        LineupLoader: true,
        LineupsList: [],
        updateBooster: new Date().valueOf(),
      }, () => {
        const { SelectedLeaderboard, SelectedLeaderboardItem } = this.state
        this.getUserTeamInfo({
          contest_id: SelectedLeaderboard.contest_id,
          lineup_master_id: SelectedLeaderboardItem.lineup_master_id,
          active_week: item.week
        })
      })
    }
    else { }
  }

  clickHandler = (e,index) => {
    const {monthly_leagues} = this.state
    this.setState({
      activeTabMonth: index,
      updateBooster: new Date().valueOf(),
    },()=>{
      this.MonthclickHandler(monthly_leagues[index])
    })
   
  }

  MonthclickHandler = (item)=>{
    this.setState({
      selectedMonth:item,
      module_id:item.ml_id,
      isfromMonth:true
    },()=>{
      this.getUserLeaderboardDetails()
    })
  }
  leaderboardRender = (item) => {
    this.setState({
      selectedWk: item.week,
      Offset: Constant.CURRENT_OFFSET
    }, () => {
      this.getUserLeaderboardDetails(item.week)
    })

  }

  SelectedLeaderboardItemInit = (item) => {
    //console.log(item,"itemitemitemitem");
    //alert('radha')

    this.setState({
      //SelectedWeek: '',
      LineupLoader: true,
      SelectedLeaderboardItem: item,
      LineupList: []
    }, () => {
      const { SelectedLeaderboard, active_week } = this.state
      if (window.innerWidth < 991) {
        this.toggleDrawer()
      }
      this.getUserTeamInfo({
        contest_id: SelectedLeaderboard.contest_id,
        lineup_master_id: item.lineup_master_id,
        active_week: active_week
      })
    })
  }

  getLineupRosterLimit = (week) => {
    const { sports_config } = this.state;
    let limit = '-'
    if (typeof sports_config.lineup_roster_limit === 'object' && sports_config.lineup_roster_limit !== null) {
      _.forEach(sports_config.lineup_roster_limit, (object, i) => {
        if (week == i) {
          limit = object
        }
      })
    } else {
      limit = sports_config.lineup_roster_limit
    }
    return limit
  }

  PlayerCardInit = (SelectedPlayer) => {
    const { isDrawerOpen } = this.state
    this.setState({
      SelectedPlayer
    }, () => {
      if (isDrawerOpen) {
        this.toggleDrawer()
      }

      this.PlayerCardToggle()
    })
  }

  PlayerCardToggle = () => {
    this.setState({
      isPlayerCardShow: !this.state.isPlayerCardShow
    })
  }


  leagueSelectHandler = (item, key) => {

    // console.log(item, 'my-infomy-infomy-infomy-info')
    const { current_week } = this.state;
    if ((Number(item.contest_info.joined_week) > current_week) && (key == 'gameweek_standing' || key == 'country_standing' || key == 'fanclub_standing')) {
      Utils.notify(Constant.LEAGUE_OOPS_MSZ, "success", 2500);
    } else {
      this.setState({
        Offset: Constant.CURRENT_OFFSET,
        contest_id: item.contest_id,
        module_id: item.module_id,
        leaderboard: [],
        isLoaderShow: true,
        SelectedLeaderboard: {
          ...item,
          module: key
        }
      }, () => {
        const { SelectedLeague, contest_id, module_id } = this.state;

        const { league_id, sports_id } = SelectedLeague;
        const prv = _.isString(key) ? 0 : 1
        this.props.history.push({
          pathname: '/leagues/details',
          search: `?league_id=${league_id}&sports_id=${sports_id}&contest_id=${contest_id}&module_id=${module_id}&type=3&prv=${prv}`
        })
        if (key == 'gameweek_standing') {
          setTimeout(() => {
            this.setState({ showSlider: true, showSliderMonth:false },()=>{
              //alert('SitaRam')
            })  
            
          }, 1000);
          
        }
        else if (key === 'monthly_standing') {
          this.setState({ showSliderMonth: true, showSlider:false })
        }
        else {
          this.setState({ showSlider: false, showSliderMonth: false })
        }
        this.getUserLeaderboardDetails()
      })
    }
  }
  leagueSelectHandlers = (item, key) => {
    // const { current_week } = this.state;
    // console.log(Number(Utils.getJoined()) > current_week, 'sadjoi')
    // return

    // console.log(item, 'my-infomy-infomy-infomy-info')
    const { current_week } = this.state;
    if ((Number(Utils.getJoined()) > current_week) && (key == 'gameweek_standing' || key == 'country_standing' || key == 'fanclub_standing')) {
      Utils.notify(Constant.LEAGUE_OOPS_MSZ, "warning", 2500);
      // this.props.history.push('/leagues')
    } else {
      this.setState({
        isfromMonth:false,
        Offset: Constant.CURRENT_OFFSET,
        contest_id: item.contest_id,
        module_id: item.module_id,
        leaderboard: [],
        isLoaderShow: true,
        SelectedLeaderboard: {
          ...item,
          module: key
        }
      }, () => {
        const { SelectedLeague, contest_id, module_id } = this.state;

        const { league_id, sports_id } = SelectedLeague;
        const prv = _.isString(key) ? 0 : 1
        this.props.history.push({
          pathname: '/leagues/details',
          search: `?league_id=${league_id}&sports_id=${sports_id}&contest_id=${contest_id}&module_id=${module_id}&type=3&prv=${prv}`
        })
        if (key == 'gameweek_standing') {
          setTimeout(() => {
            this.setState({ showSlider: true, showSliderMonth:false },()=>{
              
            })  
            
          }, 1000);
          
        } else if (key === 'monthly_standing') {
          this.setState({ showSliderMonth: true, showSlider:false })
        }
        else {
          this.setState({ showSlider: false, showSliderMonth:false  })
        }
        this.getUserLeaderboardDetails()
      })
    }
  }

  LeaveLeague = () => {
    const { SelectedLeague, SelectedLeaderboard } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "contest_id": SelectedLeaderboard.contest_id
    }
    Http.post(API.LEAVE_LEAGUE, param).then((res) => {
      Utils.notify(res.message)
      this.props.history.push({ pathname: '/leagues' })
    })
  }

  toggleDrawer = (bool = true) => {
    this.setState({ isDrawerOpen: bool == false ? bool : !this.state.isDrawerOpen });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  GotoviewPrize = () => {
    const { contest_id } = this.state
    this.props.history.push('/prizes/' + contest_id)
  }

  GotoviewGlobalPrize = () => {
    const { contest_id } = this.state
    this.props.history.push('/prizes/' + contest_id)
  }

  
  
  ApplyBooster =()=>{
    return ''
  }
  RemoveBooster =()=>{
    return ''
  }

  render() {
    // console.log(this.state.LineupList, 'PitchViewPropsPitchViewProps')
    const { t, i18n } = this.props
    const {
      SelectedLeague,

      LeaderboardList,
      SelectedLeaderboard,
      leaderboard,
      leaderboardG,
      leaderboardG_Paid,
      own_leaderboard,
      hasMore,

      AllWeek,
      SelectedWeek,
      SelectedLeaderboardItem,
      team_info,
      LineupList,

      joined_week,
      active_week,
      next_week,
      last_week,
      RosterMasterData,
      LineupLoader,
      CurrentLineup,
      isPlayerCardShow,
      SelectedPlayer,
      ALL_POSITION_CLONE, booster_list,

      is_owner,
      isDrawerOpen,
      gameweeklastupdate,

      contest_access_type,
      bench_booster,
      captain_booster,
      free_hit_booster,
      wildcard,
      showSlider,
      selectedWk,
      showSliderMonth,
      monthly_leagues,
      max_winning_rank,
      updateBooster,
      lineup_master_id,
      AllWeekWeekly
    } = this.state
    

    const PitchViewProps = {
      team_info,
      is_leagueD: true,
      LineupList,
      ALL_POSITION: {},
      ALL_POSITION_CLONE,

      joined_week,
      active_week,
      next_week,
      last_week,

      SelectedLeague,
      lineup_master_id: !_.isEmpty(SelectedLeaderboardItem) ? SelectedLeaderboardItem.lineup_master_id : '',

      RosterMasterData,
      LineupLoader,
      IS_POINT: true,
      SelectedWeek,
      CurrentLineup,
      getLineupRosterLimit: this.getLineupRosterLimit,
      PlayerCardInit: this.PlayerCardInit,
      hideBtn: true,
      message: SelectedWeek == CurrentLineup.week_info.active_week ? 
      Utils.bindMessage(t(`You can see other users' team once the ${CurrentLineup.week_info.active_week} starts.`), `${CurrentLineup.week_info.active_week}`, SelectedLeague.week_label[SelectedWeek])
      : 
      Utils.bindMessage(t(`You can see other users' team once the ${CurrentLineup.week_info.active_week} starts.`), `${CurrentLineup.week_info.active_week}`, SelectedLeague.week_label[SelectedWeek])
    }
    //Utils.bindMessage(t(`Stay tuned to check his team once the week ${CurrentLineup.week_info.active_week} starts.`), '{{week}}', SelectedLeague.week_label[SelectedWeek]) 

    const PlayerCardProps = {
      isOpen: isPlayerCardShow,
      toggle: this.PlayerCardToggle,
      selected_league: SelectedLeague,
      Player: SelectedPlayer,
    }

    const DrawerProps = {
      width: innerWidth < 375 ? 320 : 375,
      noTouchOpen: true,
      noTouchClose: true,
      handleWidth: 5,
      overlayColor: "rgba(0,0,0,0.78)",
      drawerStyle: {
        "boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
      }
    }

    // const GameWeekFiledsProps = {
    //   AllWeek,
    //   SelectedWeek,
    //   gameweeklastupdate,
    //   SelectWeekHandle: this.SelectWeekHandle,
    //   SelectedLeaderboardItem,
    //   LineupLoader,
    //   LineupList,
    //   team_info,
    //   PitchViewProps,
    //   setNodeRef: this.setNodeRef,
    //   _this: this
    // }

    const BoosterProps = {
      CurrentLineup,
      booster_list,
      team_info,
      next_week,
      last_week,
      joined_week,
      active_week,
      SelectedWeek,
      updateBooster,
      BoosterPosting:false,
      LineupList,
      ApplyBooster: this.ApplyBooster,
      RemoveBooster: this.RemoveBooster,
      IS_MYTEAM:false,
      lineup_master_id,
      IS_FROM_LEADER:true
    }
    const GameweekHeader = () => {
      return (
        <div className="team-field-header">

          <i className="icon-cancel drawer-close" onClick={() => this.toggleDrawer()} />
        </div>
      )
    }
    // console.log(parseInt(team_info.joined_week) + team_info.active_week, 'checking for')
    const GameWeekFileds = () => {
      return (
        <>
          <div className="gameweek-wrap">
            <GameweekNativeSlider {...{
              scoring_weeks: AllWeek,
              SelectedWeek,
              gameweeklastupdate:this.state.gameweeklastupdate,
              SelectWeekHandle: this.SelectWeekHandle,
              is_fixture: true,
              active_week:SelectedWeek
            }} />
          </div>

          <div className="scoring-board class-color">
            <div className="login-profile-toggle w-fix-league">
              {/* <div className="avtar">
                <img src={SelectedLeaderboardItem && SelectedLeaderboardItem.user_image} alt="" />
              </div> */}
              <div className="details">
                <div className="user-name-m">
                  {SelectedLeaderboardItem && SelectedLeaderboardItem.first_name}{' '}{SelectedLeaderboardItem && SelectedLeaderboardItem.last_name}
                </div>
                <div className="user-rank">{SelectedLeaderboardItem && (SelectedLeaderboardItem.team_name || SelectedLeaderboardItem.user_name)}</div>
              </div>
            </div>
            <div className="score-wrap">
              <div className="cell bg-box">
                {
                  LineupLoader || _.isEmpty(LineupList) ?
                    <div className="value">0</div>
                    :
                    <div className="value">
                      {(parseInt(team_info.joined_week) < team_info.active_week) &&
                        <span>
                          {team_info.last_rank < team_info.rank && team_info.last_rank !== '0' ?
                            < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                            :
                            < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                          }
                        </span>
                      }
                      {team_info.week_rank || '--'}</div>
                }
                <div className="lbl"><Trans>Overall Rank</Trans></div>
              </div>
              <div className="cell bg-box">
                {
                  LineupLoader || _.isEmpty(LineupList) ?
                    <div className="value">
                      0
                    </div>
                    :
                    <div className="value">
                      {team_info.week_score || '--'}
                    </div>
                }
                <div className="lbl"><Trans>Points</Trans></div>
              </div>
              <div className="cell bg-box">
                {
                  LineupLoader || _.isEmpty(LineupList) ?
                    <div className="value">0</div>
                    :
                    <div className="value">{team_info.total_transfer || '--'}</div>
                }
                <div className="lbl"><Trans>Transfer</Trans></div>
              </div>
            </div>
          </div>
          {/* <div className='booster-block'>
            <div id='booster-block' className={wildcard.is_applied == 0 ? '' : 'grayscale-bx'}>
              <img src={Images.BOOSTER_0} />
              <div id='bstr-details'>
                <span>{wildcard.is_applied == 0 ? 'Available Booster' : 'Applied'}</span>
                <span className='name-bstr'>Wild Card</span>
              </div>
            </div>
            <div id='booster-block' className={captain_booster.is_applied == 0 ? '' : 'grayscale-bx'}>
              <img src={Images.BOOSTER_1} />
              <div id='bstr-details'>
                <span>{captain_booster.is_applied == 0 ? 'Available Booster' : 'Applied'}</span>
                <span className='name-bstr'>Triple Captain</span>
              </div>
            </div>
            <div id='booster-block' className={bench_booster.is_applied == 0 ? '' : 'grayscale-bx'}>
              <img src={Images.BOOSTER_2} />
              <div id='bstr-details'>
                <span>{bench_booster.is_applied == 0 ? 'Available Booster' : 'Applied'}</span>
                <span className='name-bstr'>Bench Boost</span>
              </div>
            </div>
            <div id='booster-block' className={free_hit_booster.is_applied == 0 ? '' : 'grayscale-bx'}>
              <img src={Images.BOOSTER_3} />
              <div id='bstr-details'>
                <span>{free_hit_booster.is_applied == 0 ? 'Available Booster' : 'Applied'}</span>
                <span className='name-bstr'>Free Hit</span>
              </div>
            </div>
          </div > */}
          {/* <Banner type='11'  paddingBottom/> */}
          <div>
            <Booster {...BoosterProps} />
          </div>
          
          <PitchView getInstance={this.setNodeRef.bind(this, 'PitchView')} {...PitchViewProps} />
        </>
      )
    }
    const MonthSlider = ()=> {
      const {monthly_leagues, activeTabMonth, selectedMonth} =this.state
    return(
      <Tabs
        isRTL={i18n.language == "ar"}
        activeTab={activeTabMonth}
        onTabClick={this.clickHandler}
        tabsClassName='gameweek-inner'
        tabsContainerClassName='gameweek-weekslider native-scroll'
        rightBtnIcon={<i className="icon-next" />}
        leftBtnIcon={<i className="icon-previous" />}
      >
        {
           _.map(monthly_leagues, (item, idx) => {
            return(
              <Tab key={idx} 
             
              {...{ className: `week-item${item.ml_id == selectedMonth.ml_id  ? ' active' : ''}` }}
              >
                <div className='month-slider-row'>
                    <div className='month-slider-head'>
                      {item.league_name}
                    </div>
                    <div className='month-slider-sub'>
                      GW {item.from_week} - GW {item.to_week}
                    </div>
                </div>
            </Tab>
            )
            
           })
        }
      </Tabs>
    )
   
    
  }
    // console.log(SelectedLeaderboard, 'SelectedLeaderboard')
    return (
      <>
        <section className='background-pitch'>
          <Container className="leaderboard-component img-bg">
            <Row>
              <Col>
                <div>
                  <div className="leaderboard-header">
                    <div className='flex-dc'>
                      <span id='arrow-hide'>{this.props.location.is_DashLeague ? <NavLink to='/' className={'icon-previous'} /> : <NavLink to='/leagues' className={'icon-previous'} />}</span>
                      <div className="league-dropdown">
                        <UncontrolledDropdown>
                          <DropdownToggle tag='div' className='league-dropdown-toggle'>
                            <span>
                              {
                                !_.isEmpty(SelectedLeaderboard) ?
                                  (SelectedLeaderboard.category_name || SelectedLeaderboard.contest_info.contest_name)
                                  : ''
                              }
                            </span>
                            <i className="icon-down" />
                          </DropdownToggle>
                          {<DropdownMenu right={i18n.language == "ar"} className='league-dropdown-menu'>
                            {/* {this.getCatName(idx) !== '' && leaderboardG_Paid */}
                            {leaderboardG &&
                              <>
                                <DropdownItem header className='top-label'>
                                  <div><Trans>Global Paid Leagues</Trans></div>
                                </DropdownItem>

                                <DropdownItem header>
                                  <div><Trans>League</Trans></div>
                                  <div className='rank-width'><Trans>Rank</Trans></div>
                                </DropdownItem>
                              </>
                            }
                            {
                              _.map(leaderboardG, (Leaderboard, idx) => {
                                return (
                                  !_.isEmpty(Leaderboard) ?
                                    <Fragment key={idx}>
                                      <DropdownItem key={idx}
                                        active={SelectedLeaderboard.category_name == Leaderboard.category_name}
                                        onClick={() => this.leagueSelectHandlers(Leaderboard, idx)}>
                                        <div id='league-name'>{!_.isUndefined(Leaderboard) ? (Leaderboard.category_name || Leaderboard.contest_name) : '--'}</div>
                                        <div className='rank-width' id='ranks'>
                                          <span id='arrow-u-d'>
                                            {Leaderboard.last_rank < Leaderboard.rank && Leaderboard.last_rank !== '0' ?
                                              < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                              :
                                              < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                            }
                                          </span>
                                          {Leaderboard.rank || '--'}</div>
                                      </DropdownItem>



                                    </Fragment>
                                    :
                                    ''

                                )
                              })
                            }
                            {_.map(leaderboardG_Paid, (Leaderboard, idx) => {
                              return (
                                !_.isEmpty(Leaderboard) ?
                                  <Fragment key={idx}>
                                    <DropdownItem key={idx}
                                      active={SelectedLeaderboard.category_name == Leaderboard.category_name}
                                      onClick={() => this.leagueSelectHandlers(Leaderboard, idx)}>
                                      <div id='league-name'>{!_.isUndefined(Leaderboard) ? (Leaderboard.category_name || Leaderboard.contest_name) : '--'}</div>
                                      <div className='rank-width' id='ranks'>
                                        <span id='arrow-u-d'>
                                          {Leaderboard.last_rank < Leaderboard.rank && Leaderboard.last_rank !== '0' ?
                                            < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                            :
                                            < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                          }
                                        </span>
                                        {Leaderboard.rank || '--'}</div>
                                    </DropdownItem>
                                  </Fragment>
                                  :
                                  ''

                              )
                            })
                            }
                            {
                              _.map(LeaderboardList, (Leaderboard, idx) => {
                                return (
                                  !_.isEmpty(Leaderboard) ?
                                    <Fragment key={idx}>
                                      {this.getCatName(idx) !== '' &&
                                        <DropdownItem header className='top-label'>
                                          <div><Trans>{this.getCatName(idx)}</Trans></div>
                                        </DropdownItem>}
                                      {idx === 'global_league' &&
                                        <DropdownItem header>
                                          <div><Trans>League</Trans></div>
                                          <div className='rank-width'><Trans>Rank</Trans></div>
                                        </DropdownItem>}

                                      {
                                        !_.isEmpty(Leaderboard) &&
                                        <>
                                          {
                                            _.map(Leaderboard, (item, index) => {
                                              if (_.isUndefined(item.contest_info)) return ''
                                              return (
                                                <DropdownItem key={index}
                                                  active={SelectedLeaderboard.category_name == item.category_name}
                                                  onClick={() => this.leagueSelectHandler(item, index)}>
                                                  <div id='league-name'>{!_.isUndefined(item.contest_info) ? (item.category_name || item.contest_info.contest_name) : '--'}</div>
                                                  <div className='rank-width' id='ranks'>
                                                    <span id='arrow-u-d'>
                                                      {item.last_rank < item.rank && item.last_rank !== '0' ?
                                                        < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                        :
                                                        < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                      }
                                                    </span>
                                                    {item.rank || '--'}</div>
                                                </DropdownItem>
                                              )
                                            })
                                          }
                                        </>
                                      }
                                    </Fragment>
                                    :
                                    ''

                                )
                              })
                            }
                          </DropdownMenu>
                          }
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    {
                      contest_access_type == 4 &&
                      <a className='link-view-prize' onClick={() => this.GotoviewPrize()}><img src={Images.TROPHY_PRIZES} alt='' /> View Prizes</a>
                    }
                    {
                      contest_access_type == 2 &&
                      <a className='link-view-prize' onClick={() => this.GotoviewGlobalPrize()}><img src={Images.TROPHY_PRIZES} alt='' /> View Prizes</a>
                    }
                  </div>


                  {
                    (!_.isEmpty(SelectedLeaderboard) && (_.isUndefined(SelectedLeaderboard.category_name) && is_owner == 1)) &&
                    <div className='share-icn'>
                      <ShareButton item={SelectedLeaderboard} />
                    </div>
                    // <span className='leave-league-btn' onClick={() => this.LeaveLeague()}><Trans>Leave League</Trans></span>
                  }

                </div>

              </Col>
            </Row>
            <Row className='row-leagues md-design pd-fix-rwlg'>
              <Col lg={4}>
                <div className="leaderboard-user-wrap">
                  {showSlider &&
                    <div className="gameweek-leaderbd">
                      <GameweekNativeSlider {...{
                        scoring_weeks: AllWeekWeekly,
                        SelectedWeek:selectedWk,
                        gameweeklastupdate,
                        SelectWeekHandle: this.leaderboardRender,
                        is_fixture: true,
                        active_week:selectedWk
                      }} />
                    </div>
                  }
                  {showSliderMonth &&
                    <div className="gameweek-leaderbd">
                      <MonthSlider/>
                    </div>
                  }
                  <div className="sticky-items">
                    <div className="leaderboard-user-item head">
                      <div className="cell minw">
                        <div className="lbl dl-clr tal-l">
                          <Trans>Rank</Trans>
                        </div>
                      </div>
                      <div className="cell">
                        <div className="lbl dl-clr">
                          <Trans>Name</Trans>
                        </div>
                      </div>
                      <div className="cell minw">
                        <div className="lbl dl-clr">
                          <Trans>Pts</Trans>
                        </div>
                      </div>
                    </div>
                    {
                      !_.isUndefined(own_leaderboard) &&
                      <div
                        {...{
                          className: `leaderboard-user-item owner ${SelectedLeaderboardItem && SelectedLeaderboardItem.lineup_master_id == own_leaderboard.lineup_master_id ? 'selected' : ''}`
                        }}
                        onClick={!_.isEmpty(own_leaderboard) ? () => this.SelectedLeaderboardItemInit(own_leaderboard) : null}>
                        {
                          _.isEmpty(own_leaderboard) ?
                            <Utils.Spinner black />
                            :
                            <>
                              <div className="cell minw tal-l">{own_leaderboard.position}</div>
                              <div className="cell">
                                <div className="login-profile-toggle">
                                  <div className="avtar">
                                    {/* <img src={own_leaderboard.user_image} alt="" /> */}
                                    <Img src={own_leaderboard.user_image} fallbacksrc={Images.DEFAULT_IMG_DARK} alt="" />
                                  </div>
                                  <div className="details">
                                    <div className="user-name-m sel-block">
                                      <span>{own_leaderboard.first_name}{' '}{own_leaderboard.last_name}</span>
                                    </div>
                                    <div className="user-rank">{own_leaderboard.team_name || own_leaderboard.user_name}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="cell minw bld-txt-lb">{own_leaderboard.total_score}</div>
                            </>
                        }
                      </div>
                    }
                  </div>
                  <InfiniteScroll
                    dataLength={leaderboard.length}
                    next={this.fetchMoreData}
                    hasMore={hasMore}
                    scrollableTarget='scrollbar'>
                    <div id="scrollbar" className="scroll-items">
                      {
                        _.map(leaderboard, (item, idx) => {
                          return (
                            <div {...{
                              className: `leaderboard-user-item ${SelectedLeaderboardItem && SelectedLeaderboardItem.lineup_master_id == item.lineup_master_id ? 'selected' : ''}`
                            }} key={idx} onClick={() => this.SelectedLeaderboardItemInit(item)}>
                              <div className="cell minw tal-l">{item.position}</div>
                              <div className="cell">
                                <div className="login-profile-toggle">
                                  <div className="avtar">
                                    {/* <img src={item.user_image} alt="" /> */}
                                    <Img src={item.user_image} fallbacksrc={Images.DEFAULT_IMG_DARK} alt="" />
                                  </div>
                                  <div className="details">
                                    <div className="user-name-m">
                                      {item.first_name}{' '}{item.last_name}
                                    </div>
                                    <div className="user-rank dull-clr-team">{item.team_name || item.user_name}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="cell minw bld-txt-lb pr-t">
                                { ((Number(max_winning_rank) >= Number(item.position) && Number(item.position) !== 0))  && 
                                  <img src={Images.TROPHY_IC} className='trophy-img' alt='' />}
                                {item.total_score}</div>
                            </div>
                          )
                        })
                      }
                    </div>

                  </InfiniteScroll>


                </div>
              </Col>
              <Col lg={8} md={12} className='roster-mobile-hide'>
                {(SelectedWeek && SelectedWeek !== '') && GameWeekFileds()}
              </Col>
            </Row>

            {
              isPlayerCardShow &&
              <PlayerCard {...PlayerCardProps} />
            }
            <div className="roster-drawer-wrap">
              <Drawer open={isDrawerOpen} className='roster-drawer' {...DrawerProps} onChange={this.onChangeDrawer} aria-drawer="true" right={true}>
                <GameweekHeader />
                {(SelectedWeek && SelectedWeek !== '') && GameWeekFileds()}
              </Drawer>
            </div>
          </Container>
        </section>
        <section className='white-color'>
          <Container className='fxt-container'>
            <Row className='gutter-fix'>
              <Col md={8} className='white-fixture-wrap'>
                {(SelectedWeek && SelectedWeek !== '') && <FixtureMT
                  no_ad
                  selected_league={SelectedLeague}
                  lastupdate={gameweeklastupdate}
                  league_week={SelectedWeek}
                />}
              </Col>
              <Col md={4} className='top-stick'>
                <Banner type="11" />
              </Col>
            </Row>
          </Container>
        </section>
      </>

    );
  }
}
export default ReduxHOC(LeagueDetails);
