/* eslint-disable */
import { ShareButton } from 'Components';
import { _, API, Constant, Http, Utils, withRouter, Trans, Images } from 'Helpers';
import { Component, Fragment } from 'react';
import { ReduxHOC } from 'HOC';

class DashLeague extends Component {
    constructor(props) {
        super(props);
        const { t } = props
        this.state = {
            posting: true,
            SelectedLeague: Utils.getMultiLeg(),
            CurrentLineup: props.root.CurrentLineup,
            is_single_player: Utils.isSinglePlayer(),
            PrivateLeaderboardList: [],
            GlobalLeaderboardList: [],
            MonthlyLeaderboardList: [],
            current_week: '',

            LeagueType: [
                // { value: 'all_league', label: t('All Leagues') },
                { value: 'global_league', label: t('Global Leagues') },
                { value: 'global_paid_league', label: t('Global Paid Leagues') },
                // { value: 'other_league', label: t('Private Leagues') },
                // { value: 'mini_league', label: t('Mini Leagues') }
            ]
        }
        this.node = null;
        this.setInstance = this.setInstance.bind(this)
    }
    setInstance(node) {
        this.node = node
        if (typeof this.props.getInstance === 'function') {
            this.props.getInstance({
                GetAllLeague: this.GetAllLeague
            })
        }
    }

    gotoDetails = (data, index, alldata) => {

        // return false;
        const {
            current_week,
            SelectedLeague } = this.state;
        const { league_id, sports_id } = SelectedLeague;
        setTimeout(() => {
            if ((Number(alldata.contest_info.joined_week) > current_week) && (index == 'gameweek_standing' || index == 'country_standing' || index == 'fanclub_standing')) {
                Utils.notify(Constant.LEAGUE_OOPS_MSZ, "warning", 3000);
            } else {
                // console.log(alldata['global_league'], 'adasd')
                // return
                const prv = _.isString(index) ? 0 : 1
                this.props.history.push({
                    pathname: '/leagues/details',
                    search: `?league_id=${league_id}&sports_id=${sports_id}&contest_id=${data.contest_id}&module_id=${data.module_id}&type=3&prv=${prv}`,
                    state: { alldata: alldata, data: data, dta: true, index },
                    is_DashLeague: true
                })
            }
        }, 100);
    }

    init = async () => {
        const { SelectedLeague } = this.state;
        const { league_id, sports_id } = SelectedLeague;
        let param = {
            "sports_id": sports_id,
            "league_id": league_id,
            "user_id": this.props.user_id ? this.props.user_id : '' // For Point screen
        }
        let [GlobalLeaderboardList, PrivateLeaderboardList, MiniLeagueLeaderboardList] = await Promise.all([
            Http.post(API.GET_USER_LEADERBOARD_LIST_GLOBAL, param),
        ]);
        // console.log(MiniLeagueLeaderboardList);
        return {
            current_week: GlobalLeaderboardList.data.current_week,
            ...GlobalLeaderboardList.data
            // ...PrivateLeaderboardList.data,
            // ...MiniLeagueLeaderboardList.data,
        }
    }

    GetAllLeague = () => {
        this.init().then(res => {
            this.setState({
                LeaderboardList: res,
                current_week: res.current_week,
                posting: false
            })
        })
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps.updateLineupInfo !== this.props.updateLineupInfo) {
            return false;
        } else {
            return true;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.updateLineupInfo != nextProps.updateLineupInfo) {
            this.GetAllLeague()
        }
    }

    componentDidMount() {
        // const { extend } = this.props
        // if (extend) {
        this.init().then(res => {
            this.setState({
                LeaderboardList: res,
                current_week: res.current_week,
                posting: false
            })
        })
        // }
    }


    getCatName = (str) => {
        const { LeagueType } = this.state;
        let _arr = _.filter(LeagueType, o => o.value == str)
        return !_.isEmpty(_arr) ? _arr[0].label : 'Leagues'
    }

    render() {
        const { className, extend, selected } = this.props;
        const {
            posting,
            LeaderboardList,
            CurrentLineup
        } = this.state;
        return (
            <div {...{ className: `lineup-info-leaderboard ${className}` }} ref={this.setInstance}>
                {
                    posting ?
                        <div className="spinner-static">
                            <Utils.Spinner primary />
                        </div>
                        :
                        _.map(LeaderboardList, (Leaderboard, idx) => {
                            return (
                                !_.isEmpty(Leaderboard) && (!_.isEmpty(Leaderboard[0]) || !_.isEmpty(Leaderboard.overall_standing)) ?
                                    <Fragment key={idx}>
                                        {
                                            (selected.value == "all_league" || selected.value == idx) &&
                                            <div className="lineup-leaderboard">
                                                {/* <div className="title"><span>{this.getCatName(idx)}</span></div> */}
                                                <div className="joined-global-wrap">

                                                    {
                                                        !_.isEmpty(Leaderboard) ?
                                                            <>
                                                                {
                                                                    _.map(Leaderboard, (item, index) => {
                                                                        {/* console.log(LeaderboardList,'LeaderboardList') */ }

                                                                        if (_.isUndefined(item) || _.isEmpty(item)) return ''
                                                                        if (!_.isUndefined(item.is_country_set) && item.is_country_set == 0) return ''
                                                                        return (
                                                                            <div className="leaderboard-item" key={index}>

                                                                                <div className="title-block" onClick={() => this.gotoDetails(item, index, LeaderboardList)}>
                                                                                    <span>{!_.isUndefined(item) ? (item.category_name || item.contest_name) : '--'}</span>
                                                                                    {
                                                                                        item.is_owner == 1 && (!_.isString(index) && CurrentLineup.week_info.last_week != CurrentLineup.week_info.active_week) &&
                                                                                        <ShareButton item={item} />
                                                                                        // <span className='icon-share ' onClick={() => this.shraeModalInit(item)}></span>
                                                                                    }

                                                                                </div>
                                                                                {
                                                                                    extend &&
                                                                                    <>
                                                                                        <div className="cell fixw-l text-center">{item.rank || '--'}</div>
                                                                                        <div className="cell fixw-l text-center">{item.last_rank || '--'}</div>
                                                                                        <div className="cell fixw-l text-center">{item.total_score || '--'}</div>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    !extend &&
                                                                                    <div className="rank-block">
                                                                                        {(Utils.getActive() > parseInt(Utils.getJoined())) && item.rank !== 0 &&
                                                                                            <span>
                                                                                                {item.last_rank < item.rank && item.last_rank !== '0' ?
                                                                                                    < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                                                                    :
                                                                                                    < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                                                                }
                                                                                            </span>}
                                                                                        {item.rank || '--'}
                                                                                        {(!_.isUndefined(LeaderboardList['contest_info']) && Number(item.rank) <= Number(LeaderboardList['contest_info'].max_winning_rank) && item.rank !== 0) && (Utils.getActive() > parseInt(Utils.getJoined())) && <img src={Images.TROPHY_IC} alt='trophy ic' className='pos-update' />}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </>
                                                            :
                                                            <h5 className='no-data-league'><Trans>You can see other users lineup after the matches of upcoming week is started</Trans></h5>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                    : ''
                            )
                        })
                }
            </div>
        );
    }
}

DashLeague.defaultProps = {
    updateLineupInfo: null,
    extend: false,
    className: '',
    selected: { value: 'all_league', label: 'All Leagues' }
}

export default ReduxHOC(withRouter(DashLeague, { withRef: true }))
