/* eslint-disable */
import { Component } from 'react';
import {
  Col, Container, Row
} from 'reactstrap';
import {
  API,
  Http,
  Utils,
  _,
  NavLink,
  Trans,
  Images,
  Auth
} from 'Helpers';
import {
  Banner,
  // Banner, 
  Img,
  MultiBanner
} from 'Components';
import { PlayerCard } from 'Modals';
import { ReduxHOC } from 'HOC';

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      transferINPlayer: [],
      transferOUTPlayer: [],
      SelectedPlayer: {},
      isPlayerCardShow: false,
      is_single_player: Utils.isSinglePlayer(),
      top_scorer: {},
      top_scorer_tab: 'weekly_top_scorer',
      most_picked_captain: {},
      most_picked_tab: 'weekly_picked_captains',
      week_label: Utils.getWeekLabel(Utils.getMultiLeg().week_label['1']),
      loadTabs: true,
      loadTabsCaptian: true
    }
  }

  componentDidMount() {
    this.getTopScoringPlayers();
  }

  getPlayersStastics = () => {
    const { SelectedLeague } = this.state;
    let param = {
      "league_id": SelectedLeague.league_id,
      "sports_id": SelectedLeague.sports_id,
    }
    Http.post(API.GET_PLAYERS_STASTICS, param).then(response => {
      const { data } = response;
      this.setState({
        gW_display: (data.next_week),
        transferINPlayer: data.in_players,
        transferOUTPlayer: data.out_players,
      })
    })
  }
  getTopScoringPlayers = () => {
    const { SelectedLeague } = this.state;
    let param = {
      "league_id": SelectedLeague.league_id,
      "sports_id": SelectedLeague.sports_id,
      "limit": "50",
      "set_weekly_data": "1"
    }
    Http.post(API.GET_TOP_SCORING_PLAYER, param).then(response => {
      const { data } = response;
      this.setState({
        top_scorer: data
      }, () => {
        this.getMostPickedCaptains()
      });
    }).catch(error => {
      console.error(error)
      this.getMostPickedCaptains()
    });
  }
  getMostPickedCaptains = () => {
    const { SelectedLeague } = this.state;
    let param = {
      "league_id": SelectedLeague.league_id,
      "sports_id": SelectedLeague.sports_id,
      "limit": "50",
    }
    Http.post(API.MOST_PICKED_CAPTAINS, param).then(response => {
      const { data } = response;
      this.setState({
        most_picked_captain: data
      }, () => {
        this.getPlayersStastics();
      });

    }).catch(error => {
      console.error(error)
      this.getPlayersStastics();
    });
  }

  PlayerCardInit = (SelectedPlayer) => {
    this.setState({
      SelectedPlayer
    }, () => {
      this.PlayerCardToggle()
    })
  }

  PlayerCardToggle = () => {
    this.setState({
      isPlayerCardShow: !this.state.isPlayerCardShow,

    })
  }

  getPercentage = (Val, totalVal) => {
    return ((Val / totalVal) * 100).toFixed(2);
  }

  changeScoringTab = (item) => {
    this.setState({
      top_scorer_tab: item.value,
      loadTabs: false
    }, () => {
      setTimeout(() => {
        this.setState({
          loadTabs: true
        })
      }, 100);

    })
  }

  changePickedCaptainTab = (item) => {
    this.setState({
      most_picked_tab: item.value,
      loadTabsCaptian: false
    }, () => {
      setTimeout(() => {
        this.setState({
          loadTabsCaptian: true
        })
      }, 100);

    })
  }
  render() {
    const { t } = this.props
    const {
      transferINPlayer,
      transferOUTPlayer,
      isPlayerCardShow,
      SelectedLeague,
      SelectedPlayer,
      is_single_player,

      top_scorer,
      top_scorer_tab,

      most_picked_captain,
      most_picked_tab,
      week_label,
      gW_display,
      loadTabs,
      loadTabsCaptian
    } = this.state

    const PlayerCardProps = {
      isOpen: isPlayerCardShow,
      toggle: this.PlayerCardToggle,
      selected_league: SelectedLeague,
      Player: SelectedPlayer,
    }

    const ScoreTabList = [
      {
        value: 'weekly_top_scorer',
        label: 'Week'
      },
      {
        value: 'month_top_scorer',
        label: t('Month')
      },
      {
        value: 'overall_top_scorer',
        label: t('Overall')
      },
    ]
    const MostPickedTabList = [
      {
        value: 'weekly_picked_captains',
        // label: Utils.getWeekLabel(SelectedLeague.week_label['1'])
        label: 'Week'
      },
      {
        value: 'month_picked_captains',
        label: t('Month')
      },
      {
        value: 'overall_picked_captains',
        label: t('Overall')
      },
    ]
    return (
      <Container className='statistics-container'>

        <Row className='stats-head'>
          <Col id='fit-cont'>
            <div className="heading-main"><Trans>Statistics</Trans></div>
            {/* <p className='subtitle'><Trans>Play Fantasy with your friends</Trans></p> */}
          </Col>
          {
            <Col md={2} className='ta-r'>
              <NavLink className='normal-nav' to='/statistics/all'><Trans>View All Player Stats</Trans></NavLink>
            </Col>
          }
        </Row>
        <div className="multi-banner-wrap multibanner-wrap">
          <Banner type='57' />
          <MultiBanner type='21' />
        </div>
        {/* <Banner type="9" paddingTop /> */}
        <Row className='stats-box-row'>
          {
            // !Auth.getAuth() &&
            // <div className="stats-blank-msz m-t-md">
            //   <span>
            //     <Trans>The new season is on its way</Trans> <br /><br />

            //     <Trans>Grab your seat!!</Trans>  <br />
            //     <Trans>We will be filling in this page soon</Trans>

            //   </span>
            // </div>
          }
          <Col>
            <div className="stats-box">
              <div className="stats-box-header">
                <div className="title">
                  <span><Trans>Top Scoring Players</Trans></span>
                  {/* <i className="icon-share clr-change" /> */}
                </div>
                <div className="stats-tab">
                  {
                    _.map(ScoreTabList, (item, idx) => {
                      return (
                        <span key={idx} onClick={() => this.changeScoringTab(item)} {
                          ...{
                            className: `${top_scorer_tab == item.value ? 'selected' : ''}`
                          }
                        }><Trans>{item.label}</Trans></span>
                      )
                    })
                  }
                </div>
              </div>
              <div className={_.isEmpty(top_scorer[top_scorer_tab]) ? 'stats-box-body no-data-stats' : "stats-box-body"}>
                {
                  _.isEmpty(top_scorer[top_scorer_tab]) &&
                  <div className="blank-msz m-t-md text-center"><span><Trans>Data will reflect after MW1</Trans></span></div>
                }
                {loadTabs &&
                  _.map(top_scorer[top_scorer_tab], (item, idx) => {
                    return (
                      <div className="state-item" key={idx} onClick={() => this.PlayerCardInit(top_scorer.player_list[item.player_uid])}>
                        <div className="cell first">
                          {idx + 1}
                        </div>
                        <div className="cell mid">
                          <div className="player-name"><span>{top_scorer.player_list[item.player_uid].full_name}</span></div>
                          <div className="player-subline">
                            {
                              !is_single_player &&
                              <Img src={top_scorer.player_list[item.player_uid].flag} fallbacksrc={Images.DEFAULT_FLAG} alt="" className='player-flag' />
                            }
                            <span className="player-pos">{top_scorer.player_list[item.player_uid].position}</span>
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="value">{item.top_score} <Trans>Pts</Trans></div>
                          <div {...{
                            className: `graph-cell ${this.getPercentage(item.top_score, top_scorer[top_scorer_tab][0].top_score) < 50 ? 'gray' : 'primary'}`,
                            style: {
                              width: this.getPercentage(item.top_score, top_scorer[top_scorer_tab][0].top_score) + '%'
                            }
                          }} />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Col>

          <Col>
            <div className="stats-box two">
              <div className="stats-box-header">
                <div className="title">
                  <span><Trans>Most Picked Captain</Trans></span>
                  {/* <i className="icon-share clr-change" /> */}
                </div>
                <div className="stats-tab">
                  {
                    _.map(MostPickedTabList, (item, idx) => {
                      return (
                        <span key={idx} onClick={() => this.changePickedCaptainTab(item)} {
                          ...{
                            className: `${most_picked_tab == item.value ? 'selected' : ''}`
                          }
                        }><Trans>{item.label}</Trans></span>
                      )
                    })
                  }
                </div>
              </div>
              <div className={_.isEmpty(most_picked_captain[most_picked_tab]) ? 'stats-box-body no-data-stats' : "stats-box-body"}>
                {
                  _.isEmpty(most_picked_captain[most_picked_tab]) &&
                  <div className="blank-msz m-t-md text-center"><span><Trans>Data will reflect after MW1</Trans></span></div>
                }
                {loadTabsCaptian &&
                  _.map(most_picked_captain[most_picked_tab], (item, idx) => {
                    return (
                      <>
                        {!_.isUndefined(most_picked_captain.player_list[item.player_uid]) && <div className="state-item" key={idx} onClick={() => this.PlayerCardInit(most_picked_captain.player_list[item.player_uid])}>
                          <div className="cell first">
                            {idx + 1}
                          </div>
                          <div className="cell mid">
                            <div className="player-name"><span>{most_picked_captain.player_list[item.player_uid].full_name}</span></div>
                            <div className="player-subline">
                              {
                                !is_single_player &&
                                <Img src={most_picked_captain.player_list[item.player_uid].flag} fallbacksrc={Images.DEFAULT_FLAG} alt="" className='player-flag' />
                              }
                              <span className="player-pos">{most_picked_captain.player_list[item.player_uid].position}</span>
                            </div>
                          </div>
                          <div className="cell last">
                            <div className="value">{item.captain_percentage}%</div>
                            <div {...{
                              className: `graph-cell ${this.getPercentage(item.captain_percentage, most_picked_captain[most_picked_tab][0].captain_percentage) < 50 ? 'gray' : 'primary'}`,
                              style: {
                                width: this.getPercentage(item.captain_percentage, most_picked_captain[most_picked_tab][0].captain_percentage) + '%'
                              }
                            }} />
                          </div>
                        </div>}
                      </>
                    )
                  })
                }
              </div>
            </div>
          </Col>

          <Col>
            <div className="stats-box half">
              <div className="stats-box-header">
                <div className="title">
                  <span className='trf'> <i className="icon-down-1" /> <Trans>Transferred In</Trans></span>
                  {/* <small className="week-label">{week_label}</small> */}
                  <div className='gw-dsp'>{Utils.getMultiLeg().week_abbr[gW_display]}</div>
                </div>
                <div className="table-item head">
                  <div className="cell first"><Trans>Player</Trans></div>
                  <div className="cell wd"><Trans>Position</Trans></div>
                  <div className="cell wd"><Trans>No. of transfers</Trans></div>
                </div>
              </div>
              <div className={_.isEmpty(transferINPlayer) ? 'stats-box-body no-data-stats' : "stats-box-body"}>
                {
                  _.isEmpty(transferINPlayer) &&
                  <div className="blank-msz m-t-md text-center"><span><Trans>Data will reflect after MW1</Trans></span></div>
                }
                {
                  _.map(transferINPlayer, (item, idx) => {
                    return (
                      <div key={idx} className="table-item" onClick={() => this.PlayerCardInit(item)}>
                        <div className="cell first">
                          <div className="player-thumb">
                            <Img src={is_single_player ? item.player_image : item.jersey} fallbacksrc={Images.DEFAULT_JERSEY} alt="" className='player-img' />
                            {
                              !is_single_player &&
                              <img src={item.flag} alt="" className="player-flag" />
                            }
                          </div>
                          <div className="player-details">
                            <div className="name">
                              <span>{item.full_name}</span>
                            </div>
                            <div className="team-name">
                              {is_single_player ? (item.country || '--') : (item.team_abbr_lang || '--')}
                            </div>
                          </div>
                        </div>
                        <div className="cell wd gray-999">
                          <b>{item.position}</b>
                        </div>
                        <div className="cell wd">
                          <b>{item.count || '-'}</b>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="stats-box half">
              <div className="stats-box-header">
                <div className="title">
                  <span><i className="icon-up-1" /><Trans>Transferred Out</Trans></span>
                  {/* <small className="week-label">{week_label}</small> */}
                  {/* <div className='gw-dsp'>{Utils.getWeekLabel(Utils.getMultiLeg().week_abbr['1'])} {gW_display}</div> */}
                  <div className='gw-dsp'>{Utils.getMultiLeg().week_abbr[gW_display]}</div>
                </div>
                <div className="table-item head">
                  <div className="cell first"><Trans>Player</Trans></div>
                  <div className="cell wd"><Trans>Position</Trans></div>
                  <div className="cell wd"><Trans>No. of transfers</Trans></div>
                </div>
              </div>
              <div className={_.isEmpty(transferOUTPlayer) ? 'stats-box-body no-data-stats' : "stats-box-body"}>
                {
                  _.isEmpty(transferOUTPlayer) &&
                  <div className="blank-msz m-t-md text-center"><span><Trans>Data will reflect after MW1</Trans></span></div>
                }
                {
                  _.map(transferOUTPlayer, (item, idx) => {
                    return (
                      <div key={idx} className="table-item" onClick={() => this.PlayerCardInit(item)}>
                        <div className="cell first">
                          <div className="player-thumb">
                            <Img src={is_single_player ? item.player_image : item.jersey} fallbacksrc={Images.DEFAULT_JERSEY} alt="" className='player-img' />
                            {
                              !is_single_player &&
                              <img src={item.flag} alt="" className="player-flag" />
                            }
                          </div>
                          <div className="player-details">
                            <div className="name">
                              <span>{item.full_name}</span>
                            </div>
                            <div className="team-name">
                              {is_single_player ? (item.country || '--') : (item.team_abbr_lang || '--')}
                            </div>
                          </div>
                        </div>
                        <div className="cell wd gray-999">
                          <b>{item.position}</b>
                        </div>
                        <div className="cell wd">
                          <b>{item.count || '-'}</b>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Col>
        </Row>

        {
          isPlayerCardShow &&
          <PlayerCard {...PlayerCardProps} />
        }
      </Container>
    )
  }
}

export default ReduxHOC(Statistics);
