/* eslint-disable */
import { Component, createRef  } from 'react';
import {
  API, Auth, Constant, Http, Utils, _, Trans, Images,
} from 'Helpers';
import { Button, Col, Container, Row } from 'reactstrap';
import { Booster, DeadlineClock, GameweekNativeSlider, PitchView, SeasonEndAlert, MyTeamRoster, InfoPopover, Banner, FixtureComponent, SelectLeague } from 'Components';
import { PlayerCard, TeamSubmiModal, ConfirmTransfer, FormationModal, TeamShareModal } from 'Modals';
import { StaticPage } from 'Pages';
import { ReduxHOC } from 'HOC';
import Drawer from 'react-motion-drawer';
import FixtureMT from 'Components/FixturesComp/FixtureMT';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { useScreenshot } from 'use-react-screenshot'
import { toPng } from 'html-to-image';





const {
  REACT_APP_SALARY_SUBFFIX
} = process.env

class MyTeam extends Component {
  constructor(props) {
    super(props);
    this.nodes = {}
    this.state = {
      _temp: [],
      IS_TRASFER: props.name == 'transfers',
      IS_MYTEAM: props.name == 'myteam',
      gameweeklastupdate: new Date().valueOf(),
      SelectedLeague: Utils.getMultiLeg(),
      CurrentLineup: props.root.CurrentLineup,
      AppMasterData: props.root.AppMasterData,
      sports_config: {},
      booster_list: [],

      ALL_POSITION: [],
      SORT_BY_LIST: [],
      ALL_TEAM: {},
      SALARY_CAP: null,
      team_name: '',
      is_editable: 1,
      team_info: {},
      my_performance: {},
      user_join_count: null,
      active_week: '',
      next_week: null,
      last_week: null,
      joined_week: null,
      lineup_master_id: null,


      LineupStage: '', // 'CREATE', 'EDIT',
      LineupList: Utils.getLineup() || [],
      LineupListClone: Utils.getLineup() || [],
      LineupLoader: true,
      scoring_weeks: [],
      SelectedWeekObj: {},

      is_single_player: Utils.isSinglePlayer(),
      updatePlayerList: new Date().valueOf(),
      updateLineupInfo: null,
      updateBooster: new Date().valueOf(),
      isTeamSubmitShow: false,
      SelectedPlayer: {},
      isPlayerCardShow: false,
      SelectedWeek: '',
      SelectedWeekData: {},
      isConfirmTransferShow: false,
      InPlayers: Utils.getTransferData().in,
      BoosterPosting: false,
      isDrawerOpen: false,
      innerWidth: null,
      innerHeight: null,
      MicroSiteWidth: 991,

      FormationModalShow: false,
      default_formation: '',
      default_formation_clone: '',
      SwapActive: false,
      SwapSource: {},
      MinVal: {},
      constant_pos: Utils.getFormationInitial(),
      PlayerRoleTemp: [],
      btnActive: false,
      AutoPickPosing: false,
      MasterRosterList: [],
      team_update: {},
      is_rule_violate:false,
      ScImage:"",
      shareModal:false,
      image: null,
      optionDisable:false,
      is_rule_violate: false,
      WKSel: this.props.location && this.props.location.state && this.props.location.state.selWek

    }
    this.nodeRef = createRef();
    this.ref = createRef();
    this.contentRef = createRef();



    
  }

  convertToPng = () => {
    const node = this.contentRef.current; // Access the div element using the ref

    toPng(node)
      .then((dataUrl) => {
        // Create an image element and set its src to the data URL
        const img = new Image();
        img.src = dataUrl;

        // Append the image to the body or any other element
        document.body.appendChild(img);

        // Optionally, download the image
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'my-div.png';
        link.click();
      })
      .catch((error) => {
        console.error('Error generating PNG:', error);
      });
  };

  // handleCapture = () => {
  //   if (this.contentRef.current) {
  //     toPng(this.contentRef.current)
  //       .then(async(dataUrl) => {
  //         const base64Response = await fetch(dataUrl);
  //       const blob = await base64Response.blob();
        
  //       // Create a File object from the Blob
  //       const file = new File([blob], "screenshot.png", { type: "image/png" });

  //       // Now you can use the File object as needed
  //       console.log('File created:', file);

  //       // Optionally, update the state or perform other actions
  //       this.setState({ imageDataUrl: dataUrl },()=>{
  //         this.uploadImage(file);
  //       });
  //       })
  //       .catch((error) => {
  //         console.error('Error capturing image:', error);
  //       });
  //   }
  // };

  handleCapture = () => {
    // Find the element by its class name
   // const element = document.querySelector('.main-myteam');
    const element = document.getElementById('myDiv');

    this.setState({
      optionDisable:true
    })
    if (element) {
      toPng(element)
        .then(async (dataUrl) => {
          try {
            // Convert Base64 data URL to Blob
            const base64Response = await fetch(dataUrl);
            const blob = await base64Response.blob();
            
            // Create a File object from the Blob
            const file = new File([blob], "screenshot.png", { type: "image/png" });
            
            // Now you can use the File object as needed
            console.log('File created:', file);
            
            // Optionally, update the state or perform other actions
            this.setState({ imageDataUrl: dataUrl }, () => {
              this.uploadImage(file); // Example function to handle the file
            });
          } catch (error) {
            console.error('Error converting data URL to file:', error);
          }
        })
        .catch((error) => {
          console.error('Error capturing image:', error);
        });
    } else {
      console.error('Element not found');
    }
  };
  

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node
    }
  }



  /**
   * #1
   * @description method to get lineup master data
   */
  getLineupMasterData = (param) => {
    const { t } = this.props;
    const { SelectedLeague, is_single_player, CurrentLineup } = this.state;
    Http.post(API.GET_LINEUP_MASTER_DATA, param).then(response => {
      const { data } = response;

      this.setState({
        MinVal: data.lineup_formation ? this.GetMinPosValue(data.lineup_formation.formation, data.formation_positions) : [],
        RosterMasterData: data,
        ALL_POSITION_CLONE: data.all_position,
        ALL_POSITION: [{
          position_display_name: t('All'),
          max_player_per_position: null,
          position: ""
        },
        ...(is_single_player ? data.all_position[data.next_week] : data.all_position)
        ],
        team_name: data.team_name,
        booster_list: data.booster_list,
        SORT_BY_LIST: [{
          value: '',
          label: t('All'),
        }, ...data.sort_by_list],
        SALARY_CAP: data.salary_cap,
        default_formation: Utils.getDefaultFormation() === _.cloneDeep(data.default_formation) ? _.cloneDeep(data.default_formation) : Utils.getDefaultFormation(),
        default_formation_clone: _.cloneDeep(data.default_formation),
        next_week: data.next_week,
        active_week: data.active_week,
        last_week: data.last_week,
        lineup_master_id: data.lineup_master_id,
        LineupStage: (data.lineup_master_id && data.lineup_master_id != 0 ? Constant.LINEUP_STAGE_EDIT : Constant.LINEUP_STAGE_CREATE),
      }, () => {

        if (is_single_player) {
          // console.log(data.all_position[data.next_week]);
          // let last_pos = _.last(data.all_position[data.next_week])
          // console.log(last_pos.position_name, 'data.all_position[data.next_week]');
        }

        if (Auth.getAuth()) {
          this.getUserTeamInfo({
            ...param,
            lineup_master_id: CurrentLineup.team_info.lineup_master_id,
            contest_id: CurrentLineup.contest_id
          })
          // this.getManageTeamMaster(param)
          // console.log(Utils.setDefaultFormation(_.cloneDeep(data.default_formation)), 'dd')
          if (Utils.getDefaultFormation() !== _.cloneDeep(data.default_formation)) {
            //console.log('Formation Not Changed')
          }
          else {
            Utils.setDefaultFormation(_.cloneDeep(data.default_formation))
          }
          // 
        } else {

          Utils.setDefaultFormation(_.cloneDeep(data.default_formation))
          // this.nodes.PitchView.lineupGenerate()
          // Create team method
        }
      });


    }).catch(error => {
      this.props.history.push(Constant.DASHBOARD_PATH)
      console.error(error)
    });
  }

  /**
   * #2
   * @description method to get team master data
   */
  getManageTeamMaster = (option) => {
    const { CurrentLineup, is_single_player } = this.state;
    const {
      next_week_start_time,
      week_info,
      team_info,
      contest_id,
      user_join_count
    } = CurrentLineup

    this.setState({
      contest_id: contest_id,
      user_join_count: user_join_count
      // default_formation: team_info.selected_formation,
      // default_formation_clone: team_info.selected_formation,
      // my_performance: data.my_performance,
    }, () => {

      // Utils.setDefaultFormation(_.cloneDeep(data.selected_formation))

      if (!is_single_player) {
        this.getAllTeam(option)
      }

      if (false) // Already Lineup submit
      {

      } else {
        if (user_join_count == 1) {
          this.getUserLineupDetails({
            ...option,
            contest_id: contest_id,
            lineup_master_id: team_info.lineup_master_id,
            week: this.state.SelectedWeek == '' || _.isUndefined(this.state.SelectedWeek) ? week_info.next_week : this.state.SelectedWeek,
          })
        } else {
          this.setState({
            SelectedWeek: week_info.next_week
          }, () => {
            //console.log(1);
            this.nodes.PitchView.lineupGenerate('create')
          })

          // Create team method
        }
      }
    })


    return
    let param = {
      ...option,
      "is_performance_required": 1,
      "week": this.state.SelectedWeek == '' ? '' : this.state.SelectedWeek
    }
    Http.post(API.GET_MANAGE_TEAM_MASTER_DATA, param).then(response => {

      const { data } = response;
      this.setState({
        next_week_start_time: data.next_week_start_time,
        next_week: data.next_week,
        active_week: data.active_week,
        last_week: data.last_week,
        lineup_master_id: data.lineup_master_id,
        LineupStage: (data.lineup_master_id && data.lineup_master_id != 0 ? Constant.LINEUP_STAGE_EDIT : Constant.LINEUP_STAGE_CREATE),
        default_formation: data.selected_formation,
        default_formation_clone: data.selected_formation,
        contest_id: data.contest_id,
        my_performance: data.my_performance,
        user_join_count: data.user_join_count
      }, () => {
        const { CurrentLineup, is_single_player } = this.state;
        Utils.setDefaultFormation(_.cloneDeep(data.selected_formation))
        if (!is_single_player) {
          this.getAllTeam(option)
        }

        if (false) // Already Lineup submit
        {

        } else {
          if (CurrentLineup.user_join_count == 1) {
            this.getUserLineupDetails({
              ...option,
              contest_id: data.contest_id,
              lineup_master_id: data.lineup_master_id,
              week: this.state.SelectedWeek == '' ? data.next_week : this.state.SelectedWeek,
            })
          } else {
            this.setState({
              SelectedWeek: data.next_week
            }, () => {
              console.log(1);
              this.nodes.PitchView.lineupGenerate('create')
            })

            // Create team method
          }
        }
      })

    }).catch(error => {
      console.error(error)
    });
  }

  /**
   * #3
   * @description method to get user lineup details
   */
  getUserLineupDetails = (options) => {
    Http.post(API.GET_USER_LINEUP_DETAIL, options).then(response => {
      const { data } = response;
      const _lineup = _.orderBy(
        data.lineup,
        function (o) {
          return new Number(o.rank);
        },
        ['asc']
      )
      let check_is_rule_violate = _lineup.some(obj => obj.is_rule_violate === "1");
      this.setState({
        LineupList: _.cloneDeep(_lineup),
        LineupListClone: _.cloneDeep(_lineup),
        default_formation: data.formation,
        default_formation_clone: data.formation,
        SALARY_CAP: data.salary_cap,
        is_rule_violate: check_is_rule_violate
      }, () => {
        Utils.setDefaultFormation(_.cloneDeep(data.formation))
        this.setState({
          updateLineupInfo: new Date().valueOf(),
        });
        // this.getUserTeamInfo(options)


        const { SelectedWeek, LineupList } = this.state
        if (LineupList.length < Number(this.getLineupRosterLimit(SelectedWeek))) {
          this.nodes.PitchView.lineupGenerate('', Number(this.getLineupRosterLimit(SelectedWeek)) - LineupList.length)
        } else {
          this.nodes.PitchView.lineupGenerate()
        }


      })
    }).catch(error => {
      console.error(error)
    });

  }

  /**
   * #4
   * @description method to get user team info
   */
  getUserTeamInfo = (options) => {
    const { CurrentLineup, is_single_player, SelectedWeek, next_week, WKSel } = this.state;
    const {
      contest_id,
      user_join_count,
      team_info,
      week_info
    } = CurrentLineup

    this.setState({
      contest_id: contest_id,
      user_join_count: user_join_count,
      // next_week: week_info.next_week,
      lineup_master_id: team_info.lineup_master_id,
      // my_performance: data.my_performance,
    }, () => {
      if (!is_single_player) {
        this.getAllTeam(options)
      }
      if (false) { } else {
        if (user_join_count == 1) {
          this.getUserLineupDetails({
            ...options,
            contest_id: contest_id,
            lineup_master_id: team_info.lineup_master_id,
            week: WKSel ? WKSel : SelectedWeek == '' || _.isUndefined(SelectedWeek) ? next_week : SelectedWeek,
          })
        } else {
          this.setState({
            SelectedWeek: next_week
          }, () => {
            this.nodes.PitchView.lineupGenerate('create')
          })
        }
      }
    })
    if (user_join_count == 1) {
      Http.post(API.GET_USER_TEAM_INFO, {
        ...options,
        // "need_graph_data": 1
      }).then(response => {
        const { team_info } = response.data;
        this.setState({
          team_info: team_info,
          joined_week: team_info.joined_week,
          next_week: team_info.next_week,
          active_week: team_info.active_week,
          last_week: team_info.last_week,
          lineup_master_id: team_info.lineup_master_id,
          LineupStage: (team_info.lineup_master_id && team_info.lineup_master_id != 0 ? Constant.LINEUP_STAGE_EDIT : Constant.LINEUP_STAGE_CREATE),
          boosterApplied: team_info.booster_applied,
          is_editable: team_info.is_editable,
          show_transfer: team_info.show_transfer,
          updateBooster: new Date().valueOf(),
          next_week_start_time: team_info.next_week_start_time,
        }, () => {
          /*  console.log(team_info, 'team_info');
            const { CurrentLineup, is_single_player, next_week, lineup_master_id, SelectedWeek } = this.state;
            const { 
              contest_id,
              user_join_count
            } = CurrentLineup
  
            this.setState({
              contest_id: contest_id,
              user_join_count: user_join_count
              // my_performance: data.my_performance,
            }, () => {
              if (!is_single_player) {
                this.getAllTeam(options)
              }
        
              if(false) // Already Lineup submit
              {} else {
                if(user_join_count == 1) {
                  this.getUserLineupDetails({
                    ...options,
                    contest_id: contest_id,
                    lineup_master_id: lineup_master_id,
                    week: SelectedWeek == '' || _.isUndefined(SelectedWeek) ? next_week : SelectedWeek,
                  })
                } else {
                  this.setState({
                    SelectedWeek: next_week
                  }, () => {
                    this.nodes.PitchView.lineupGenerate('create')
                  })
                  // Create team method
                }
              }
            }) */


          if (team_info.scoring_weeks.length > 0) {
            const { next_week } = this.state
            let tmpSelectedWeek = {}
            const scoring_weeks_mod = _.map(team_info.scoring_weeks, (week) => {
              if (this.state.SelectedWeek == '' && next_week == week.week) {
                tmpSelectedWeek = week;
              } else if (week.week == this.state.SelectedWeek) {
                tmpSelectedWeek = week;
              }
              return { ...week, id: week.week }
            })
            this.setState({
              scoring_weeks: scoring_weeks_mod,
              SelectedWeekObj: tmpSelectedWeek,
              SelectedWeek: tmpSelectedWeek.week,
              SelectedWeekData: tmpSelectedWeek,
              BoosterPosting: false,
              updateBooster: new Date().valueOf(),
              // updateLineupInfo: new Date().valueOf(),
              gameweeklastupdate: new Date().valueOf()
            })
          }
        })

      }).catch(error => {
        console.error(error)
      });
    }
  }

  /**
   * #A
   * @description method to get All team data
   */
  getAllTeam = (param) => {
    const { t } = this.props;
    let s3_param = [
      API.S3_GET_ALL_TEAM,
      param.league_id
    ]
    Http.s3(Utils.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((response) => {
      // Http.post(API.GET_ALL_TEAM, param).then(response => {
      const { data } = response;
      // console.log('object')
      let TempViews = [{
        // max_player_per_position: null,
      }]
      _.map(data, (it) => {
        TempViews.push({ value: it.team_id, team_name: it.team_name, team_abbr: it.team_abbr })
        // console.log(TempViews)
      })
      const idx = TempViews.map(o => o.team_id)
      const filters = TempViews.filter(({ value }, index) => !idx.includes(value, index + 1))
      this.setState({
        updatePlayerList: new Date().valueOf(),
        team_update: filters,
        ALL_TEAM: [{
          team_id: '',
          team_abbr: t('All'),
          team_name: t('All'),
        }, ...data],
      })

    }).catch(error => {
      console.error(error)
    });
  }
  /**
   * @description General method to data modification
   */
  getLineupRosterLimit = (week) => {
    const { sports_config } = this.state;
    let limit = '-'
    if (typeof sports_config.lineup_roster_limit === 'object' && sports_config.lineup_roster_limit !== null) {
      _.forEach(sports_config.lineup_roster_limit, (object, i) => {
        if (week == i) {
          limit = object
        }
      })
    } else {
      limit = sports_config.lineup_roster_limit
    }
    return limit
  }

  updateLineupList = (LineupList, isOnlyView) => {
    // alert(isOnlyView)
    this.setState({
      LineupList: _.cloneDeep(LineupList),
      // LineupListClone: _.cloneDeep(LineupList),
      LineupLoader: false,
      InPlayers: Utils.getTransferData().in
    }, () => {
      if (isOnlyView) return;
      // console.log(LineupList,"LineupListLineupList");
      Utils.setLineup(LineupList)
      // this.fullLineup()
    })
  }
  triggerPlayerList = (position) => {
    const {
      innerWidth,
      MicroSiteWidth
    } = this.state;
    if (MicroSiteWidth >= innerWidth) {
      this.toggleDrawer()
      if(this.nodes){
        this.nodes.PlayerListDrawer.rosterFilterUpdate(position);
      }
      
    } else {
      if(this.nodes){
        this.nodes.PlayerList.rosterFilterUpdate(position);
      }
    }
    
  }

  fullLineup = () => {
    const {
      LineupList,
      next_week
    } = this.state;
    return LineupList && (LineupList.length == this.getLineupRosterLimit(next_week))
  }

  LineupSave = (is_reg = true) => {
    if (is_reg && !Utils.isCapAvailable(this.state.LineupList)) {
      Utils.notify('Please select captain', 'error')
      return
    }
    if (is_reg && !Utils.isVCAvailable(this.state.LineupList)) {
      Utils.notify('Please select vice captain', 'error')
      return
    }
    const { lineup_master_id } = this.state
    if (_.isUndefined(lineup_master_id)) {
      this.setState({ isTeamSubmitShow: true })
      // alert(1)
    } else {
      // alert(9)
      this.LineupProccess()

    }
  }

  lineupMod = (lineup) => {
    const finalLineup = _.map(lineup, ({
      player_id,
      player_uid,
      player_team_id,
      position,
      salary,
      team_league_id,
      player_role,
      is_bench_player,
      bench_order,
      ...rest
    }) => {
      return {
        player_id,
        player_uid,
        player_team_id,
        position,
        salary,
        team_league_id,
        player_role,
        is_bench_player,
        bench_order
      }
    })
    return finalLineup
  }

  lineupModTrns = (lineup) => {
    const finalLineup = _.map(lineup, ({
      player_id,
      player_uid,
      player_team_id,
      position,
      salary,
      team_league_id,
      player_role,
      is_bench_player,
      bench_order,
      ...rest
    }) => {
      return {
        player_id,
        player_uid,
        position,
        salary,
        player_team_id,
        is_bench_player,
      }
    })
    return finalLineup
  }

  LineupProccess = () => {
    this.setState({
      LineupLoader: true
    })
    const { SelectedLeague, contest_id, lineup_master_id, LineupList, team_name, is_single_player, default_formation } = this.state;
    let _LineupList = _.map(_.cloneDeep(LineupList), (item) => {
      if (is_single_player) {
        item.position = item.position_clone
      }
      return item;
    });

    let param = {
      "league_id": SelectedLeague.league_id,
      "sports_id": SelectedLeague.sports_id,
      "team_name": team_name,
      "lineup_master_id": lineup_master_id,
      "contest_id": contest_id,
      "lineup": this.lineupMod(_LineupList),
      "selected_formation": default_formation,
    }

    Http.post(API.LINEUP_PROCCESS, param).then(response => {
      localStorage.removeItem('_tr');
      Utils.notify(response.message);
      this.setState({
        btnActive: false,
        LineupListClone: _.cloneDeep(LineupList),
        default_formation: Utils.getDefaultFormation(),
        default_formation_clone: Utils.getDefaultFormation(),
        LineupLoader: false
      }, () => {
        this.props.history.push('/my-team')
      }
        // , () => {
        //   if (!_.isUndefined(this.props.location.state.c_id) || !_.isEmpty(this.props.location.state)) {
        //     this.joinLeague()
        //   }
        // }
      )
    }).catch(error => {
      console.error(error)
    });
  }

  TeamSubmitToggle = () => {
    this.setState({
      isTeamSubmitShow: !this.state.isTeamSubmitShow
    })
  }
  ConfirmTransferToggle = (bool) => {
    //console.log(Utils.isVCAvailable(this.state.LineupList),"IIIIIIIIIII");
    //return;
    if (!Utils.isCapAvailable(this.state.LineupList)) {
      Utils.notify('Please select captain', 'error')
      return
    }
    //alert('radha')
    //return;
    // if(!Utils.isVCAvailable(this.state.LineupList)){
    //   Utils.notify('Please select vice captain', 'error')
    // }
    this.setState({
      isConfirmTransferShow: !this.state.isConfirmTransferShow
    }, () => {
      if (bool) {
        const { CurrentLineup, SelectedLeague } = this.state
        const { league_id, sports_id } = SelectedLeague;
        this.getUserTeamInfo({
          league_id, sports_id,
          lineup_master_id: CurrentLineup.team_info.lineup_master_id,
          contest_id: CurrentLineup.contest_id
        })
        // this.getManageTeamMaster({ league_id, sports_id })

        // this.setState({
        //   LineupListClone: _.cloneDeep(this.state.LineupList)
        // })
        setTimeout(this.props.history.push('/my-team'), 1000)
      }
    })
  }

  ResetLineup = () => {
    const { LineupStage, LineupListClone, IS_TRASFER, default_formation_clone } = this.state
    if (IS_TRASFER) {
      Utils.removeTransferData()
    }
    if (LineupStage == Constant.LINEUP_STAGE_EDIT) {
      this.setState({
        AutoPickPosing: false,
        SwapActive: false,
        LineupList: _.cloneDeep(LineupListClone),
        LineupLoader: true,
        default_formation: _.cloneDeep(default_formation_clone)
      }, () => {
        this.setState({
          LineupListClone: _.cloneDeep(this.state.LineupList),
          default_formation_clone: _.cloneDeep(this.state.default_formation),
        }, () => {
          const { SelectedWeek, LineupList } = this.state
          this.nodes.PitchView.lineupGenerate('', Number(this.getLineupRosterLimit(SelectedWeek)) - LineupList.length)
        })
      })
    } else {
      const { SelectedWeek, LineupList, RosterMasterData } = this.state;
      this.setState({
        AutoPickPosing: false,
        default_formation: Utils.getDefaultFormation() || RosterMasterData.default_formation
      }, this.nodes.PitchView.lineupGenerate('reset', Number(this.getLineupRosterLimit(SelectedWeek)) - LineupList.length))
    }

  }

  ApplyBooster = (type, is_already) => {
    const { SelectedLeague, lineup_master_id, CurrentLineup } = this.state
    const { league_id, sports_id } = SelectedLeague
    let param = {
      "league_id": league_id,
      "sports_id": sports_id,
      "lineup_master_id": lineup_master_id,
      ...(type == 0 ? {} : { "booster": type })
    }
    if (is_already) {
      Utils.notify('You cannot apply multiple boosters in single week', 'error');
      // Utils.notify('Booster already applied for next week!', 'error');
    } else {
      this.setState({ BoosterPosting: true })
      // if (type == 0) {
      //   console.log('You can applied wild card!');
      //   this.setState({ BoosterPosting: false })
      //   return
      // }
      Http.post(type == 0 ? API.APPLY_WILDCARD : API.APPLY_BOOSTER, param).then(res => {
        Utils.notify(res.message);
        this.getUserTeamInfo({
          league_id, sports_id,
          lineup_master_id: CurrentLineup.team_info.lineup_master_id,
          contest_id: CurrentLineup.contest_id
        })
        // this.getManageTeamMaster({ league_id, sports_id })
      }).catch(err => {
        this.setState({ BoosterPosting: false })
        console.error(err);
      })
    }
  }

  RemoveBooster = (e, type) => {
    e.stopPropagation();
    const { SelectedLeague, lineup_master_id, CurrentLineup } = this.state
    const { league_id, sports_id } = SelectedLeague
    let param = {
      "league_id": league_id,
      "sports_id": sports_id,
      "lineup_master_id": lineup_master_id,
      "booster": type
    }
    this.setState({ BoosterPosting: true })
    Http.post(API.REMOVE_BOOSTER, param).then(res => {
      Utils.notify(res.message);
      this.getUserTeamInfo({
        league_id, sports_id,
        lineup_master_id: CurrentLineup.team_info.lineup_master_id,
        contest_id: CurrentLineup.contest_id
      })

      // this.getManageTeamMaster({ league_id, sports_id })
    }).catch(err => {
      this.setState({ BoosterPosting: false })
      console.error(err);
    })
  }

  PlayerCardInit = (SelectedPlayer) => {
    this.setState({
      SelectedPlayer
    }, () => {
      this.PlayerCardToggle()
    })
  }

  PlayerCardToggle = () => {
    this.setState({
      isPlayerCardShow: !this.state.isPlayerCardShow
    })
  }

  PlayerRoleInit = (player, role) => {
    const { SelectedLeague, SwapActive } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let _SwapSource = {}
    let param = {
      "league_id": league_id,
      "sports_id": sports_id,
      "player_uid": player.player_uid,
      "player_role": role
    }
    // if(player.player_role != 0) {
    //   console.log(player.player_role);
    //   this.setState({
    //     PlayerRoleTemp: _.uniqBy([...this.state.PlayerRoleTemp, player], 'player_role')
    //   }, () => {
    //     console.log(this.state.PlayerRoleTemp);    
    //   });
    //   if(this.state.PlayerRoleTemp.length != 2) return;
    // }

    Http.post(API.UPDATE_CAPTAIN_VICECAPTAIN, param).then(res => {
      Utils.notify(res.message);
      const { LineupList } = this.state;
      let _LineupList = _.forEach(_.cloneDeep(LineupList), (item) => {
        if (item.player_role == role) {
          item.player_role = 0
        }
        if (item.player_uid == player.player_uid) {
          item.player_role = role
          if (SwapActive) {
            _SwapSource = item
          }
        }
      })
      this.setState({
        isPlayerCardShow: false,
        SwapSource: _SwapSource,
        LineupListClone: _.cloneDeep(_LineupList),
      }, () => {
        this.updateLineupList(_LineupList)
      })
    }).catch(err => {
      console.error(err)
    })
  }

  /* 
    SWAP PLAYER 
  */

  GetMinPosValue = (formation, formation_positions) => {
    let _arr = formation_positions.split('-')
    let _Arr = {
      [_arr[0]]: [],
      [_arr[1]]: [],
      [_arr[2]]: []
    }
    let single_pos = ''
    _.forEach(formation, (item, key) => {
      key = key.split('-')
      _Arr[_arr[0]].push(key[0])
      _Arr[_arr[1]].push(key[1])
      _Arr[_arr[2]].push(key[2])

      _.forEach(item, obj => {
        if (obj.number_of_bench_player == obj.number_of_players) {
          single_pos = obj.position
        }
      })
    })

    Utils.setFormationInitial({
      'P0': single_pos,
      'P1': _arr[0],
      'P2': _arr[1],
      'P3': _arr[2],
    })

    return {
      [_arr[0]]: Number(_.min(_Arr[_arr[0]])),
      [_arr[1]]: Number(_.min(_Arr[_arr[1]])),
      [_arr[2]]: Number(_.min(_Arr[_arr[2]]))
    }
  }

  getPosibleFormation = (LineupsList) => {
    const { constant_pos } = this.state;
    let P1_FILTER = _.filter(LineupsList, (o) => { return o.position == constant_pos.P1 && o.is_bench_player == 0 }).length;
    let P2_FILTER = _.filter(LineupsList, (o) => { return o.position == constant_pos.P2 && o.is_bench_player == 0 }).length;
    let P3_FILTER = _.filter(LineupsList, (o) => { return o.position == constant_pos.P3 && o.is_bench_player == 0 }).length;
    return P1_FILTER + '-' + P2_FILTER + '-' + P3_FILTER;
  }

  SwapInit = (player) => {
    const { LineupList, SwapSource, MinVal, constant_pos } = this.state;
    const { position, is_bench_player, player_uid } = player;
    let source;

    let filterP0 = _.filter(LineupList, (o) => { return (o.position == constant_pos.P0) && (o.is_bench_player != 1) }).length;
    let filterP1 = _.filter(LineupList, (o) => { return (o.position == constant_pos.P1) && (o.is_bench_player != 1) }).length;
    let filterP2 = _.filter(LineupList, (o) => { return (o.position == constant_pos.P2) && (o.is_bench_player != 1) }).length;
    let filterP3 = _.filter(LineupList, (o) => { return (o.position == constant_pos.P3) && (o.is_bench_player != 1) }).length;

    function SwapingConditionBench(Option) {
      const { NCKey, Count, Min, itemPos } = Option;
      if ((position == NCKey && Count > Min) || (position == NCKey && itemPos == NCKey && Count == Min)) {
        return true;
      } else {
        return false;
      }
    }
    let lineuplist = _.map(LineupList, item => {
      if (item.player_uid) {
        if (SwapSource.highlight_out == true) {
          item = _.omit(item, ['highlight'])
          item = _.omit(item, ['opacity'])
          item = _.omit(item, ['highlight_out'])
        }

        if (_.isEmpty(SwapSource) || (!_.isUndefined(SwapSource) && SwapSource.player_uid != player_uid)) {
          item.opacity = true;

          if (item.player_uid == player_uid) {
            item = _.omit(item, ['opacity'])
            item.highlight_out = true;
            source = item
          }
          // For Bench Player
          if (item.is_bench_player != is_bench_player && is_bench_player != 1) {
            item = _.omit(item, ['opacity'])
            if (SwapingConditionBench({ NCKey: constant_pos.P0, Count: filterP0, Min: 1, itemPos: item.position })) {
              item.highlight = true;
            } else if (SwapingConditionBench({ NCKey: constant_pos.P1, Count: filterP1, Min: MinVal[constant_pos.P1], itemPos: item.position })) {
              item.highlight = true;
            } else if (SwapingConditionBench({ NCKey: constant_pos.P2, Count: filterP2, Min: MinVal[constant_pos.P2], itemPos: item.position })) {
              item.highlight = true;
            } else if (SwapingConditionBench({ NCKey: constant_pos.P3, Count: filterP3, Min: MinVal[constant_pos.P3], itemPos: item.position })) {
              item.highlight = true;
            }
            if ((position !== constant_pos.P0 && item.position == constant_pos.P0) ||
              (position == constant_pos.P0 && item.position != constant_pos.P0)) {
              item.opacity = true;
              item = _.omit(item, ['highlight'])
            }
          }
          // For Field Player
          if (item.is_bench_player != is_bench_player && is_bench_player == 1) {
            if (
              position !== constant_pos.P0 &&
              ((position == constant_pos.P1 && item.position == constant_pos.P1) ||
                (position == constant_pos.P2 && item.position == constant_pos.P2) ||
                (position == constant_pos.P3 && item.position == constant_pos.P3) ||
                (
                  // (item.position == constant_pos.P0 && filterP0 == 1) ||
                  (item.position == constant_pos.P1 && filterP1 > MinVal[constant_pos.P1]) ||
                  (item.position == constant_pos.P2 && filterP2 > MinVal[constant_pos.P2]) ||
                  (item.position == constant_pos.P3 && filterP3 > MinVal[constant_pos.P3])
                ))
            ) {
              item = _.omit(item, ['opacity'])
              item.highlight = true;
            }
            if (position == constant_pos.P0 && item.position == constant_pos.P0) {
              item = _.omit(item, ['opacity'])
              item.highlight = true;
            }
          }

          // Bench Swapping allow
          if (is_bench_player == 1 && item.is_bench_player == is_bench_player) {
            // console.log('for bench')
            item.highlight = true;
            item = _.omit(item, ['opacity'])
            if ((position !== constant_pos.P0 && item.position == constant_pos.P0) || (position == constant_pos.P0 && item.position != constant_pos.P0)) {
              item.opacity = true;
              item = _.omit(item, ['highlight'])
            }
          }

        } else {
          item = _.omit(item, ['highlight'])
          item = _.omit(item, ['highlight_out'])
          item = _.omit(item, ['opacity'])
          source = {}
        }
        return item;
      } else {
        return item;
      }
    })

    this.setState({
      LineupList: lineuplist,
      SwapSource: source,
      SwapActive: !this.state.SwapActive
    }, () => {

    })

  }

  SwapExecute = (over) => {
    const { LineupList, SwapSource } = this.state;
    const lineup = _.cloneDeep(LineupList)

    let lineupTemp = _.map(lineup, item => {
      item = _.omit(item, ['highlight'])
      item = _.omit(item, ['highlight_out'])
      item = _.omit(item, ['opacity'])

      if (item.player_uid == SwapSource.player_uid) {
        item.is_bench_player = over.is_bench_player;
        item.bench_order = over.bench_order;
        item.player_role = over.player_role;
      }
      if (item.player_uid == over.player_uid) {
        item.is_bench_player = SwapSource.is_bench_player;
        item.bench_order = SwapSource.bench_order;
        item.player_role = SwapSource.player_role;
      }
      return item;
    })

    let msz = `You have sucessfully swapped ${SwapSource.full_name} with ${over.full_name}`
    if (SwapSource.player_uid != over.player_uid) {
      Utils.notify(msz);
      // alert("radhe")
      this.setState({
        btnActive: true
      })
    }
    this.setState({
      LineupList: lineupTemp,
      SwapSource: {},
      SwapActive: false,
      LineupLoader: true,
      default_formation: this.getPosibleFormation(lineupTemp)
    }, () => {
      Utils.setDefaultFormation(this.state.default_formation)
      this.setState({
        LineupLoader: false
      })
    })
  }

  SwapReset = () => {
    const { LineupListClone, default_formation_clone } = this.state;
    this.setState({
      SwapActive: false,
      LineupList: _.cloneDeep(LineupListClone),
      default_formation: _.cloneDeep(default_formation_clone),
      LineupLoader: true,
    }, () => {
      this.setState({
        LineupLoader: false
      })
    })
  }

  SelectWeekHandle = (obj) => {
    this.setState({
      SelectedWeekObj: obj,
      SelectedWeek: obj.week,
      LineupLoader: true,
      WKSel: obj.week
    }, () => {
      const { CurrentLineup, SelectedLeague } = this.state;
      const { league_id, sports_id } = SelectedLeague;
      this.getUserTeamInfo({
        league_id, sports_id,
        lineup_master_id: CurrentLineup.team_info.lineup_master_id,
        contest_id: CurrentLineup.contest_id
      })
      // this.getManageTeamMaster({ league_id, sports_id })
    })
  }

  ConsumeFreeTransfers = (val) => {
    const { team_info, next_week, InPlayers, SelectedLeague } = this.state;
    const { t } = this.props;
    const { free_transfer_week } = SelectedLeague;
    
    let ConsumeFreeransfer = 0;
    if (_.isEmpty(team_info)) return ConsumeFreeransfer;
    if (_.isEmpty(InPlayers)) return ConsumeFreeransfer;
    if (val >= InPlayers.length) {
      ConsumeFreeransfer = InPlayers.length
    } else {
      ConsumeFreeransfer = val
    }
    if ((team_info.free_hit_booster_status && team_info.free_hit_booster_status.is_applied == 1 && Number(team_info.free_hit_booster_status.applied_week) == Number(next_week)) ||
      (team_info.wildcard_status && team_info.wildcard_status.is_applied == 1 && Number(team_info.wildcard_status.applied_week) == Number(next_week)) ||
      free_transfer_week == Number(next_week)) return t('Unlimited');
      
    return ConsumeFreeransfer;
  }

  AvailableFreeTransfers = (val) => {
    const { t } = this.props;
    const { team_info, next_week, InPlayers, SelectedLeague } = this.state;
    const { free_transfer_week } = SelectedLeague;
    let AvalFreeransfer = 0;
    if ((team_info.free_hit_booster_status && team_info.free_hit_booster_status.is_applied == 1 && Number(team_info.free_hit_booster_status.applied_week) == Number(next_week)) ||
      (team_info.wildcard_status && team_info.wildcard_status.is_applied == 1 && Number(team_info.wildcard_status.applied_week) == Number(next_week)) ||
      free_transfer_week == Number(next_week)) return t('Unlimited');
    if (val >= InPlayers.length) {
      AvalFreeransfer = val - InPlayers.length
    }
    return AvalFreeransfer;
  }

  TransfersCast = (val) => {
    const { team_info, InPlayers } = this.state
    let TotalCast = 0;
    if (_.isEmpty(team_info)) return TotalCast;
    if (_.isEmpty(InPlayers)) return TotalCast;
    let isFreeAvail = this.AvailableFreeTransfers(val, InPlayers)
    if (isFreeAvail == 0) {
      TotalCast = (InPlayers.length - val) * team_info.per_transfer_deduct
    }
    return TotalCast;
  }

  FormationModalToggle = () => {
    const { user_join_count } = this.state
    console.log(this.state.user_join_count)
    //return
    if (user_join_count !== 0 && !Utils.isCapAvailable(this.state.LineupList)) {
      Utils.notify('Please select captain', 'error')
      return
    }
    if (user_join_count !== 0 && !Utils.isVCAvailable(this.state.LineupList)) {
      Utils.notify('Please select vice captain', 'error')
      return
    } else {
      this.setState({
        FormationModalShow: !this.state.FormationModalShow
      })
    }

  }

  FormationChangeHandle = (default_formation) => {
    this.setState({
      default_formation,
      FormationModalShow: false,
      LineupLoader: true
    })
    Utils.setDefaultFormation(default_formation)
    const {
      RosterMasterData,
      LineupList
    } = this.state;
    const LineupClone = _.cloneDeep(LineupList)
    let CurrentFormation = RosterMasterData.lineup_formation.formation[default_formation]
    let k = 1;
    for (let i = 0; i < CurrentFormation.length; i++) {
      const element = CurrentFormation[i];
      var filterArr = LineupClone.filter(function (item) {
        return item.position == element.position && item.player_role == 0
      })
      _.map(filterArr, (item, idx) => {
        item.is_bench_player = '0';
        // item.player_role = '0'
        item.bench_order = '0';
        if (idx < element.number_of_bench_player) {
          item.is_bench_player = '1';
          item.bench_order = k;
          k = k + 1
        }
        return item;
      })
    }
    setTimeout(() => {
      this.setState({
        LineupList: LineupClone,
        LineupLoader: false
      }, () => {
        const { IS_TRASFER } = this.state
        if (IS_TRASFER) {
          this.LineupProccess()
        }

      })
    }, 500)

  }


  // joinLeague = () => {

  //   // const { SelectedLeague, CurrentLineup, selectedMinileague } = this.state;
  //   // const { league_id, sports_id } = SelectedLeague;
  //   const { c_id, s_id, l_id } = this.props.location.state

  //   let join_game_param = {
  //     "sports_id": s_id,
  //     "league_id": l_id,
  //     "contest_id": c_id,
  //     "lineup_master_id": CurrentLineup.team_info.lineup_master_id,
  //   }
  //   Http.post(API.JOIN_GAME, join_game_param).then(responseJson => {
  //     Utils.notify(responseJson.message, "success", 5000);

  //     this.props.history.push('/leagues')
  //     this.setState({ posting: false });

  //   }).catch(error => {
  //     console.error(error);
  //     this.setState({
  //       posting: false,
  //       formValid: false,
  //       join_code: '',
  //     });
  //   });


  // }


  /**
   * @description Lifecycle(s)
   */
  componentDidMount() {
  // Check if another image loads properly
  const img = new Image();
  img.src = 'https://isl-prod-mum.s3.ap-south-1.amazonaws.com/upload/jersey/1695990801.png'; // Example placeholder image
  img.onload = () => {
    console.log('Test image loaded');
  };
  img.onerror = () => {
    console.error('Test image failed to load');
  };


    window.addEventListener("resize", this.updateDimensions);
    const { league_id, sports_id, sports_config } = this.state.SelectedLeague;
    this.setState({
      sports_config
    })
    Promise.all([
      this.getLineupMasterData({ league_id, sports_id }),
    ])
    Utils.removeTransferData()
    document.body.addEventListener('click', this.handleBodyClick);
  }
  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    this.setState = () => {
      return;
    };
  }
  updateDimensions = () => {
    this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
  }
  toggleDrawer = (bool = true) => {
   // e.stopPropagation()
    if (!this.state.isDrawerOpen) {
      document.body.classList.add('add-overflow');
    } else {
      document.body.classList.remove('add-overflow');
    }
    this.setState({
      isDrawerOpen: bool == false ? bool : !this.state.isDrawerOpen,
      ...(!this.state.isDrawerOpen && { updateLineupInfo: new Date().valueOf() }, () => {
        alert(this.state.isDrawerOpen)
      })
    });

  }

  updateBoosterFn = (time) => {
    // console.log(time, 'time');
    // console.log(this.state.updateBooster, 'this.state.updateBooster');
    if (this.state.updateBooster != time) {
      //console.log(1, this.state.updateBooster == time);
      this.setState({
        updateBooster: time,
      })
    }
  }

  handleAutoPick = () => {
    const { t } = this.props;
    const { SelectedLeague, LineupList, default_formation, is_single_player, team_info, IS_TRASFER } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let _LineupList = _.map(_.cloneDeep(LineupList), (item) => {
      if (is_single_player) {
        item.position = item.position_clone
      }
      return item;
    });
    const payload = {
      "sports_id": sports_id,
      "league_id": league_id,
      "lineup": Utils.SelectPlayerCount(LineupList) < 15 ? Utils.GetSelectPlayer(this.lineupModTrns(_LineupList)) : [],
      "selected_formation": default_formation
    }
    this.setState({
      AutoPickPosing: true,
      LineupLoader: true,
      _temp: []
    })
    Http.post(API.GET_AUTOFILL_LINEUP_DATA, payload).then(({ data }) => {
      const { LineupStage, MasterRosterList } = this.state

      // console.log(MasterRosterList,"MasterRosterListMasterRosterList");
      let Player = '';
      let Allplayers = [];
      Allplayers = _.map(data.lineup_player, (item) => {
        Player = _.filter(MasterRosterList, (o) => o.player_uid == item.player_uid);
        return { ...(_.isEmpty(Player) ? {} : Player[0]), ...item }
      })

      const LineupListData = Allplayers

      //console.log(Allplayers,"Allplayers");


      if (LineupStage == Constant.LINEUP_STAGE_CREATE) {

        if (Utils.SelectPlayerCount(LineupList) == 0 || Utils.SelectPlayerCount(LineupList) == LineupList.length) {
          //  console.log('Get all Player from API')
          let LineupFieldPlayer = _.filter(LineupListData, o => {
            return o.is_bench_player != 1
          })
          let LineupBenchPlayer = _.filter(LineupListData, o => {
            return o.is_bench_player == 1 && o.position != 'GK'
          })
          let LineupGKBenchPlayer = _.filter(LineupListData, o => {
            return o.is_bench_player == 1 && o.position == 'GK'
          })

          // let k = 2;
          _.map(LineupFieldPlayer, item => {
            item.player_role = '0'
            item.bench_order = '0'
            return item;
          })
          _.map(LineupGKBenchPlayer, item => {
            // item.bench_order = '1';
            item.bench_order = '0';
            return item;
          })
          _.map(LineupBenchPlayer, item => {
            item.bench_order = '0';
            // item.bench_order = k;
            // k = k + 1
            return item;
          })

          let _LineupList = [...LineupFieldPlayer, ...LineupGKBenchPlayer, ...LineupBenchPlayer]
          this.setState({
            LineupList: _.cloneDeep(_LineupList),
            LineupLoader: false
          }, () => {
            this.updateLineupList(this.state.LineupList)
          })
        } else if (Utils.SelectPlayerCount(LineupList) < LineupList.length) {
          //console.log('Get remaining Player from API')
          // let k = 2;
          const _lineup = _.filter(_.cloneDeep(LineupList), item => {
            return item.player_uid != null
          })
          let LineupFieldPlayer = _.filter(_.cloneDeep(LineupListData), o => {
            return o.is_bench_player != 1
          })
          const LineupGKBenchPlayer = _.filter(_.cloneDeep(LineupListData), item => {
            return item.position == 'GK' && item.is_bench_player == 1
          })
          const LineupBenchPlayer = _.filter(_.cloneDeep(LineupListData), o => {
            return o.is_bench_player == 1 && o.position != 'GK'
          })

          _.map(LineupFieldPlayer, item => {
            item.player_role = '0'
            item.bench_order = '0'
            return item;
          })

          _.map(LineupGKBenchPlayer, item => {
            item.bench_order = '0';
            if (item.is_bench_player == 1) {
              // item.bench_order = '1';
              item.bench_order = '0';
            }
            return item;
          })

          _.map(LineupBenchPlayer, item => {
            item.bench_order = '0';
            // k = k + 1
            return item;
          })

          this.setState({
            LineupList: [..._lineup, ...LineupGKBenchPlayer, ...LineupBenchPlayer, ...LineupFieldPlayer],
            LineupLoader: false,
            AutoPickPosing: false,
          }, () => {
            this.updateLineupList(this.state.LineupList)
          })


        }
      } else {
        const _CBlank = _.filter(LineupList, item => item.player_uid == null && item.player_role && item.player_role == 1)
        const _VCBlank = _.filter(LineupList, item => item.player_uid == null && item.player_role && item.player_role == 2)


        const _lineup = _.filter(LineupList, item => item.player_uid != null)
        const PlayerPLaceholder = _.filter(LineupList, item => item.player_uid == null)
        _.forEach(_.cloneDeep(LineupListData), (obj) => {
          _.forEach(_.cloneDeep(PlayerPLaceholder), (item) => {
            if (!Utils.IsPlayerExist(obj, this.state._temp) && (item.position == obj.position && item.is_bench_player == obj.is_bench_player)) {
              this.setState({ _temp: [...this.state._temp, { ...item, ...obj }] })
            }
          })
        })

        let tempCap = _.isEmpty(_CBlank) ? [] : _.filter(this.state._temp, o => o.position == _CBlank[0].position)
        let tempVCap = _.isEmpty(_VCBlank) ? [] : _.filter(this.state._temp, o => o.position == _.isEmpty(_VCBlank) ? '' : _VCBlank[0].position)

        let isCap = true
        let isVCap = true

        let finalLineup = _.map(this.state._temp, stm => {
          Utils.setTransferData(stm, '1')
          
          if (!_.isEmpty(_CBlank) && (stm.position == tempCap[0].position && isCap && stm.player_role == 0) && stm.is_bench_player !== "1") {
            stm.player_role = 1
            isCap = false
          }
          if (!_.isEmpty(_VCBlank) && stm.position == tempVCap[0].position && isVCap && stm.player_role == 0 && stm.is_bench_player !== "1") {
            //console.log("radhe");
            stm.player_role = 2
            isVCap = false
          }
          return stm
        })
        
        this.setState({
          InPlayers: Utils.getTransferData().in,
          LineupList: [..._lineup, ...finalLineup],
          LineupLoader: false,
          AutoPickPosing: false,
        },()=>{
          if(IS_TRASFER){
            this.ConsumeFreeTransfers(team_info.available_transfer)
            this.TransfersCast(team_info.available_transfer)
          }
        })
      }


    }).catch(error => {
      this.props.history.push(Constant.DASHBOARD_PATH)
      console.error(error)
    });
  }

  getMasterRosterList = (list) => {
    this.setState({
      MasterRosterList: list
    })
  }

  setActive = (con) => {
    this.setState({ btnActive: con })
  }

  handleBodyClick = () => {
    document.body.classList.remove('add-overflow');
  };

  //For share 

  captureAndShareoold = () => {
    // Select the element you want to capture
    const element = document.querySelector('.pitch-wrap-pos');
    
    // Log the selected element for debugging
    console.log('Selected element:', element);
  
    if (element) {
      domtoimage.toPng(element)
        .then((dataUrl) => {
          // Create a link element
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'screenshot.png';
          console.log('Data URL:', dataUrl);
  
          // Trigger the download
          link.click();
        })
        .catch((error) => {
          console.error('Error capturing screenshot:', error);
        });
    } else {
      console.error('Element not found.');
    }
  };
  

  captureAndShare= () => {
    const header = document.querySelector('.pitch-wrap-pos');
    html2canvas(header, { useCORS: true }).then(async canvas => {
      const image = canvas.toDataURL('image/png');
      
    
      const base64Response = await fetch(image);
      const blob = await base64Response.blob();
      const file = new File([blob], "screenshot.png", { type: "image/png" });
    
      this.uploadImage(file);
    }).catch(error => {
      console.error('Error capturing the image:', error);
    });
    
    
  };





  uploadImage(blobimage) {
    let globalThis = this;
    

    let blob = blobimage;
//console.log(blob,"blobblobblobblob");

    var data = new FormData();
    data.append("file", blob);
    // data.append("userfile", this.state.file);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState == 4) {
        var response = JSON.parse(this.responseText);

        if (response != '' && response.response_code == 200) {
          globalThis.setState({
            ScImage:response.data.share_url,
            optionDisable:false
          })
          // UserProfile.image = response.data.image_url
          // globalThis.setState({
          //   UserProfile: UserProfile
          // }, () => globalThis._updateProfile())
        }
        else {
          if (response.global_error && response.global_error != '') {
            Utils.notify(response.global_error, "error");
          }
          else {
            var keys = Object.keys(response.error);
            if (keys.length > 0) {
              Utils.notify(response.error.userfile, "error");
            }
          }

         // globalThis.setState({ PicPosting: false })
        }

      }
    });

    xhr.open("POST", API.UPLOAD_SHARE_IMAGE);
    xhr.setRequestHeader('session_key', Auth.getAuth())
    xhr.send(data);
  }

  showShareModal =()=>{
    this.setState({
      shareModal:!this.state.shareModal
    },()=>{
      if(this.state.shareModal){
        setTimeout(() => {
          alert("RADHA")
          this.handleCapture()  
        }, 10000);
        
        //this.captureAndShare()
      }
    })
  }

  render() {
    const { t } = this.props
    const {
      // Master
      IS_MYTEAM,
      IS_TRASFER,
      updatePlayerList,
      AppMasterData,
      CurrentLineup,
      SelectedLeague,
      sports_config,
      RosterMasterData,
      lineup_master_id,
      is_single_player,
      isTeamSubmitShow,
      isConfirmTransferShow,

      //  Lineup Master
      ALL_POSITION_CLONE,
      SALARY_CAP,
      ALL_TEAM,
      ALL_POSITION,
      SORT_BY_LIST,
      booster_list,
      LineupStage,
      team_name,
      contest_id,


      // Team Manage Data
      my_performance,
      next_week_start_time,
      joined_week,
      next_week,
      active_week,
      last_week,
      scoring_weeks,
      SelectedWeek,
      user_join_count,
      is_editable,

      // Get User Lineup Data
      team_info,
      show_transfer,
      LineupList,
      LineupListClone,
      LineupLoader,

      SelectedPlayer,
      isPlayerCardShow,
      updateLineupInfo,
      updateBooster,
      InPlayers,
      BoosterPosting,
      SelectedWeekObj,
      isDrawerOpen,

      FormationModalShow,
      default_formation,
      default_formation_clone,
      SwapActive,
      gameweeklastupdate,
      innerWidth,
      MicroSiteWidth,
      btnActive,
      AutoPickPosing,
      MasterRosterList,
      team_update,
      is_rule_violate,
      shareModal,
      ScImage,
      image,
      WKSel
    } = this.state

    // console.log(SelectedWeek == ''," radha SelectedWeek");

    // user_join_count = 1
    // active_week = 3
    // next_week = 3
    // last_week = 3
    // joined_week = 3
    // SelectedWeek = next_week

    const DeadlineClockProps = {
      date: Utils.getUtcToLocal(next_week_start_time),
      gameweek: Utils.getMultiLeg().week_label[next_week]
    }
   
    
    console.log(SelectedWeek,"SelectedWeekSelectedWeek");
    
    const GameweekProps = {
      // CurrentLineup,
      scoring_weeks,
      //SelectedWeek: WKSel || SelectedWeek,
      SelectedWeek: SelectedWeek,
      next_week,
      active_week,
      last_week,
      joined_week,
      gameweeklastupdate,
      SelectWeekHandle: this.SelectWeekHandle
    }

    const PlayerListProps = {
      next_week,
      joined_week,
      RosterMasterData,
      SALARY_CAP,
      ALL_TEAM,
      ALL_POSITION,
      SORT_BY_LIST,
      updatePlayerList,
      SelectedLeague,
      sports_config,
      LineupStage,
      LineupList,
      IS_TRASFER,
      lineup_master_id,
      lineup_roster_limit: this.getLineupRosterLimit(next_week),
      updateLineupList: this.updateLineupList,
      getMasterRosterList: this.getMasterRosterList,
      setActive: this.setActive,
    }

    const PitchViewProps = {
      SwapActive,
      ALL_POSITION,
      ALL_POSITION_CLONE,
      joined_week,
      active_week,
      next_week,
      last_week,
      SelectedLeague,
      RosterMasterData,
      lineup_master_id,

      LineupList,
      LineupLoader,
      IS_TRASFER,
      SelectedWeek,
      CurrentLineup,
      team_info,
      LineupStage,
      default_formation,
      MasterRosterList,
      formation_show: (SelectedLeague.is_allow_bench == 1 && ((user_join_count == 0 && IS_MYTEAM || (next_week == joined_week) && (active_week != last_week)) || user_join_count == 1 && IS_TRASFER)),
      IS_MYTEAM,
      getLineupRosterLimit: this.getLineupRosterLimit,
      updateLineupList: this.updateLineupList,
      PlayerListNode: this.triggerPlayerList,
      PlayerCardInit: this.PlayerCardInit,
      toggleDrawer: this.toggleDrawer,
      FormationModalToggle: this.FormationModalToggle,

      SwapInit: this.SwapInit,
      SwapExecute: this.SwapExecute,
      shareModal:shareModal
    }
    const BoosterProps = {
      CurrentLineup,
      booster_list,
      team_info,
      next_week,
      last_week,
      joined_week,
      active_week,
      SelectedWeek,
      updateBooster,
      BoosterPosting,
      LineupList,
      ApplyBooster: this.ApplyBooster,
      RemoveBooster: this.RemoveBooster,
      IS_MYTEAM,
      lineup_master_id

    }

    const TeamSubmitProps = {
      LineupList,
      RosterMasterData,
      lineup_master_id,
      sports_config,
      default_formation,
      contest_id: contest_id,
      isOpen: isTeamSubmitShow,
      toggle: this.TeamSubmitToggle,
      updateLineupList: this.updateLineupList,
      ...(this.props.location.state ? {
        c_id: this.props.location.state.c_id,
        s_id: this.props.location.state.s_id,
        l_id: this.props.location.state.l_id,
        c_data_block: this.props.location.state.c_data_block,
      } : {}),
      ALL_TEAM: team_update
    }

    const ConfirmTransferProps = {
      lineup_master_id,
      team_info,
      next_week,
      next_week_start_time,
      ALL_POSITION,
      default_formation,
      SALARY_CAP,
      isOpen: isConfirmTransferShow,
      toggle: this.ConfirmTransferToggle,
      ConsumeFreeTransfers: this.ConsumeFreeTransfers,
      TransfersCast: this.TransfersCast
    }
    const PlayerCardProps = {
      IS_TRASFER,
      next_week,
      joined_week,
      ALL_POSITION,
      LineupList,
      RosterMasterData,
      selected_league: SelectedLeague,
      lineup_roster_limit: this.getLineupRosterLimit(next_week),
      isLineupPlayer: SelectedPlayer.is_bench_player != "1",
      enablePlayerRoleInit: (_.isUndefined(CurrentLineup.week_info.next_week) ? (user_join_count == 1 ? true : false) : CurrentLineup.week_info.next_week == SelectedWeek) && _.isEmpty(InPlayers) && !SwapActive,
      updateLineupList: this.updateLineupList,
      PlayerRoleInit: this.PlayerRoleInit,

      Player: SelectedPlayer,
      isOpen: isPlayerCardShow,
      toggle: this.PlayerCardToggle,
    }

    const LineupInfoProps = {
      my_performance,
      SelectedWeekObj,
      user_join_count,
      BoosterProps,
      scoring_weeks,
      team_info: team_info || {},
      updateLineupInfo: updateLineupInfo,
      updateBoosterFn: this.updateBoosterFn,
    }


    const DrawerProps = {
      width: innerWidth < 375 ? 320 : 375,
      noTouchOpen: true,
      noTouchClose: true,
      handleWidth: 5,
      overlayColor: "rgba(0,0,0,0.78)",
      drawerStyle: {
        "boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
      }
    }

    const TeamFieldHeader = () => {
      return (
        <div className="team-field-header">
          <i className="icon-cancel drawer-close" onClick={() => this.toggleDrawer()} />
        </div>
      )
    }


    const isEqualLineup = LineupList.filter(({ player_uid: uid1 }) => !LineupListClone.some(({ player_uid: uid2 }) => uid2 === uid1)).length == 0

    const MyTeamRosterProps = {
      user_join_count,
      SALARY_CAP,
      IS_MYTEAM,
      IS_TRASFER,
      CurrentLineup,
      lineup_master_id,
      PlayerListProps,
      LineupInfoProps,
      next_week,
      joined_week,
      last_week,
      active_week,
      _this: this,
      setNodeRef: this.setNodeRef
    }

    const FormationModalProps = {
      isOpen: FormationModalShow,
      toggle: this.FormationModalToggle,
      RosterMasterData: RosterMasterData,
      default_formation: default_formation,
      FormationChangeHandle: this.FormationChangeHandle
    }

    const TeamShareModalProps ={
      isOpen:shareModal,
      toggle:this.showShareModal,
      ScImage:ScImage,
      disable:this.state.optionDisable
    }
// console.log(next_week,'next_weeknext_week')
    return (
      <section className='main-myteam'>
                {/* <button onClick={()=>this.captureAndShare()}>Capture Screenshot</button> */}

        <Container className="stadium-component">
          <Row className='gutter-fix'>
            <Col lg={8} md={12}>
              {/*  */}
              {/* <div>
                <div ref={this.contentRef} id="my-div">
                  <h1>Hello, World!</h1>
                  <p>This is a sample div to convert to PNG.</p>
                  <img src={'https://isl-prod-mum.s3.ap-south-1.amazonaws.com/upload/jersey/1695990792.png'} alt="" />
                </div>
                <button onClick={this.convertToPng}>Convert to PNG</button>
              </div> */}
              {/* PITCH HEADER */}
              <div className="pitch-header" ref={this.nodeRef}>
                <div className="pitch-header-top">
                  <div className="cell">
                    {
                      (IS_MYTEAM && !LineupLoader) &&
                      <>
                        {
                          _.isUndefined(lineup_master_id) ?
                            <span className="ph-title"><Trans>Create Team</Trans></span>
                            :
                            <span className="ph-title"><Trans>My Team</Trans></span>
                        }
                      </>
                    }
                    {
                      IS_TRASFER &&
                      <>
                        <span className="ph-title"><Trans>Transfers</Trans></span>
                        {/* <div className="ph-subtitle"><Trans>Select a maximum of</Trans>{` ${this.getLineupRosterLimit(next_week)} `}<Trans>players from Rank</Trans>{` ${'1-16'}`}</div> */}
                      </>
                    }
                    <InfoPopover>
                      <StaticPage {...{ page_id: '2' }} />
                    </InfoPopover>
                  </div>
                  <div className='share-block-wrap'>
                  {
                    (!(active_week == last_week && next_week == last_week) && next_week_start_time) &&
                    <DeadlineClock {...DeadlineClockProps} />
                  }
                  {/* {IS_TRASFER && <div className='team-share-option' onClick={()=>this.showShareModal()}>
                    <i className='icon-share'></i>
                    <span><Trans>Share Team</Trans></span>
                  </div>} */}
                  </div>
                </div>

                {
                  (IS_MYTEAM && CurrentLineup.user_join_count == 1) && SelectedWeek !== ''&&
                  <GameweekNativeSlider {...GameweekProps} />
                }

                <div className={Auth.getAuth() ? IS_MYTEAM && !_.isUndefined(lineup_master_id) ? 'login-updated pitch-header-bottom pbfix-tms' : "login-updated pitch-header-bottom" : 'pitch-header-bottom'}>
                  {
                    (SALARY_CAP != 0) &&
                    <div className="ph-info-block">
                      <div className="ph-label"><Trans>Available Budget</Trans></div>
                      <div className="ph-value">{AppMasterData.CURRENCY_CODE} {Utils.AvailableSalary(SALARY_CAP, LineupList)} {REACT_APP_SALARY_SUBFFIX}</div>
                    </div>
                  }
                  <div className="ph-info-block">
                    <div className="ph-label"><Trans>Player(s) Selected</Trans></div>
                    <div className="ph-value">{Utils.SelectPlayerCount(LineupList)}/{this.getLineupRosterLimit(SelectedWeek)}</div>
                  </div>
                  {
                    (
                      (IS_TRASFER && team_info) && (next_week > joined_week)) &&
                    <>
                      <div className="ph-info-block">
                        <div className="ph-label">{`${t('Free Transfers')} (${0} ${t('Cost')})`}</div>
                        <div className="ph-value">
                          {
                            (team_info.free_hit_booster_status && team_info.free_hit_booster_status.is_applied == 1 && Number(team_info.free_hit_booster_status.applied_week) == Number(next_week)) ||
                              (team_info.wildcard_status && team_info.wildcard_status.is_applied == 1 && Number(team_info.wildcard_status.applied_week) == Number(next_week)) ||
                              SelectedLeague.free_transfer_week == Number(next_week)
                              ?
                              <Trans>Unlimited</Trans>
                              :
                              <>{this.ConsumeFreeTransfers(team_info.available_transfer)}/{team_info.available_transfer}</>
                          }
                        </div>
                      </div>
                      {
                        this.TransfersCast(team_info.available_transfer) > 0 &&
                        // this.ConsumeFreeTransfers(team_info.available_transfer) != 0 && 
                        <div className="ph-info-block">
                          <div className="ph-label"><Trans>Additional Transfers</Trans></div>
                          <div className="ph-value flx">
                            <div className="txt">{`${this.TransfersCast(team_info.available_transfer)} ${t('cost')} (${t('pts')})`}</div>
                            <div className="num"> {InPlayers.length - team_info.available_transfer} </div>
                          </div>
                        </div>
                      }
                    </>
                  }

                  {!LineupLoader && <div className='pitch-control'>
                    {
                      ((_.isUndefined(lineup_master_id) && !LineupLoader) || IS_TRASFER) &&
                      <div className={`formation-trigger ${Utils.getTransferData().out.length != 0 ? 'is_trasfer' : ''} ${SwapActive ? 'disabled' : ''}`} onClick={() => this.FormationModalToggle()}>
                        <div className='formation-data'>
                          {<img src={default_formation === '4-4-2' ?
                            Images[442] : default_formation === '3-5-2' ?
                              Images[352] : default_formation === '3-4-3' ?
                                Images[343] : default_formation === '4-3-3' ?
                                  Images[433] : default_formation === '4-5-1' ?
                                    Images[451] : default_formation === '5-3-2' ?
                                      Images[532] : Images[541]} alt='' />}
                          <span>{default_formation}</span>
                        </div>

                        {
                          Utils.getTransferData().out.length == 0 &&
                          <i className="icon-down" />
                        }

                      </div>
                    }
                    {/* MY TEAM */}
                    {
                      (!_.isNull(user_join_count) && IS_MYTEAM) &&
                      <>
                        {
                          (!lineup_master_id && (user_join_count == 0) || !_.isUndefined(CurrentLineup.team_info) && (CurrentLineup.week_info.next_week == CurrentLineup.team_info.joined_week)) &&
                          <button className='ctrl-btn' onClick={() => this.ResetLineup()}
                          // disabled={isEqualLineup}
                          >
                            <i className="icon-refresh" />
                            <span className="ph-label">
                              <Trans>Reset</Trans>
                            </span>
                          </button>
                        }
                      </>
                    }

                    {/* TRANSFER */}
                    {
                      ((!_.isNull(user_join_count) && IS_TRASFER) && lineup_master_id) &&
                      <button className='ctrl-btn' onClick={() => this.ResetLineup()} disabled={isEqualLineup}>
                        <i className="icon-refresh" />
                        <span className="ph-label">
                          <Trans>Reset</Trans>
                        </span>
                      </button>
                    }


                    {
                      !is_single_player && (!IS_MYTEAM || _.isEmpty(Utils.getCurrentLineup().team_info)) &&
                      <button className={`ctrl-btn blue-box-autopick ${AutoPickPosing ? 'disbale-auto' : ''}`} onClick={AutoPickPosing ? () => { } : () => this.handleAutoPick()}>
                        <i className="icon-auto-flash" />
                        <span className="ph-label">
                          <Trans>Auto Pick</Trans>
                        </span>
                      </button>

                    }
                    {/* {
                      !is_single_player &&
                      <button className='ctrl-btn blue-box-autopick' onClick={AutoPickPosing ? () => { } : () => this.handleAutoPick()}>
                        <i className="icon-auto-flash" />
                        <span className="ph-label">
                          <Trans>Auto Pick</Trans>
                        </span>
                      </button>
                      
                    } */}

                  </div>}
                </div>
              </div>

              {
                user_join_count == 1 &&
                <Booster {...BoosterProps} />
              }
              {
                is_rule_violate &&
                <div className='violation-info'>Violation Detected: Your team has exceeded maximum player per team limit.</div>

              }
              {/* <div id="myDiv">
                  <img src={'https://isl-prod-mum.s3.ap-south-1.amazonaws.com/upload/jersey/1695990762.png'} alt="" />
              </div> */}
              <PitchView getInstance={this.setNodeRef.bind(this, 'PitchView')} {...PitchViewProps} />
              {
                (!LineupLoader) &&
                <>
                  {
                    IS_MYTEAM &&
                    <>
                      {/* Create Team */}
                      {
                        user_join_count == 0 ?
                          <div className="bottom-pitch-control web">
                            {/* <div className="text"><Trans>Pick your starting lineup</Trans></div> */}
                            <Button
                              disabled={
                                !this.fullLineup() ||
                                (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                                Utils.SelectPlayerCount(LineupList) !== LineupList.length
                              }
                              onClick={() => this.LineupSave(false)}
                            ><Trans>save</Trans></Button>
                          </div>
                          :
                          <div className="bottom-pitch-control web">
                            {
                              (
                                (next_week == joined_week) && (active_week != last_week)
                              ) &&
                              <Button
                                disabled={
                                  !this.fullLineup() ||
                                  (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                                  Utils.SelectPlayerCount(LineupList) !== LineupList.length || SwapActive && !btnActive
                                  // ||
                                  // RosterMasterData
                                }
                                onClick={() => this.LineupSave()}
                              ><Trans>Confirm Changes</Trans></Button>
                            }
                            {
                              (
                                ((next_week > joined_week) && IS_MYTEAM) &&
                                (SelectedWeek == next_week) && (last_week != active_week)
                              ) &&
                              <Button
                                // onClick={() => this.setState({
                                //   IS_TRASFER:  true,
                                //   IS_MYTEAM:  false,
                                // })}
                                onClick={() => this.props.history.push({ pathname: '/transfers', state: { c_id: '', s_id: '', l_id: '', c_data_block: '' } })}
                              ><Trans>Make Transfer</Trans></Button>
                            }
                            {
                              (active_week == last_week && next_week == last_week) &&
                              <SeasonEndAlert />
                            }
                          </div>
                      }
                      {/* <Banner type='6' paddingBottom /> */}
                    </>
                  }
                  {
                    IS_TRASFER &&
                    <div className="bottom-pitch-control web">

                      {
                        (next_week > joined_week) ?
                          <>
                            {/* <Button
                                onClick={() => this.setState({
                                  IS_TRASFER: false,
                                  IS_MYTEAM: true,
                                })}
                              // onClick={() => this.props.history.push('/transfers')}
                              ><Trans>Cancel</Trans></Button> */}
                            <Button
                              disabled={
                                !this.fullLineup() ||
                                (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                                Utils.SelectPlayerCount(LineupList) !== LineupList.length
                              }
                              onClick={
                                () => isEqualLineup ? this.LineupSave() : this.ConfirmTransferToggle()
                              }
                            ><Trans>save</Trans></Button>
                          </>
                          :
                          <Button
                            disabled={
                              !this.fullLineup() ||
                              (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                              Utils.SelectPlayerCount(LineupList) !== LineupList.length || SwapActive && !btnActive
                            }
                            onClick={() => this.LineupSave()}
                          ><Trans>Confirm Changes</Trans></Button>

                      }
                    </div>
                  }
                </>
              }
            </Col>
            <Col lg={4} className='roster-mobile-hide'>
              {
                MicroSiteWidth < innerWidth &&
                <MyTeamRoster {...MyTeamRosterProps} getInstance={this.setNodeRef.bind(this, 'PlayerList')} />
              }

            </Col>
          </Row>
          <>
            {
              isConfirmTransferShow &&
              <ConfirmTransfer {...ConfirmTransferProps} />
            }
            {
              isTeamSubmitShow &&
              <TeamSubmiModal {...TeamSubmitProps} />
            }
            {
              isPlayerCardShow &&
              <PlayerCard {...PlayerCardProps} />
            }
            {
              FormationModalShow &&
              <FormationModal {...FormationModalProps} />
            }
          </>
          <div className="roster-drawer-wrap">
            <Drawer open={isDrawerOpen} className='roster-drawer' {...DrawerProps} aria-drawer="true" right={true}>
              {
                MicroSiteWidth >= innerWidth &&
                <>
                  <TeamFieldHeader />
                  <MyTeamRoster {...MyTeamRosterProps} getInstance={this.setNodeRef.bind(this, 'PlayerListDrawer')} />
                </>
              }
            </Drawer>
          </div>
        </Container>
        <section className='white-color pup-fix'>
          <Container className='fxt-container'>
            <Row className='xgutter-fix my-team-gtf'>
              <Col md={8} className='white-fixture-wrap'>
                {!_.isUndefined(next_week) && !_.isNull(user_join_count) && <FixtureMT
                  no_ad
                  selected_league={SelectedLeague}
                  lastupdate={updateLineupInfo}
                  next_week={next_week}
                  user_join_count={user_join_count}
                  league_week={(SelectedWeek == '' || _.isUndefined(SelectedWeek)) ? next_week : SelectedWeek}
                />}
              </Col>
              <Col md={4} className='top-stick'>
                <Banner type="7" />
              </Col>
            </Row>
          </Container>
        </section>
        {
          (!LineupLoader) &&
          <>
            {
              IS_MYTEAM &&
              <>
                {/* Create Team */}
                {
                  user_join_count == 0 ?
                    <div className="bottom-pitch-control mob">
                      {/* <div className="text"><Trans>Pick your starting lineup</Trans></div> */}
                      <Button
                        disabled={
                          !this.fullLineup() ||
                          (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                          Utils.SelectPlayerCount(LineupList) !== LineupList.length
                        }
                        onClick={() => this.LineupSave(false)}
                      ><Trans>save</Trans></Button>
                    </div>
                    :
                    <div className="bottom-pitch-control mob">
                      {
                        (
                          (next_week == joined_week) && (active_week != last_week)
                        ) &&
                        <Button
                          disabled={
                            !this.fullLineup() ||
                            (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                            Utils.SelectPlayerCount(LineupList) !== LineupList.length || SwapActive && !btnActive
                            // ||
                            // RosterMasterData
                          }
                          onClick={() => this.LineupSave()}
                        ><Trans>Confirm Changes</Trans></Button>
                      }
                      {
                        (
                          ((next_week > joined_week) && IS_MYTEAM) &&
                          (SelectedWeek == next_week) && (last_week != active_week)
                        ) &&
                        <Button
                          // onClick={() => this.setState({
                          //   IS_TRASFER:  true,
                          //   IS_MYTEAM:  false,
                          // })}
                          onClick={() => this.props.history.push({ pathname: '/transfers', state: { c_id: '', s_id: '', l_id: '', c_data_block: '' } })}
                        ><Trans>Make Transfer</Trans></Button>
                      }
                      {
                        (active_week == last_week && next_week == last_week) &&
                        <SeasonEndAlert />
                      }
                    </div>
                }
                {/* <Banner type='6' paddingBottom /> */}
              </>
            }
            {
              IS_TRASFER &&
              <div className="bottom-pitch-control mob">

                {
                  (next_week > joined_week) ?
                    <>
                      {/* <Button
                                onClick={() => this.setState({
                                  IS_TRASFER: false,
                                  IS_MYTEAM: true,
                                })}
                              // onClick={() => this.props.history.push('/transfers')}
                              ><Trans>Cancel</Trans></Button> */}
                      <Button
                        disabled={
                          !this.fullLineup() ||
                          (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                          Utils.SelectPlayerCount(LineupList) !== LineupList.length
                        }
                        onClick={
                          () => isEqualLineup ? this.LineupSave() : this.ConfirmTransferToggle()
                        }
                      ><Trans>save</Trans></Button>
                    </>
                    :
                    <Button
                      disabled={
                        !this.fullLineup() ||
                        (SALARY_CAP != 0 ? Utils.AvailableSalary(SALARY_CAP, LineupList) < 0 : false) ||
                        Utils.SelectPlayerCount(LineupList) !== LineupList.length || SwapActive && !btnActive
                      }
                      onClick={() => this.LineupSave()}
                    ><Trans>Confirm Changes</Trans></Button>

                }
              </div>
            }
          </>
        }
        { shareModal && 
          <TeamShareModal {...TeamShareModalProps}/>
        }
      </section>
    );
  }
}

export default ReduxHOC(MyTeam);
